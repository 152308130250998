@import "style_variables";

.cardLayout {
    // border: 1px solid #cfd0dd;
    min-height: 52px;
    font-size: 14px;
    margin-bottom: 20px;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;

    .subType {
        text-transform: capitalize;
        display: flex;
        align-items: center;
    }

    .color_grey {
        color: $grey-focus;
    }

    .viewBtn {
        background-color: $primaryColor-enable;
        color: $white;
        border-radius: 4px;
        font-size: 13px;
        height: 30px;
        border: 1px solid $primaryColor-enable;


        &:hover {
            background-color: $primaryColor-hover;
            border: 1px solid $primaryColor-hover;

        }
    }

    .eventType{
        display: inline-block;
        min-width: 105px;
        // width: 105px;
        max-width: 130px;
        font-weight: 600;
        border-radius: 20px;
        padding: 5px 20px;
        font-size: 13px;
        color: white;
        text-align: center;
    }

    .subType-span {
        padding: 3px 20px;
        border-radius: 20px;
        color: white;
        font-weight: 600;
        font-size: 13px;
        text-transform: capitalize;
        min-width: 80px!important;
        box-sizing: border-box;
        display: inline-block;
        text-align: center;
    }


    .cardTitle-icon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }


    .mi_showme_play {
        background-color: $mediumorchid;
    }
    .mi_guide_play {
        background-color: $yellowsandybrown;
    }
    .mi_guide_download {
        background-color: $lightseagreen;
    }
    .mi_test {
        background-color: $slateblue;
    }
    .mi_do_it_for_me {
        background-color: $orangesandybrown;
    }


    

   .mi_showme_play_icon path {
        fill: $mediumorchid!important;
    }
    .mi_guide_play_icon path {
        fill: $yellowsandybrown!important;
    }
    .mi_guide_download_icon{
        color: $lightseagreen!important;
        font-size: 18px
    }
    .mi_test_icon path {
        fill: $slateblue!important;
    }
    .mi_do_it_for_me_icon path {
        fill: $orangesandybrown!important;
    }



    .live{
        color: $live;
        border: 1px solid $live;
    }

    .ppt{
        color: $ppt;
        border: 1px solid $ppt;
    }

    .video{
        color: $video;
        border: 1px solid $video;
    }

    .gif{
        color: $gif;
        border: 1px solid $gif;
    }

    .pdf{
        color: $pdf;
        border: 1px solid $pdf;
    }

    .docx{
        color:  $docx;
        border: 1px solid $docx;
    }

    .html{
        color: $html;
        border: 1px solid $html;
    }

    .txt{
        color: $text;
        border: 1px solid $text;
    }
}

.stepsDiv {
    
}

.startTime-div{
    width: 580px;
    margin: 0 auto;
    display: block;
}

.startTime-span{
    background-color: #187de6;
    color: white;
    font-size: 13px;
    font-weight: 600;
    padding: 5px 20px 5px 20px;
    border-radius: 20px;
    min-width: 100px;
    display: inline-block;
}


.stepTitle-icon{
    width: 13px;
    height: 13px;
    min-width: 13px;
    min-height: 13px;
    margin-right: 5px;
    margin-top: 3px;
}


.guideTitle-icon{
    width: 13px;
    height: 13px;
    min-width: 13px;
    min-height: 13px;
    margin-right: 5px;
    position: relative;
}

.stepTitle-icon path {
    fill: $grey!important;
}

.guideTitle-icon path {
    fill: $white!important;
}

.cardsModal {
    padding-bottom: 20px;
    padding-top: 40px!important;
    height: 400px;
    overflow-y: auto;
}

.stepTitle-span{
    font-weight: 600;
}

.stepTime-span {
    color: $grey;
}

.stepCard{
    border: 1px solid $lightgray;
    width: 400px;
    padding: 10px;
    border-radius: 2px;
    font-size: 13px;
}
.noDatadiv{
    display: flex;
    justify-content: center;
    align-items: center;
}

.horizontalLine-span{
    display: inline-block;
    height: 1px;
    width:50px;
    background-color: $lightgray;
}

.borderLeft{
    border-left: 1px solid $lightgray;
    width: 450px;
    margin: 0 auto;
}

.cardDivider{
    height: 1px;
    width: 450px;
    background-color: #cfd0dd;
    /* margin-top: 20px; */
    margin-bottom: 50px;
}

.stepCard {
    width: 400px;
    // margin-bottom: 20px;
}

.surveyQuestionsList{
    .cardLayout{
    cursor: pointer;
    display : flex;
    flex-direction: column;
    min-height: 52px;
    padding: 0px !important;
    justify-content: center;
    .questionDiv{
        display : flex;
        min-height: 52px;
        justify-content: center;
        width: calc(100% - 50px);
    .quesStatement{
        .quesNumber-text{
            color: #7d7d7c;
            font-size: 18px;
            padding: 3px;
            width:7%;
        }
        .question-title{
            color: $table-font-link;
            font-weight: 600;
            font-size: 18px;
            white-space: nowrap;
            padding: 1px 0;
            overflow: hidden!important;
            text-overflow: ellipsis!important;
            width: 80%;
        }
    }
    }
    .responseDiv{
        display: flex;
        width: 100%;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        justify-content: center;
    }
}  
}

.surveyExportDiv{
    display: flex;
    padding-top: 0px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    height: 70px;
    width: 100%;
    /* flex: inherit; */
    align-items: center;

    .hideDeleted{
        width: 300px;
    }

    .exportDiv{
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        position: absolute;
        right: 0px;
    }

    .checkmark{
        display: inline-block;
        position: absolute;
        left: -10px;
        height: 18px;
        width: 18px;
        border: 1px solid #cfd0dd;
        border-radius: 2px;
    }
    .check-container input:checked ~ .checkmark {
        background-color: $primaryColor-active !important;
        border: 1px solid $primaryColor-active;
    }
    
    /* On mouse-over, add a grey background color */
    .check-container:hover input:checked ~ .checkmark {
        background-color: $primaryColor-hover !important;
        border: 1px solid $primaryColor-hover;
        cursor: pointer;
    }
    
    .check-container input:disabled ~ .checkmark {
        background-color: $grey6 !important;
        border: 1px solid $grey6;
        cursor: default;
    }

}
