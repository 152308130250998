@import "style_variables";


.userStats-card{
    text-align: center;
    line-height: 2;

    .heigth105{
        height: 105px;
    }

    .tiles-border {
        border: 1px solid #cfd0dd;
        background-color: #fff;
        border-radius: 10px;
        border-bottom-width: 3px;
    }
}

.overlay-div{
    position: absolute;
    height: 300px;
    top: 180px;
    background-color: white;
    width: 100%;
    text-align: center;
    line-height: 20;
    opacity: 0.5; 
}

.loadingDiv{
    position: absolute;
    height: 300px;
    top: 180px;
    background-color: white;
    width: 100%;
    text-align: center;
    line-height: 20;
    opacity: 0.5;
}

.dashboardloadingDiv{
    position: absolute;
    height: 450px;
    top: 0px;
    background-color: white;
    width: 100%;
    text-align: center;
    line-height: 20;
    opacity: 0.5;
}

.justifyEnd {
    justify-content: flex-end;
}