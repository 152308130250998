.featureStatsSummaryTable{

    .table tbody tr td:nth-child(1) {
    width: 45%;
        .tableContent {
            max-width: 420px;
        }
    }
    .table tbody tr td:nth-child(2) {
        width: 20%;
    }

    .table tbody tr td:nth-child(3) {
        width: 35%;
    }

    .table th:nth-child(1) {
        width: 45%;
    }

    .table th:nth-child(2) {
        width: 20%;
    }

    .table th:nth-child(3) {
        width: 35%;
    }

}