@import "../../style_variables.scss";
#bubbleChart {
    // border-left: 1px solid $grey6;
    padding-left: 20px;
    height: 400px;

    .bubbleInfo-div {
        padding: 10px;
        background-color: $grey6;
        margin-top: 10px;
        border-radius: 4px;
    }
}