#tooltipsDetailSection {
    .userActivityBtn{
        right: 35px;
        top: 55px;
    }

    .warningDiv {
        position: absolute;
        top: 50%;
        /* right: 50%; */
        transform: translateX(-50%);
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 10px 20px 0px 20px;
        background-color: #feeaa5;
        color: #9a7600;
        border-radius: 4px;
        font-size: 14px;

        .closeWarning {
            border: none;
            background-color: transparent;
            width: 20px;
            height: 20px
        }

        p {
            margin-bottom: 10px;
        }
    }

    .table tbody tr td:nth-child(1),
    .table th:nth-child(1) {
        width: 50%;
        .tableContent {
            max-width:95%;
            // cursor: pointer !important;
        }
    }

    .table tbody tr td:nth-child(2),
    .table th:nth-child(2) {
        width: 25%;
    }

    .table tbody tr td:nth-child(3),
    .table th:nth-child(3) {
        width: 25%;
    }
}

.legend-dropdown-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100px !important;
    gap: 15px;
}

.tooltip-detail {
    .dropdown {
        width: 100% !important;
    
        .legend-dropdown {
            width: 100% !important;
            height: 40px;
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: space-between;
            padding: 8px 10px !important;
            background-color: #ffffff !important;
            border: 1px solid #707070 !important;
            border-radius: 4px;
            box-shadow: 0px 1px 2px 1px #55555533 inset;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
    
            .custom-control {
                display: flex!important;
            }
    
            .dropdown-label {
                font-size: 16px;
                font-weight: 400;
                color: #000000;
            }
    
            .dropdown-count {
                position: absolute;
                top: 0px;
                left: 0px;
                background: #005BF0;
                font-size: 12px;
                color: white;
                font-weight: 600;
                padding-top: 6px;
                text-align: center !important;
                border-radius: 50%;
            }
        }
    
        .dropdown-menu {
            width: 100% !important;
            padding: 0 !important;
            top: 40px !important;
            cursor: pointer;
            border: 2px solid transparent;
            height: fit-content;
    
            &.show {
                display: block !important;
            }
    
            .custom-control {
                cursor: pointer;
                border: 2px solid transparent;
                display: flex!important;
    
                
    
                label {
                    cursor: pointer;
                }
    
                &:hover {
                    border: 2px solid #005FCC;
                    border-radius: 4px;
                }
    
                &.active {
                    border: 2px solid #005FCC;
                    border-radius: 4px;
                }
            }
        }
    }
}

