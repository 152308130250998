@import 'style_variables';

.guideStatusTitle {
    font-size: 14px;
    color:#707070;
    font-weight: 700;

    // position: absolute;
    top: 0px;
    right: 255px;
    z-index: 1;
    width: 130px;
}

#state-filter-dropdown {
    .source-but-state-filter {
        right: 185px;
        width: 180px;
        // position: absolute!important;
        height: 40px;
        font-size: 16px;
        padding: 0.5rem 10px;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: capitalize;
        text-align: left;
        top: 21px;
    
        color: #000 !important;
        border-radius: 4px;
        cursor: hand;
        border: 1px solid #BBBBBB !important;
        text-align: left;
        overflow: hidden;
        background: linear-gradient(180deg, #ffffff80, #f5f5f5) !important;
    
        &:focus{
            border: 2px solid #005FCC !important;
        }
    
        &:hover{
            border: 1px solid #BBBBBB !important;
        }
        .switchApp-btn-icon {
            position: absolute;
            right: 10px;
            top: 13px;
        }
    
        span.caret {
            display: none;
        }

        .filterCase {
            text-transform: capitalize;
        }
    }
    
    .source-but-state-filter:hover {
        background-color: #ffffff !important;
        color: $filter-font !important;
        
    }
    
    .source-but-state-filter:active {
        background-color: #ffffff !important;
        color: $filter-font !important;
    
    }
    
    .source-but-state-filter span {
        display: inline-block;
        // margin-left: 8px;
        text-align: left;
    }
    
    .dropdown-menu-state-filter {
        width: 152px !important;
        margin-top: 40px;
        left: 225px;
        // margin-left: 420px !important;
    }
    
    .dropdown-menu-item {
        border-radius: 4px;
        width: 100%;
        text-align: center;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 5px;
        margin-top: 5px;
        font-size: 14px;
        padding: 4px;
    }
    
    .source-icon {
        float: left;
        font-size: 20px!important;
    }
    
    .buttonPositionStateFilter {
        // position: fixed!important;
        z-index: 999!important;
      }
    
    .source-menu-state-filter {
        text-align: left;
        top: 120px !important;
        min-width: 150px;
        padding: 10px 10px;
        right: 184px !important;
        left: auto !important;
        transform: none !important;
        position: static !important; 
        width: 180px;
    }

    .guideAnalytics-source-menu-state-filter {
        top: 63px !important;
    }
    
    .source-but-state-filter-dashboard {
        // position: absolute;
        right: 294px;
        width: 180px;
        position: absolute;
        height: 40px;
        font-size: 14px;


        padding: 0.5rem 20px;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: 1px solid $filter-btn-border;
        position : static !important;
        z-index: 999!important;


        color: #000 !important;
        border-radius: 4px;
        cursor: hand;
        border: 1px solid #BBBBBB !important;
        text-align: left;
        overflow: hidden;
        background: linear-gradient(180deg, #ffffff80, #f5f5f5) !important;
    
        .switchApp-btn-icon {
            position: absolute;
            right: 10px;
            top: 13px;
        }
    
        span.caret {
            display: none;
        }
    }
    
    .source-but-state-filter-dashboard:hover {
        background-color: #ffffff !important;
        color: $filter-font !important;
        
    }
    
    .source-but-state-filter-dashboard:active {
        background-color: #ffffff !important;
        color: $filter-font !important;
    
    }
    
    .source-but-state-filter-dashboard span {
        display: inline-block;
        // margin-left: 8px;
        text-align: left;
    }
    
    .source-menu-state-filter-dashboard {
        text-align: left;
        // top: 120px !important;
        min-width: 150px;
        // padding: 0 !important;
        // position: absolute;
        // right: 294px;
        // left: auto !important;
        transform: none!important;
        position: static!important;
        width: 180px;
        z-index: 999!important;
        padding: 10px !important;
        // width: 148px;
    }

    .source-menu-userEngagement-state-filter {
        text-align: left;
        top: 60px !important;
        min-width: 150px;
        padding: 10px 10px;
        right: 276px !important;
        left: auto !important;
        transform: none !important;
        position: static !important; 
        width: 180px;

        // width: 148px;
    }
}
