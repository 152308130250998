.surveyStatsTable {
    .table tbody tr td:nth-child(1) {
        width: 35%;
        .tableContent {
            max-width: 420px;
        }
    }

    .table tbody tr td:nth-child(2) {
        width: 15%;
    }

    .table tbody tr td:nth-child(3) {
        width: 15%;
    }

    .table tbody tr td:nth-child(4) {
        width: 15%;
    }
    .table tbody tr td:nth-child(5) {
        width: 20%;
    }

    .table th:nth-child(1) {
        width: 35%;
    }

    .table th:nth-child(2) {
        width: 15%;
    }

    .table th:nth-child(3) {
        width: 15%;
    }

    .table th:nth-child(4) {
        width: 15%;
    }
    .table th:nth-child(5) {
        width: 20%;
    }
}
