.worldLegend {
    color: #6f6c6c;
    font-size: 10px;
    padding: 2px 8px;
    font-weight: 600;
    width: 95px;
    display: inline-block;
    height: 15px;
    text-align: right;
    padding-right: 0px;
}

.paddingTop45 {
    padding-top: 45px;
}

