@import "style_variables";


.segmentFilterTitle {
    // position: absolute;
    font-size: 14px;
    color:#707070;
    font-weight: 700;

    top: 0px;
    right: 692px;
    z-index: 1;
}

.segmentsFilterDiv{
    display: flex; 
    align-items: center;
    justify-content: center;

    right: 567px;
    z-index: 999;
    width: 180px;
    height: 40px;
    font-size: 16px;


    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: none !important;
    top: 21px;
    // position: absolute;


    color: #000 !important;
    border-radius: 4px;
    cursor: hand;
    border: 1px solid #BBBBBB !important;
    text-align: left;
    overflow: hidden;
    background: linear-gradient(180deg, #ffffff80, #f5f5f5) !important;

    &:focus{
        border: 2px solid #005FCC !important;
    }

    &:hover{
        border: 1px solid #BBBBBB !important;
    }

    .segmentsIcon{
        display: flex;
        font-size: 20px !important;
        width: 20px;
    }
    .segmentsTitle{
        display: block;
        // text-align: center;
        width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
        text-transform: capitalize;
    }
    .dropdownIcon{
        display: flex;
        width: 20px;
    }
}

.segmentsFilterDivTooltips{
    right: 567px!important;
}

.segmentsFilterDiv:hover {
    background-color: #ffffff !important;
    color: $filter-font !important;
    border-radius: 4px;
    border: 1px solid;
    cursor: pointer;
}

.segmentsFilterDiv:active {
    background-color: #ffffff !important;
    color: $filter-font !important;
    border-radius: 4px;
    border: 1px solid;
}

.segmentsSearchDiv{
    display: flex;
    align-items: center;
    justify-content: right;
    align-content: space-around;
    min-height: 50px;
    padding: 10px;
}

.segmentsModalDiv{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-flow: row wrap;
    min-height: 150px;
    height: 150px;
    gap: 20px 10px;
    overflow-y: auto;
    padding: 20px 10px;

    .segmentsListDiv{
        // display: flex;
        flex: none;
        // width: 250px;
        height: 40px;
        background-color: #ffffff;
        color: $primaryColor-enable;
        border-radius: 4px;
        border: 1px solid $primaryColor-enable;
        // padding: 10px;
        display: inline-block;
        width: calc(50% - 10px);
        box-sizing: border-box;
        cursor: pointer;
        .segmentsListTitle{
            display:flex;
            align-items : center;
            justify-content: center;
            // width: 250px;
            height: 40px;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width:100%;

            .segTitle-overflow{ 
                width: 90%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
            
            }
        }
    }
    .segmentsListDiv:hover{
        background-color: $primaryColor-hover !important;
        color:#ffffff !important;
    }.segmentsListDiv:active{
        background-color: $primaryColor-enable !important;
        color:#ffffff !important;

    }

    .segmentsListDivActive{
        // display: flex;
        flex: none;
        // width: 250px;
        height: 40px;
        background-color: $primaryColor-enable !important;
        color: #ffffff !important;
        border-radius: 4px;
        border: 1px solid $primaryColor-enable;
        cursor: pointer;
    }
    .messageDiv{
        display: flex;
        align-items: center;
        justify-content: center;
        min-Height: 110px;
    }

}

.activeButton{
    background-color: $primaryColor-enable !important;
    color:#ffffff !important;
    border-radius: 4px;
}
