@import "style_variables";

.colorPickSelector {
    border-radius: 5px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;
    -ms-transition: all linear 0.2s;
    -o-transition: all linear 0.2s;
    transition: all linear 0.2s;
}

.colorPickSelector:hover {
    transform: scale(1.1);
}

.colorPicker {
    padding: 20px;
    border: 1px solid $error-red;
    display: none;
}

.area {
    height: 200px;
    width: 200px;
}

// .buttonParent:hover ~ .colorPicker {
//   background-color: red;
// }

.inputCC-div {
    width: 100% !important;
    svg {
        display: none;
    }
}