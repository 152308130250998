//Modified by: Saurabh Garg
//on: 25-Jan-23
//Reason: adding tutorial guide info in guide list and corresponding message in playmode

@import "style_variables";

.custom__control {
    height: 40px !important;
}

.custom__placeholder {
    color: #707070 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.flex-basis-50 {
    flex-basis: 50%;
}

.flex-basis-30 {
    flex-basis: 30%;
}

.box-sizing-border-box {
    box-sizing: border-box;
}

.goalFormSection {
    .react-datepicker-wrapper {
        padding: 0;
        border: 0;
        width: 100% !important;
    }
    .react-datepicker__tab-loop {
        position: relative;
    }
    
    .react-datepicker-popper {
        width: 350px !important;
        transform: auto !important;
        position: absolute;
        z-index: 3;
    }
    
    .react-datepicker__input-container {
        input {
            width: 100% !important;
            font-size: 14px;
            color: #454560;
            height: 40px;
            border-radius: 4px;
            border: 1px solid #ced4da;
            padding: 10px;
            cursor: pointer;
        }
    }
}




.timeZoneSpan {
    position: absolute;
    right: 20px;
    top: 10px;
}

$gap: 10, 15, 20, 25, 30, 35, 40, 45, 50, 150;

@each $gap in $gap {
    .gap-#{$gap} {
        gap: #{$gap}px !important;
    }
}

.goal-form-event-source {
    width: 100% !important;
    .eventSource-div {
        position: relative;
        width: 100% !important;

        .buttonPositionEventSource {
            position: relative !important;
            left: 0px;
            top: 0px;
            width: 100%;
            z-index: 1 !important;
        }

        .source-menu-event-source {
            right: auto !important;
            width: 100%;
            top: 40px !important;
            position: absolute !important;
            left: 0px !important;
        }
    }
}

.gaol-radio-container {
    width: 40px;
    .goalRadioButton {min-width: 24px;
        width: 24px !important;
        height: 24px !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid #383838 !important;
        border-radius: 50%;
        cursor: pointer;
        outline: none; // Changed to 'none' for clarity
        padding: 4px !important;
        background-clip: content-box;
        background-color: #ffffff !important;
        min-width: 24px;

        // Removed duplicate 'active' class styling
        &.active, &.hover { 
            background-color: $primaryColor-active !important;
            border: 1px solid $primaryColor-active !important;
        }

        &.active { 
            &.hover {
                background-color: $primaryColor-active !important;
                border: 1px solid $primaryColor-active !important;
            } 
        }
    }

    .btn-check {
        z-index: 3;
        opacity: 0; // This can remain, but ensure it's used correctly in your HTML
    }
}

.checkmark-tooltip {
    padding: 0 !important;
    margin: 0 !important;
    left: 0 !important;
    top: 0 !important;
    height: 24px !important;
    width: 24px !important;

    &::after {
        width: 7px !important;
        height: 14px !important;
        margin-left: 2px !important;
    }
}

.goal-container .checkmark-tooltip {
    top: 0px!important;
}

.formSection {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 147px!important;
}

.goalsTab {
    .tab-content {
        width: 100%;
        padding: 16px 0px;
        min-height: fit-content;
    }
}

.goalFormSection {
    .createEdit-goalform {

        padding-bottom: 30px;
        background-color: white !important;
        min-height: 273px;
        box-sizing: border-box;
        position: relative;
        width: 100%;
        display: inherit !important;
    
        .form-overlay {
            position: absolute !important;
            z-index: 1;
            border: none;
            background-color: transparent;
            height: 100%;
            top: 0px;
            padding: 0px 30px;
            left: 0px;
            display: none;
        }
    
        .placeholderText {
            padding: 10px;
            pointer-events: none;
            position: absolute;
            left: 0;
            top: -3px;
            transition: 0.2s;
            transition-timing-function: ease;
            transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
            opacity: 0.5;
        }
    
        .tagModalText {
            font-size: 12px;
            color: $grey1;
        }
    
        input[type="checkbox"] {
            -webkit-appearance: none;
            width: 24px;
            height: 24px;
            border: 1px solid #61aeff;
            box-shadow: 0 0 5px 0px white inset;
            outline: none;
        }
    
        .checkmark {
            height: 24px;
            width: 24px;
            background-color: #eee;
            border-radius: 2px;
            position: absolute;
            right: 5px;
            top: 5px;
        }
    
        .checkmark-tooltip {
            position: absolute;
            // top: 15px !important;
            left: 5px;
            height: 18px;
            width: 18px;
            border: 1px solid #cfd0dd;
            background-color: #ffffff !important;
            border-radius: 2px;
            cursor: pointer;
        }
    
        .check-container input:checked ~ .checkmark {
            background-color: #005FCC !important;
            border: 1px solid #005FCC;
        }
    
        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark-tooltip:after {
            content: "";
            position: absolute;
            display: none;
        }
    
        /* Show the checkmark when checked */
        .check-container input:checked~.checkmark-tooltip:after {
            display: block;
        }
    
        /* Style the checkmark/indicator */
        .check-container .checkmark-tooltip:after {
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            visibility: visible;
        }
    
        .check-container input:disabled~.checkmark-tooltip:after {
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid transparent;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            visibility: visible;
        }
    
        .check-container input.form-checkbox:disabled~.checkmark-tooltip:after {
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            visibility: visible;
        }
    
        .markGlobal-span {
            text-align: left;
            // margin-top: 22px;
            font-size: 13px;
            color: grey;
            margin-left: 8px;
            // padding-left: 18px;
        }
    
        .questions-scrollDiv {
            border: 1px solid lightgrey;
            border-radius: 4px;
            margin-top: 6px;
            height: 415px;
            overflow-x: auto;
            overflow-y: hidden;
            padding: 0 20px;
            position: relative;
            // max-width: calc(100% - 250px);
    
            overflow-x: auto;
    
            .questionList-ul {
                list-style: none;
                padding-left: 0px;
                padding-right: 0px !important;
    
                margin-top: 15px;
    
                .box-container {
                    padding: 0px 15px 12px 15px;
                    position: relative;
                }
            }
    
            .page-border {
                border: 2px dashed #bdbdbd;
                min-width: 500px;
                width: 500px;
                /* height: 336px; */
                /* overflow-y: scroll; */
                /* padding: 10px; */
                margin: 5px;
                border-radius: 10px;
    
                .page-title-header {
                    padding: 5px 20px;
                    margin-bottom: 0px;
                    border-bottom: 1px solid #e4e4e4;
                    font-size: 13px;
                    font-weight: 600;
                    color: $primaryColor-active !important;
    
                    .delete-page-icon {
                        color: $red-enable;
                        /* margin-top: 3px; */
                        border: 1px solid $red-enable;
                        padding: 5px 7px;
                        border-radius: 4px;
                        margin-top: -2px;
    
                        &:hover {
                            border: 1px solid $red-hover;
                            color: $red-hover;
                        }
                    }
                }
    
                .pageBoder-inner {
                    height: 270px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    padding: 10px;
                    width: 100%;
                    position: relative;
                    z-index: 1;
                    // -webkit-box-shadow: inset 0px -4px 13px 0px rgba(219,219,219,1);
                    // -moz-box-shadow: inset 0px -4px 13px 0px rgba(219,219,219,1);
                    // box-shadow: inset 0px -4px 13px 0px rgba(219,219,219,1);
                }
            }
    
            .qnOverlay-div {
                background-color: lighten($primaryColor-enable, 50%);
                top: 0px;
                width: 496px;
                left: 0px;
                height: 389px;
                z-index: 9;
                border-radius: 6px;
                position: absolute;
                opacity: 0.8;
                display: flex;
    
                .qnLink-btn {
                    height: 35px;
                    border-radius: 4px;
                    margin: auto;
                    background-color: #ffffff;
                    color: $purple-active;
                    font-size: 14px;
                    opacity: 1;
                    border: 1px solid $purple-active;
                    z-index: 10;
                    font-weight: 600;
                }
            }
        }
    
        .source-menu {
            text-align: left;
            top: -195px !important;
            min-width: 150px;
            padding: 0 !important;
            position: absolute;
            left: -15px !important;
        }
    
        .charMessage {
            position: absolute;
            bottom: 70px;
            left: 10px;
            /* top: 9px; */
            font-size: 12px;
            color: red;
        }
    
        .addDivPosition {
            // width: calc(100% - 20px);
            // margin: 10px;
            bottom: 0px;
            // display: none;
        }
    
        .inner-question-div {
            background-color: #ffffff !important;
            padding: 10px;
            /* border: 1px solid lightgray; */
            margin-top: 10;
            min-height: 76px;
            border-radius: 0px 0px 4px 4px;
            box-shadow: 0px -5px 10px -1px rgba(219, 219, 219, 1);
            position: relative;
            z-index: 2;
            padding-top: 16px;
    
            .questionDropdown-div {
                padding: 10px;
                background-color: #f3f3f3;
                border: 1px solid lightgray;
                border-radius: 4px;
            }
        }
    
        .qsType {
            height: 35px;
            color: $table-font-link;
            text-align: left;
    
            &:hover,
            &:focus,
            &:active {
                height: 35px;
                color: $table-font-link;
                border: none;
                outline: none;
                background-color: #e9e9e9;
            }
        }
    
        .qs-dropdownBtn {
            // border: 1px solid $lightgray;
            background-color: white;
            color: grey;
            font-size: 13px;
            height: 35px;
            cursor: default;
            display: flex;
            width: 145px;
            border-radius: 4px;
    
            .btn-customBorder {
                border: 1px solid black !important;
                background-color: white;
                font-size: 13px;
                height: 35px;
                line-height: 1;
                padding: 2px;
            }
    
            .btn-customBorder:first-child {
                width: 140px;
                min-width: 100px !important;
                border-radius: 4px 0 0 4px !important;
            }
    
            .btn-customBorder:last-child {
                min-width: 20px !important;
                border-radius: 0 4px 4px 0 !important;
            }
    
            .dropdown-menu-end {
                font-size: 14px;
            }
        }
    
        .btn-group>.btn+.dropdown-toggle {
            border: 1px solid $lightgray;
            background-color: white;
            font-size: 13px;
            height: 35px;
        }
    
        .dropdown-menu>li>a {
            color: $table-font-link !important;
            outline: none;
        }
    
        input[name="qn_lists"] {
            border: 1px solid lightgray;
            border-radius: 4px;
            font-size: 13px;
            padding: 5px;
            margin-top: 0px;
            height: 35px;
            width: 290px;
        }
    
        .question-button {
            float: right;
            right: 0;
            color: #fff;
            font-size: 12px;
            border-radius: 50%;
            padding: 8px;
            cursor: pointer;
            width: 35px;
            border: none;
            height: 35px;
            background-color: $primaryColor-enable;
        }
    
        .inner-question-mainDiv {
            background-color: $primaryTransparent;
            padding: 0px;
            border: 1px solid #dcdcdc;
            margin-top: 3;
            width: 100%;
            border-radius: 5px;
            margin-bottom: 15px;
            padding-bottom: 15px;
    
            .hoverHeader {
                height: 28px;
                background-color: white;
                /* text-align: center; */
                display: flex;
                align-items: center;
                padding: 6px 10px 6px 10px;
    
                display: block;
                border-bottom: 1px solid transparent;
                color: $primaryColor-enable;
                border-radius: 4px 4px 0px 0px;
    
                .questionNumber {
                    position: relative;
                    top: -3px;
                }
    
                // transition: all 2s linear;
            }
    
            .quesContainer {
                padding: 10px;
            }
    
            &:hover {
                border: 1px solid $primaryColor-active;
    
                // cursor: move;
                .hoverHeader {
                    display: block;
                    // border-bottom: 1px solid #6bc9b8;
                    // transition: all 2s linear;
                    // margin-top: -40px;
                    // margin-right: -1px;
                    // margin-left: -1px;
                }
            }
    
            .linkPage-btn {
                border-radius: 4px;
                position: absolute;
                right: 45px;
                border: 1px solid $purple-enable;
                color: $purple-enable;
                background-color: white;
                font-weight: 600;
                font-size: 13px;
    
                &:disabled {
                    border: 1px solid $grey-enable;
                    color: $grey-enable;
                    background-color: lighten($grey-enable, 30%);
                }
            }
        }
    
        .create-survey-container {
            // background: #61aeff;
            padding: 5px;
            // min-width: 60px;
            height: 30px;
            text-align: center;
            font-size: 12px;
            vertical-align: top;
            // cursor: pointer;
            // border-radius: 4px;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            margin: 10px 0px 0px 0px;
            // border: 1px solid #4999ec;
            line-height: 1.1;
            width: 110px;
            // color: white;
            display: flex;
            align-items: center;
    
            .add-btn-label {
                // color: white;
            }
        }
    
        .add-page-btn {
            min-width: 500px;
            width: 500px;
            height: calc(100% - 10px);
            font-size: 20px;
            margin-top: 5px;
            border-radius: 5px;
            cursor: pointer;
            background-color: white;
            color: $primaryColor-enable;
            border: 2px solid #c6c6c6;
    
            img {
                height: 100px;
                align-items: center;
                left: 42%;
                position: relative;
            }
    
            .add-btn-label {
                margin-top: 28%;
                position: relative;
                left: 21%;
                width: 18%;
            }
        }
    
        .add-page-btn:disabled {
            background-color: #efefef;
            color: #c1c1c1;
            cursor: default;
        }
    
        .box-container {
            input {
                outline: 2px solid transparent !important;
            }
        }
    
        .radio-container {
            display: block;
            position: relative;
            padding-left: 10px;
            margin-bottom: 26px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: default;
        }
    
        /* Hide the browser's default radio button */
        .radio-container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
    
        /* Create a custom radio button */
        .radio-checkmark {
            position: absolute;
            top: -3;
            left: 8;
            height: 20px;
            width: 20px;
            background-color: white;
            border-radius: 50%;
            border: 1px solid #d9d9d9;
        }
    
        /* On mouse-over, add a grey background color */
        .radio-container:hover input~.radio-checkmark {
            background-color: white;
        }
    
        /* When the radio button is checked, add a blue background */
        .radio-container input:checked~.radio-checkmark {
            background-color: white;
        }
    
        /* Create the indicator (the dot/circle - hidden when not checked) */
        .radio-checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
    
        /* Show the indicator (dot/circle) when checked */
        .radio-container input:checked~.radio-checkmark:after {
            display: block;
        }
    
        /* Style the indicator (dot/circle) */
        .radio-container .radio-checkmark:after {
            top: 5px;
            left: 5px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: transparent;
        }
    
        .checkmark-qn {
            position: absolute;
            top: -3px;
            left: 5px;
            height: 18px;
            width: 18px;
            border: 1px solid #cfd0dd;
            background-color: white !important;
            border-radius: 2px;
            cursor: default !important;
        }
    
        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark-qn:after {
            content: "";
            position: absolute;
            display: none;
        }
    
        /* Show the checkmark when checked */
        .check-container input:checked~.checkmark-qn:after {
            display: block;
        }
    
        /* Style the checkmark/indicator */
        .check-container .checkmark-qn:after {
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            visibility: visible;
        }
    
        .check-container input:disabled~.checkmark-qn:after {
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid transparent;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            visibility: visible;
        }
    
        .check-container input.form-checkbox:disabled~.checkmark-qn:after {
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            visibility: visible;
        }
    
        .starRating {
            font-size: 18px;
            color: #91ce4b;
        }
    
        .rangePadding {
            padding: 3px 4px 4px 4px;
            height: 20px;
            width: 20px;
            background-color: #ffd89d;
            display: flex;
            align-items: center;
            border-radius: 4px;
            border-radius: 50%;
    
            .range-text {
                margin: 0 auto;
                border-radius: 4px;
                font-weight: 600;
                font-size: 12px;
            }
        }
    
        .redRange {
            background-color: #f03a3b;
        }
    
        .yellowRange {
            background-color: #f6c401;
        }
    
        .greenRange {
            background-color: #89ce01;
        }
    
        .faceIcon {
            margin: auto;
            width: 25px;
            height: 25px;
            margin-left: -6px;
        }
    
        .bg-transparent {
            background-color: transparent;
        }
    
        .requiredStar {
            color: $brightred;
            font-size: 12px;
        }
    
        .analyzerDiv {
            width: 100px;
            display: flow-root;
            height: 30px;
            margin-top: -10px;
            position: absolute;
            top: -10px;
            left: 20px;
        }
    
        .errorMsg-path {
            text-align: center;
            padding: 9px;
            font-size: 12px;
            color: red;
            background-color: antiquewhite;
        }
    
        .linkBtn {
            width: 25px;
            height: 25px;
            margin-left: 10px;
            border-radius: 4px;
            font-size: 12px;
            padding-top: 0px;
    
            i {
                margin-left: -1px;
                margin-top: -2px;
                font-size: 12px;
            }
        }
    
        .deletePage-btn-square {
            z-index: 0;
    
            &::before {
                margin-left: -5px;
            }
        }
    
        .addOption-btn {
            margin-left: 20px !important;
    
            i {
                margin-right: 6px !important;
            }
        }
    }
}

