@import "style_variables";

.modalBodyContainer {
    display: flex;
    min-height: 300px;
}
.createDashboardContainer {
    display: flex;
    min-height: 150px;
    flex-direction: column;

    .dropdown-menu {
        width: 200px;
        // display: inline-flex;
        flex-wrap: wrap;
        margin-top: 38px;
    }

    .dropdown-item {
        max-width: 33% !important;
        width: 33% !important;
        height: 42px !important;
        display: inline-block;
    }
}
.existingDashboardContainer {
    display: flex;
    min-height: 150px;
    flex-direction: column;

    /* Create the checkmark/indicator (hidden when not checked) */

    .checkmark {
        background-color: $lightgray !important;
        margin-top: -4px;
        opacity: 0;
    }

    .checkmark-tooltip:after {
        content: "";
        position: absolute;
        display: none;
    }

    .check-container {
        margin-top: 0px;
        cursor: pointer;
        width: 115px;
        position: absolute;
        top: 6px;
        right: 20px;
        width: 20px;
    }

    /* Show the checkmark when checked */
    .check-container input:checked ~ .checkmark-tooltip:after {
        display: block;
        margin-left: -7px;
    }

    /* Style the checkmark/indicator */
    .check-container .checkmark-tooltip:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        visibility: visible;
    }

    .check-container input:disabled ~ .checkmark-tooltip:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid transparent;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        visibility: visible;
    }

    .dashboradIcon {
        position: relative;
        right: 22px;
        top: 0px;
    }
}

.icon-box {
    width: 32px;
    height: 32px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin: 0 auto;

    .dashboardIcon-div {
        margin: 0 auto;
        cursor: pointer;
    }
}

.warningContainer {
    display: flex;
    min-height: 50px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $yellowsandybrown;
    padding: 0px 15px;
    margin: 5px 0px;
    border-radius: 4px;
    align-items: center;
    color: $yellowsandybrown;
    font-weight: 600;
}
.dashboardNameContainer {
    display: flex;
    min-height: 48px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #f2f2f7;
    padding: 0px 15px;
    margin: 5px 0px;
    border-radius: 2px;
    align-items: center;
    background-color: #f2f2f7;
    color: $grey1;

    .dashboardTitle {
        width: 57%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        // margin-left: -60px;
        // font-weight: 600;
    }

    .deleteDashboardIcon {
        // color: $grey-focus;
        // font-size: 18px;
        font-weight: 600;
        cursor: pointer;
        z-index: 5 !important;

        &:hover path{
            fill: $red-hover;
        }
    }
}

.addDashboard-btn {
    font-size: 14px;
    width: 110px;
    text-shadow: none;
    height: 30px;
    border: 1px solid $customDashboard-saveBtn-border !important;
    color: $grey1 !important;
    background-color: $white !important;
    line-height: 1;

    .pinIcon {
        width: 10px;
        height: 15px;
        margin-right: 5px;
        // width: 28px;
    }

    &:hover {
        border: 1px solid $primaryColor-hover !important;
        color: $white !important;
        background-color: $primaryColor-hover !important;

        .pinIcon path {
            // padding: 10px 0px;
            fill: $white;
        }
    }
}

.dashboardDefault-icon path{
    fill:$primaryColor-active!important
}


.dashboardIcon-btn {
    background-color: transparent;
    border: 1px solid #4a426b;
    color: #4a426b;
    font-size: 14px;
    width: 130px;
    height: 36px;
    padding: 0px;

    &:hover {
        background-color: $purple1;
        border: 1px solid white;
        color: white;
    }
}

.buttonContainer {
    display: flex;
    min-height: 40px;
    justify-content: space-between;
    align-items: center;
    .deleteIcon {
        width: 30px !important;
        height: 30px !important;
        margin-left: 20px !important;
    }
}

.createDashboardContainer {
    .createNew {
        // border: 1px dashed #cfd0dd;
        /* padding: 10px; */
        border-radius: 4px;
        /* width: 237px; */
        text-align: center;
        cursor: pointer;
        height: 36px;
        box-sizing: content-box;
        width: 230px;
        background-color: $primaryColor-enable;
        color: $white;
        margin: 0 auto;
        display: flex;
        align-items: center;
        line-height: 1;
        padding-left: 40px;

        svg {
            margin: 0 10px 0px 18px;
            width: 15px;
            height: 15px;
        }

        &:hover {
            background-color: $primaryColor-hover;
        }
    }
    .createNewDisabled {
        // border: 1px dashed #cfd0dd;
        /* padding: 10px; */
        border-radius: 4px;
        /* width: 237px; */
        text-align: center;
        cursor: pointer;
        height: 36px;
        box-sizing: content-box;
        width: 230px;
        background-color: $lightgray;
        color: $white;
        margin: 0 auto;
        display: flex;
        align-items: center;
        cursor: not-allowed;
        line-height: 1;

        svg {
            margin: 0 10px 0px 18px;
            width: 15px;
            height: 15px;
        }

        &:hover {
            background-color: $lightgray;
        }
    }

    .warningTextDiv {
        display: flex;
        width: 860px !important;
        height: 20px !important;
        background-color: transparent;
        color: $error-red;
        justify-content: flex-end;
    }
}

.hrSpan {
    background-color: $lightgray;
    height: 1px;
}
.deleteChart {
    font-weight: 600;
    padding: 30px 20px 50px;
}
