@import "style_variables";

.displaySentimentsFilter {
    width: 200px;
}

.dropdownBtn {
    align-items: center;
    padding: 5px 10px;
    max-height: 34px;
    min-height: 34px;
    border-radius: 4px;
    width: 180px;
    display: flex;

    color: #000 !important;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #BBBBBB !important;
    text-align: left;
    overflow: hidden;
    background: linear-gradient(180deg, #ffffff80, #f5f5f5) !important;
    text-transform: capitalize;

    .dropDownBtnTxt {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
        display: block;
    }
}

.filterBtns-div {
    height: 80px;

    .survey-filter {
        // position: absolute;

        width: 100%;
        position: absolute;
        min-height: 40px;
        // height: 40px;

        font-size: 14px;

        // padding: 0.5rem 20px;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1;



        color: #000 !important;
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid #BBBBBB !important;
        text-align: left;
        overflow: hidden;
        background: linear-gradient(180deg, #ffffff80, #f5f5f5) !important;
        text-transform: capitalize;

        .switchApp-btn-icon {
            position: absolute;
            right: 10px;
            top: 13px;
        }

        span.caret {
            display: none;
        }
    }

    .survey-dropdown {
        top: 39.9px !important;
        border-radius: 0px;
        background: white;
        opacity: 1;
        width: 180px;
        padding: 16px;
    }

    ul.survey-filter {
        padding: 0px;

        p.sentimentsDropDownList {
            padding: 3px 10px;
            height: 35px;
            margin: 0px;
            line-height: 2;
            cursor: pointer;
        }
    }

    .listHighlight {
        background-color: $lightgray;
    }

    .survey-filter:hover {
        background-color: #ffffff !important;
        color: $filter-font !important;
    }

    .survey-filter:active {
        background-color: #ffffff !important;
        color: $filter-font !important;
    }

    .survey-filter span {
        display: inline-block;
        // margin-left: 8px;
        text-align: left;
        display: inline-block;
        text-align: left;
        width: 180px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 4px;
    }

}


.guideSurvey-modal {
    width: 1100px !important;
    max-width: 1100px !important;

    .modal-question {
        height: 70vh;
        overflow-y: auto;
        margin-left: -15px;
        margin-right: -15px;
        padding: 15px;
        border-top: 1px solid $lightgray;
    }

    .modal-dialog {
        max-width: 1000px !important;
    }
}