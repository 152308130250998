@import "style_variables";

.main-rules-div {
    margin-top: 10px;
    // margin-right: 25px;
    padding: 0px 8px;

    .tags-and-text {
        text-align: left;
        color: $primaryColor-enable;
        padding: 0px 1px;
        font-size: 13px;
        font-style: italic;
        margin: 10px 5px;
    }

    .tags-outer-div {
        background-color: white;
        // padding: 3px;
        // border: 1px solid #c7c7c7;
        border-radius: 4px;

        .tags-inner-div {
            // background-color: #f6f6f6;
            padding: 10px;
            border: 1px solid #c7c7c7;
            border-radius: 4px;

            .width30Perct {
                width: 30%;
            }

            .tag-inputBox {
                text-align: left;
                font-size: 13px;
                height: calc(2.25rem + 2px);
                /*font-size: 14px;*/
                line-height: 1.3;
                border: #c7c7c7 1px solid;
                border-radius: 4px;
                padding: 5px 10px;
                margin-left: 0px;
                margin-right: 6px;
                background-color: $grey6;
                /*margin-top: 3px;*/
                /*margin-bottom: 10px;*/
                box-sizing: content-box;
                height: 19px;

                .app-domain-text {
                    width: 100%;
                    font-size: 13px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .infoIcon {
                margin-right: 0px;
                margin-left: 2px;
                padding: 5.5px 10px;
                background-color: #00000014;

                .fa-info-circle {
                    color: grey !important;
                    font-size: 16px;
                    margin-top: 1px;
                }
            }

            .tag-rule-div {
                width: calc(100% - 0px);

                

                .width31Perct {
                    width: 31%;
                }

                .urlInput {
                    height: 32px !important;
                    ;
                    margin-right: 6px;
                }

                .deleteIcon {
                    text-align: left;
                    font-size: 14px;
                    height: 32px;
                    line-height: 2;
                    border: #c7c7c7 1px solid;
                    border-radius: 4px;
                    padding: 2px 11px 5px 11px;
                    margin-left: -1px;
                    /* margin-right: 3px; */
                    // background-color: #00000014;
                    // color: grey;
                }

                .errorContainer {
                    min-height: 20px;
                    margin-top: 5px;

                    .errorMsg {
                        font-size: 12px;
                        color: red;
                        text-align: left;
                    }
                }
            }

            .deleteOuterRule-div {
                display: flow-root;
                margin-bottom: 20px;
                margin-top: -8px;
                margin-right: -8px;

                .deleteOuterRule-span {
                    width: 35px;
                    display: inline-block;
                    height: 35px;
                    background-color: #ffffff;
                    border: 1px solid #c7c7c7;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    float: right;
                    margin-top: -19px;
                    margin-right: -17px;

                    .deleteOuterRule-icon {
                        margin: 0 auto;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .selectUrl {
        border: 1px solid #BBBBBB ;
        height: 32px !important;
        margin-right: 6px;
        width: 30%;
        outline: none !important;
        padding: 4px 8px  !important;
        gap: 0px;
        border-radius: 4px;
        background: linear-gradient(180deg, #ffffff80, #f5f5f5) !important;
        border: 1px solid #BBBBBB;
        font-size: 15px !important;
    }
    .valErrors {
        text-align: right;
        padding-right: 46px;
    }

    .iconDisplay {
        display: none;
        margin-left: -1px;
        margin-right: 8px;
    }

    .invalidIcon {
        height: 32px;
        padding: 8px 11px 5px 11px;
        border: 1px solid $red-active;
        background-color: $lightpink;
        border-radius: 4px;
        color: $red-active;
    }

    .validIcon {
        height: 32px;
        padding: 8px 10px 5px 10px;
        border: 1px solid $lightgreen;
        color: $lightgreen;
        background-color: #d2ecc3;
        border-radius: 4px;
    }


}

.domainDropdown {
    width: 72px;
    height: 40px;
    padding: 8px 12px 8px 10px;
    border-radius: 4px;
    border: 1px;

    font-size: 16px;
    border: 1px solid rgba(187, 187, 187, 1) !important;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
        linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #F5F5F5 100%) !important;

    color: #000000 !important;
}

.create-rules-container {
    font-size: 14px;
    // background: #e2e2e8;
    // padding: 5px;
    // min-width: 70px;
    // width: 70px;
    // height: 30px;
    text-align: center;
    // font-size: 15px;
    vertical-align: top;
    cursor: pointer;
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin: 10px 0px 0px 0px;
    // border: 1px solid #a2a2a2;
    line-height: 1.1;
    // width: 116px;
    margin-left: 10px;
    display: flex;
    align-items: center;

    .fa-plus {
        pointer-events: auto;
        border: 1px solid #005FCC;
        padding: 3px 4px;
        border-radius: 50%;
        font-size: 12px;
    }
}

.modal-addMoreGroup-btn {
    width: 100% !important;
    background-color: transparent;
    // color: #9475c9;
    // border: 2px dashed #a7a6a6;
    margin: 4px !important;
    margin-left: 0px !important;
    height: 50px;
}


.ruleInputs-div {
    width: 85%;
    display: flex;
}

.ruleActions-div {
    width: 15%;
    display: flex;
}

.ruleUserInput-div {
    width: 780px;
    display: flex;

}

.ruleUserAction-div {
    width: 100px;
    display: flex;
    position: relative;
    left: -22px;
}

// .add-btn-label {
//   color: #414141;
//   vertical-align: middle;
//   font-size: 14px;
// }

// .tag-rule-div-or {
//   width: calc(100% - 0px);
//   margin-bottom: 10px;
// }

// .tag-rule-div-or {
//   width: calc(100% - 0px);
//   margin-bottom: 10px;
// }

// .requiredErrorMsg {
//   font-size: 12px;
//   margin-top: -10px;
//   float: left;
//   padding-left: 15px;
//   color: red;
// }

// .valuecount {
//   float: right;
//   width: auto;
//   font-size: 12px;
//   margin-top: -10px;
//   color: gray;
// }