@import "style_variables.scss";
@import "../../../components/Table/CommonTable.scss";

.tutorialGuideAnalytics {
    .font16 {
        font-size: 16px;
    }
    .font13 {
        font-size: 13px;
    }
    .guide-count {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    .countHeight {
        height: 40px;
    }

    .completeCount-text {
        color: #38b6a0;
    }
    .payCount-text {
        color: #f79b42;
    }

    .table tbody tr td:nth-child(1),
    .table th:nth-child(1) {
        width: 30%;
        .tableContent {
            // max-width:35%;
            // cursor: pointer !important;
        }
    }

    .table tbody tr td:nth-child(2),
    .table th:nth-child(2) {
        // width: 12.5%;
    }

    .table tbody tr td:nth-child(3),
    .table th:nth-child(3) {
        // width: 20%;
    }

    .table tbody tr td:nth-child(4),
    .table th:nth-child(4) {
        // width: 12.5%;
    }
    .table tbody tr td:nth-child(5),
    .table th:nth-child(5) {
        // width: 12.5%;
    }

    .table tbody tr td:nth-child(6),
    .table th:nth-child(6) {
        // width: 12.5%;
    }

 

  
    .weekendsIncluded-p {
        margin-top: 10px;
        display: flow-root;

        span {
            float: right;
            font-size: 14px;
            background-color: #ffeec9;
            border-radius: 2px;
            padding: 2px 20px;
            color: #c7a55f;
            border: 1px solid #ded9cf;
        }
    }
}
