@import "style_variables";

.paddingTopBottom {
    padding-bottom: 13px;
    padding-top: 11px !important;
}

.datepiecker-div {
    // position: absolute;
    z-index: 40;
    // right: 12px;
    // top: 20px;

    button.dropdown-toggle {
        padding: 0px;
        width: 180px;

        /* outline: transparent; */
        box-shadow: none;

        color: #000 !important;
        border-radius: 4px;
        cursor: hand;
        border: none !important;
        text-align: left;
        overflow: hidden;
        background: linear-gradient(180deg, #ffffff80, #f5f5f5) !important;
        height: 41px!important;
    }


 

    .weekend-optn-text {
        font-size: 10px;
        text-align: center;
        position: absolute;
        top: 27px;
        left: 29px;
        /* transform: translateX(-50%); */
        color: $grey-focus;
        z-index: 9;
    }

    .lastYearOptn {
        border-top: 1px solid #ababab;
        margin-top: 10px;
        padding-top: 20px;
        position: relative;


        .realTime-warning {
            position: absolute;
            font-size: 11px;
            width: 100%;
            text-align: center;
            color: #b36c00;
            background-color: #fedac3;
            /* border: 1px solid #c6883e; */
            top: 4px;
        }
    }

    .realtime-data-chip {
        font-size: 10px;
        text-align: center;
        position: absolute;
        top: 26px;
        right: 0px;
        /* transform: translateX(-50%); */
        color: $table-header-font;
        z-index: 9;
        background-color: #eeb161;
        padding: 0px 5px;
        border-radius: 4px;
    }

    .caret {
        display: none;
    }

    .date-input {
        width: 124px;
        height: 40px;
        border-radius: 4px;
        padding-top: 6px;
        padding-left: 12px;
        padding-right: 40px;
        font-size: 16px;
        height: 15px;
        box-sizing: content-box;
        // padding-bottom: 18px;
        cursor: pointer;

        outline: none !important;


        color: #000 !important;
        border-radius: 4px;
        cursor: hand;
        border: 1px solid #BBBBBB !important;
        text-align: left;
        overflow: hidden;
        background: linear-gradient(180deg, #ffffff80, #f5f5f5) !important;

        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        display: flow !important;

        &:focus {
            border: 1px solid #BBBBBB !important;
        }

        &:hover {
            border: 1px solid #BBBBBB !important;
        }
    }

    ul.dropdown-menu {
        width: 100%;
    }

    .calenderOptionspadding {
        height: 40px;
        &:hover {
            background-color: #ebf2fe;
        }

        .datepickerRadioButtons {
            height: 35px;
        }
    }

    .lastYearOptn {
        height: 50px;
    }

    .datepicker-optn {
        display: flex;
        // margin-left: 30px;
        align-items: center;
        // font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        margin-left: 10px;
        // margin-top: 8px;
    }

    .radio-container {
        // display: block;
        // position: relative;
        // padding-left: 10px;
        // margin-bottom: 26px;
        // cursor: pointer;
        // font-size: 22px;
        // -webkit-user-select: none;
        // -moz-user-select: none;
        // -ms-user-select: none;
        // user-select: none;
        // cursor: default;

        display: block;
        position: relative;
        padding-left: 0px;
        margin-bottom: 20px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        user-select: none;
        cursor: default;
        top: -9px;
    }

    /* Hide the browser's default radio button */
    .radio-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .radio-checkmark {
        cursor: pointer;
        position: absolute;
        top: 6px;
        left: 2px;
        height: 17px;
        width: 17px;
        background-color: $white;
        border-radius: 50%;
        border: 1px solid #d9d9d9;
    }

    /* On mouse-over, add a grey background color */
    .radio-container:hover input~.radio-checkmark {
        background-color: $white;
    }

    /* When the radio button is checked, add a blue background */
    .radio-container input:checked~.radio-checkmark {
        background-color: $white;
        border: 1px solid $primaryColor-active;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .radio-checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .radio-container input:checked~.radio-checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .radio-container .radio-checkmark:after {
        top: 4px;
        left: 4px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: $primaryColor-active;
    }

    ::after {
        visibility: visible;
    }

    .customDateBtn {
        color: $primaryColor-active;
        border: 1px solid $primaryColor-active;
        background-color: $white;
        font-size: 12px;
        width: 90px;
        height: 26px;
        border-radius: 4px;
        margin-left: 14px;
        line-height: 1;
        padding: 0px;
        word-wrap: break-word;
        white-space: normal;
    }

    .exclude-apply-div {
        // display: flex;
        justify-content: space-between;
        font-size: 14px;
        height: 120px;
        padding: 0 0px;
        box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.08);
        color: #383838;
        padding: 10px;

        .datePicker-optns {
            height: 30px;

            .toggleText {
                margin-left: 50px;
                float: left;
            }
        }

        .checkmark {
            position: absolute;
            top: 6px;
            left: 0px;
            height: 24px;
            width: 24px;
            background-color: $white;
            border-radius: 3px;
            border: 1px solid $lightgray;
        }

        input:disabled~.checkmark {
            background-color: $lightgray !important;
            border: 1px solid $lightgray;
            cursor: default;
        }

        input:disabled~.checkmark::after {
            border: none !important;
        }

        .exclude-text {
            // color: $table-header-font;
        }

        .applyDate-btn {
            // background-color: $primaryColor-active;
            // color: $white;
            // font-size: 12px;
            // width: 90px;
            // height: 30px;
            // border: none;
            // border-radius: 4px;
            // right: 8px;
            // position: absolute;

            width: 90px;
            height: 40px;
            margin-top: 10px;
            font-size: 16px;
            float: right;
        }
    }

    /*custom checkbox */
    .custom-check {
        display: block;
        position: relative;
        padding-left: 18px;
        margin-bottom: 0px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin-top: 0px;
        top: -6px;
    }

    /* Hide the browser's default checkbox */
    .custom-check input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */

    /* On mouse-over, add a grey background color */
    .custom-check:hover input~.checkmark {
        background-color: $white;
        border: 1px solid $lightgray;
    }

    /* When the checkbox is checked, add a blue background */
    .custom-check input:checked~.checkmark {
        background-color: $primaryColor-active;
        border: 1px solid $primaryColor-active;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .custom-check input:checked~.checkmark:after {
        display: block !important;
        visibility: visible !important;
    }

    /* Style the checkmark/indicator */
    .custom-check .checkmark:after {
        left: 6px;
        top: 2.2px;
        width: 6px;
        height: 11px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(90deg);
        transform: rotate(45deg);
    }

    .max180Days-error {
        position: absolute;
        color: $salmon;
        border: 1px solid $salmon;
        padding: 10px;
        width: 472px;
        font-size: 12px;
        border-radius: 4px;
        right: 272px;
        top: 380px;
        background-color: $lightpink;
    }
}

// .datepiecker-div-modal{
//   right: 369px!important;
// }

.datePickerClassName {
    height: 320px !important;
    min-width: 485px !important;
    top: 225px !important;
    right: 18px !important;
}



.datepiecker-div {
    .react-datepicker-popper {
        width: 472px;
        top: -56px !important;
        right: 412px !important;
        left: auto !important;
        transform: translate3d(145px, -170px, 0px) !important;
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker__day {
        font-size: 16px;
        width: 32px;
        height: 32px;
        padding: 0px;
        margin: 0px;
        line-height: 2;
        border-radius: 0px;
    }

    .react-datepicker__header {
        padding-top: 40px;
        background-color: $white-shade1;
    }

    .react-datepicker__header__dropdown--select {
        position: absolute;
        top: 5px;
        /* transform: translateX(-50%); */
        width: 410px;
        z-index: 1;
        left: 12%;
    }

    .react-datepicker__month-select,
    .react-datepicker__year-select {
        width: 114px;
        height: 28px;
        font-size: 14px;
        border: 1px solid $lightgray;
        border-radius: 4px;
        padding: 0px 5px;
        color: $table-header-font;
    }

    .react-datepicker__current-month {
        font-size: 20px;
        font-weight: normal;
        color: $table-header-font;
    }

    .react-datepicker__day-name {
        font-size: 13px;
        width: 32px;
        padding: 0px;
        margin: 0px;
        font-style: italic;
        color: #17a2b8;
        margin-top: 10px;
        margin-bottom: 8px;
    }

    .react-datepicker__week {
        $white-space: nowrap;
        height: 32px;
        min-height: 32px;
        display: flex;
        align-items: center;
    }

    .react-datepicker__month {
        height: 200px;
    }

    .react-datepicker__navigation--previous {
        border-right-color: #007bff;
    }

    .react-datepicker__navigation--next {
        border-left-color: #007bff;
    }

    .react-datepicker__day--in-range {
        background-color: rgba(2, 117, 216, 0.5);
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end {
        background-color: #0275d8;
        font-weight: normal;
        border-radius: 0px;
    }

    .react-datepicker__navigation--previous {
        border: 2px solid #007bff !important;
        border-left: 2px $white !important;
        border-top: 2px $white !important;
        left: 15px;
        transform: rotate(135deg);
        height: 10px !important;
        width: 10px !important;
        color: #007bff !important;
        top: 12px;
    }

    .react-datepicker__navigation-icon--previous {
        visibility: hidden;
    }

    .react-datepicker__navigation--next {
        border: 2px solid #007bff !important;
        border-right: 2px $white !important;
        border-bottom: 2px $white !important;
        right: 15px;
        transform: rotate(135deg);
        height: 10px !important;
        width: 10px !important;
        top: 12px;
    }

    .react-datepicker__navigation-icon--next {
        visibility: hidden;
    }
}

// .react-datepicker__day{
//   color:$table-header-font;
//   border-radius: 0px!important;
// }

// .react-datepicker__day--disabled{
//   color:#dfdada;
//   border-radius: 0px!important;
// }

// .react-datepicker__day--keyboard-selected{
//     background-color: #2a87d0;
//     color: $white!important;
//     font-weight: normal!important;
// }

// .react-datepicker__day--in-range{
//   color: $white!important;
// }

.datepickerRadioButtons {
    .datepickerRadio {
        width: 24px !important;
        height: 24px !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid #383838 !important;
        border-radius: 50%;
        cursor: pointer;
        outline: none; // Changed to 'none' for clarity
        padding: 4px !important;
        background-clip: content-box;
        background-color: #ffffff !important;
        margin-top: 6px;
        min-width: 24px !important;

        // Removed duplicate 'active' class styling
        &:active,
        &:hover {
            background-color: $primaryColor-active !important;
            border: 1px solid $primaryColor-active !important;
        }

    }

    .btn-check {
        position: relative;
        z-index: 3;
        width: 17px;
        height: 17px;
        top: 2px;
        left: 18px;
        opacity: 0;
        cursor: pointer;
    }

    .active {
        background-color: $primaryColor-active !important;
        border: 1px solid $primaryColor-active !important;
    }

    .btn-default:active:hover {
        background-color: $primaryColor-active !important;
            border: 1px solid $primaryColor-active !important;
    }

    .btn-check:checked+.btn-primary {
        background-color: $primaryColor-active !important;
        border: 1px solid $primaryColor-active !important;
    }

    .btn-check:checked+.btn-primary:focus {
        box-shadow: none;
    }
}

.datePickerClassNameExportModal {
    height: 320px !important;
    min-width: 239px !important;
    top: 225px !important;
    right: -165px !important;

    .react-datepicker__month-dropdown-container--select,
    .react-datepicker__year-dropdown-container--select {
        margin: 0px !important
    }

    .react-datepicker__month-container {
        width: 300px !important;
    }

    .react-datepicker__header__dropdown--select {
        position: absolute !important;
        top: 5px !important;
        /* transform: translateX(-50%); */
        width: 233px !important;
        z-index: 1 !important;
        left: 12% !important;
    }

    .react-datepicker__week {
        margin-left: 35px !important;
    }
}

.dateRange-title {
    // position: absolute;
    right: 119px;
    font-size: 14px;
    color: #707070;
    font-weight: 700;
}

#dropdown-custom-1 {
    width: 180px;
    padding: 0px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;

    .source-icon {
        position: absolute;
        left: 10px;
        color: $table-body-data;
    }

    .dropdown-menu.show {
        width: 272px!important;
        top: 2px !important;
        left: -93px !important;

    }
}

.exportList {
    #dropdown-custom-1 {
   
        .dropdown-menu.show {
            width: 100%!important;
            top: 2px !important;
            left: 0px !important;
    
        }
    }
}