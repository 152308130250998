@import "style_variables";

input {
    &:disabled {
        color: grey;
    }
    &:focus {
        + {
            .placeholderText {
                opacity: 1;
                transform: scale(0.75) translateY(-100%) translateX(-30px);
                background-color: $white;
                left: 20px;
                position: absolute;
                padding: 0px;
                color: $primaryColor-enable;
                top: 0px;
                opacity: 1;
                transform: scale(0.75) translateY(-100%) translateX(-30px);
                background-color: $white;
                left: 20px;
                position: absolute;
                padding: 0px;
                color: $primaryColor-enable;
                top: 0px;
            }
        }
        border: $primaryColor-enable 2px solid;
        border-color: $primaryColor-enable !important;
    }
    &:not(:placeholder-shown) {
        + {
            .placeholderText {
                opacity: 1;
                transform: scale(0.75) translateY(-100%) translateX(-30px);
                background-color: $white;
                left: 20px;
                position: absolute;
                padding: 0px;
                color: $primaryColor-enable;
                top: 0px;
            }
        }
    }
    &:not(:-ms-input-placeholder) {
        + {
            .placeholderText {
                opacity: 1;
                transform: scale(0.75) translateY(-100%) translateX(-30px);
                background-color: $white;
                left: 20px;
                position: absolute;
                padding: 0px;
                color: $primaryColor-enable;
                top: 0px;
            }
        }
    }
    box-shadow: none !important;
    // border: #eaeaea 2px solid;
}

.switchApp_btn {
    display: flex;
    align-items: center;
    width: 120px;
    border-radius: 4px 0px 0px 4px;

    position: relative;
    left: 10px;
    top: -2px;
    /* width: 40px; */
    height: 35px;
    font-size: 14px;
    color: $primaryColor-active;
    border: 1px solid $primaryColor-active;
    background-color: #ebf7f5;
    overflow: hidden;
    text-overflow: ellipsis;
    $white-space: nowrap;
    padding: 15px;
    font-weight: 500;
    border-right: 1px solid transparent;
    &:hover {
        color: $white;
        border-color: $primaryColor-active;
        background-color: $primaryColor-active;
    }
    &:focus {
        box-shadow: none;
    }
    &:not([disabled]) {
        &:not(.disabled) {
            &:active {
                border-color: $primaryColor-active;
                background-color: $primaryColor-active;
                box-shadow: none;
                color: $white;
            }
        }
    }

    &:disabled {
        border: 1px solid $grey6;
        background-color: $grey6;
        color: #27273b;
    }

    &:disabled:hover {
        border: 1px solid $grey6;
        background-color: $grey6;
        color: #27273b;
    }

    .app_icon {
        margin-right: 20px !important;
        margin-left: -15px;
        margin-top: 0px;
        -webkit-transform: translate(0px, 4px);
        transform: translate(0px, 4px);
    }
}

.appApplication_div {
    position: relative;
    right: 22px;
    top: -4px;
}

.padding10-35 {
    padding: 10px 35px;
}
@media screen and (max-width: 576px) {
    // .navbar-menuBtn {
    //     display: block;
    // }
    // .collapse.show {
    //     display: block!important;
    // }
    // .collapse {
    //     display: none!important;
    // }
    .appApplication_div {
        right: 150px;
    }
}

@media (max-width: 320px) {
    .analyticsLogo_div {
        left: -70px;
    }
    .appApplication_div {
        right: 150px;
    }
}
.domain-name {
    padding: 0 12px;
    color: #424242;
    font-weight: 600;
}
.paddingLR-0 {
    padding-left: 0px;
    padding-right: 0px;
}
.paddingLR-25 {
    padding-left: 25px;
    padding-right: 25px;
}
.paddingLR-35 {
    padding-left: 35px;
    padding-right: 35px;
}
.paddingLR-45 {
    padding-left: 45px;
    padding-right: 45px;
}
.modal-header {
    display: flex !important;
    align-items: center;
    // padding-left: 35px;
    padding-right: 20px;
    border-bottom-color: $white-shade2;
    background-color: $white-shade1;
    display: block;
    h5 {
        margin: 0px;
    }
}
.switchApp-btn {
    display: flex;
    align-items: center;
    width: 180px;
    border-radius: 4px;
    position: absolute;
    // right: 736px;
    top: 90px;
    font-size: 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    padding: 15px;
    font-weight: 500;

    line-height: 1;

    color: #000 !important;
    border-radius: 4px;
    cursor: hand;
    border: 1px solid #d3d3d3 !important;
    text-align: left;
    overflow: hidden;
    background: linear-gradient(180deg, #ffffff80, #f5f5f5) !important;
    text-transform: capitalize;
    height:40px;
    &:hover {
        color: $white;
        border-color: $primaryColor-active;
        background-color: $primaryColor-active;
    }
    &:focus {
        box-shadow: none;
    }
    &:not([disabled]) {
        &:not(.disabled) {
            &:active {
                border-color: $primaryColor-active;
                background-color: $primaryColor-active;
                box-shadow: none;
                color: $white;
            }
        }
    }

    &:disabled {
        border: 1px solid $grey6;
        background-color: $grey6;
        color: #27273b;
    }

    &:disabled:hover {
        border: 1px solid $grey6;
        background-color: $grey6;
        color: #27273b;
    }
}
.highlight {
    background-color: #f0f2ff !important;
}
.switchApp-btn-icon {
    position: absolute;
    right: 15px;
    top: 13px;
    // color: $table-body-data;
}
.searchBox {
    border-bottom: 1px solid lightgrey;
    display: inline-block;
}
.createBtn-div {
    display: inline-block;
    padding: 20px 35px 5px;
    border-top: 1px solid lightgrey;
}
.showApp-div {
    padding: 10px 0px 20px;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 30px;
    display: inline-flex;
    width: 100%;
    align-items: center;
    padding-top: 20px;
    span {
        &:hover {
            cursor: pointer;
        }
    }
    i {
        margin-right: 5px;
    }
}
.archived-status {
    position: absolute;
    right: 35px;
}
.appStatus-text {
    text-transform: capitalize;
    font-weight: 600;
    margin-left: 5px;
}
.trackingEnabled-green {
    color: #57af36;
}
.trackingEnabled-yellow {
    color: #de9f00;
}
.archived-grey {
    color: #9a9494;
}
.archived-black {
    color: #4e4e4e;
}
.appList-container {
    height: 350px;
    overflow-y: auto;
}
.appform-container {
    height: 300px;
    overflow-y: hidden;
}
.labelWidth {
    width: calc(100% - 20px);
}
.appList-div {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    margin-top: 8px;

    .noData-div {
        font-size: 13px;
        font-weight: 600;
        padding: 15px;
        background-color: $white;
        position: relative;
        top: 150px;
        padding-left: 320px;
    }
}
.tri-container {
    transform: rotate(45deg);
    display: block;
    top: -25px;
    position: absolute;
    left: 9px;
}
.arrow-left {
    position: absolute;
    width: 75.3px;
    height: 53.5px;
    left: 22px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: 3px;
}
.cover {
    position: absolute;
    height: 48px;
    width: 30px;
    overflow: hidden;
    top: 1.3px;
}
.bg-green {
    background-color: $success;
}
.bg-red {
    background-color: $deleted;
}
.bg-yellow {
    background-color: $yellowsandybrown;
}
.requiredErrorMsg {
    font-size: 12px;
    color: $brightred;
}
.form-group {
    input {
        font-size: 14px;
    }
    textarea {
        font-size: 14px;
    }
}
.font14 {
    font-size: 14px;
    font-size: 14px;
}
.requiredStar {
    color: red;
    font-size: 16px;
}
.demo {
    padding-top: 74px;
    // padding-bottom: 110px;
}
.width100 {
    width: 100%;
}
.displayInlineBlock {
    display: inline-block;
}
.flexNoWrap {
    flex-wrap: nowrap;
}
.pointer {
    cursor: pointer !important;
}
.dotText {
    font-weight: 600;
    font-size: 30px;
}
.appCard {
    margin: 10px 0px;
    padding: 0px 5px;
    height: 80px;
    display: inline-flex;
}
.appStatus-point {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    margin-top: 23px;
}
.search-field-wrapper {
    margin-top: 8px;
    margin-bottom: 8px;
}
.appContainer {
    padding: 10px 0px;
    line-height: 2;
    background-color: $white;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    border: 1px solid transparent;
    &:hover {
        // border: 1px solid #00a1e1;
        border-radius: 4px;
    }
}
.app-data {
    display: inline-flex;
    width: calc(100% - 50px);
}
.funnelContainer {
    &:hover {
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12);
        cursor: pointer;
    }
}
.appTitle {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
}
// .deleteIcon {
//     &:before {
//         color: $error-red;
//     }
// }
.appBody {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
}
.nomargin {
    margin: 0px;
}
.fontW600 {
    font-weight: 600;
}
.height30 {
    height: 30px;
    box-sizing: content-box;
}
.createApp-btn {
    background-color: #00a1e1;
    color: $white;
    width: 100%;
    text-transform: uppercase;
}
.validInfoBox {
    border: 1px solid $lightgray;
    padding: 20px;
    line-height: 2;
    margin-bottom: 20px;
}
.validApp-text {
    background-color: $grey-focus;
    padding: 0px 20px;
    border-radius: 6px;
    text-align: center;
    color: $white;
    font-size: 14px;
    font-weight: 600;
}
.app-card {
    margin: 0 auto;
    align-items: center;
}
.app-img-div {
    margin-right: 15px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    height: 42px;
    width: 42px;
    min-width: 42px;
    max-width: 42px;
    border-radius: 50%;

    .app-bgimg {
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 0px;
    }
}
.app-img {
    margin: 0 auto;
    border-radius: 50%;
    height: 42px;
    width: 42px;
}
.app-content-div {
    width: calc(100% - 50px);
}
.app-actions-div {
    align-items: center;
    position: absolute;
    right: 16px;
}
.addTags-btn {
    border-radius: 50%;
    border: none;
    color: $white;
    height: 30px;
    width: 30px;
    line-height: 0;
    font-weight: 600;
}
.textWrap {
    overflow: hidden;
    text-overflow: ellipsis;
    $white-space: nowrap;
}
.optnsContainer {
    position: relative;
    cursor: pointer;
    margin-left: 20px;
    i {
        float: right;
    }
    &:hover {
        .optnsDiv {
            display: block;
        }
    }
}
.optnsDiv {
    background-color: $white;
    padding: 5px 15px;
    box-sizing: content-box;
    border-radius: 4px;
    width: 95px;
    position: absolute;
    top: 11px;
    z-index: 2;
    left: -95px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    display: none;
    p {
        &:hover {
            cursor: pointer;
        }
        i {
            margin-top: 6px;
        }
    }
}
.edit {
    &:hover {
        font-weight: 600;
    }
}
.delete {
    &:hover {
        font-weight: 600;
    }
}
.errorContainer {
    height: 20px;
    margin-top: 5px;
}

.verifiedStatusIcon {
    width: 30px;
    height: 30px;
    align-items: center;
    display: flex;
    border-radius: 50%;
    &:before {
        margin: 0 auto;
    }
}
.formButtons {
    display: flow-root;
}
.openForm {
    border: none;
    background-color: $salmon;
    font-size: 13px;
    cursor: pointer;
    border-radius: 18px;
    padding: 10px 20px;
    color: $white;
    outline: none !important;
    margin: 0 15px;
}
.modal {
    background-color: rgba(0, 0, 0, 0.5);
}
.fade {
    opacity: 1;
}
.modal.left {
    .modal-dialog {
        position: fixed;
        margin: auto;
        width: 100%;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }
    .modal-content {
        height: 100%;
        overflow-y: auto;
    }
    .modal-body {
        padding: 15px 15px 80px;
    }
}
.modal.right {
    .modal-dialog {
        position: fixed;
        margin: auto;
        width: 100%;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }
    .modal-content {
        height: 100%;
        overflow-y: auto;
    }
    .modal-body {
        padding: 15px 15px 80px;
    }
}
.modal.left.fade {
    .modal-dialog {
        left: 0px;
        -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
        -o-transition: opacity 0.3s linear, left 0.3s ease-out;
        transition: opacity 0.3s linear, left 0.3s ease-out;
    }
}
.modal.left.fade.in {
    .modal-dialog {
        left: 0;
    }
}
.modal.right.fade {
    .modal-dialog {
        right: -320px;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
    }
}
.modal.right.fade.in {
    .modal-dialog {
        right: 0;
    }
}
.modal-content {
    border: none;
}
.modal-title {
    font-weight: 20px;
    font-weight: 600;
}
div {
    > .fa-exclamation {
        background-color: $brightred;
    }
    > .fa-plus {
        background-color: $lightgreen;
    }
}
.switch_button {
    display: block;
}

.appSwitcher-div {
    .switch {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 90px;
        height: 22px;
        padding: 3px;
        margin: 10px 0px 5px 0;
        background: linear-gradient(to bottom, $white-shade2, $white 25px);
        background-image: -webkit-linear-gradient(top, $white-shade2, $white 25px);
        border-radius: 18px;
        box-shadow: inset 0 -1px $white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        box-sizing: content-box;
    }
}

label {
    font-weight: inherit;
    position: relative;
    display: inline-block;
    margin: 10px;
}
input[type="checkbox"] {
    margin: 4px 0 0;
    line-height: normal;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    visibility: hidden;
}
input[type="radio"] {
    margin: 4px 0 0;
    line-height: normal;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}
.switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    box-sizing: content-box;
    &:checked ~ .switch-label {
        background: $yellowsandybrown;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
        &:before {
            opacity: 0;
        }
        &:after {
            opacity: 1;
        }
    }
    &:checked ~ .switch-handle {
        left: 74px;
        box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
    }
}
.switch-left-right {
    .switch-input {
        &:checked ~ .switch-label {
            background: inherit;
            &:before {
                opacity: 1;
                left: 100px;
            }
            &:after {
                left: 0 !important;
                opacity: 1;
                padding-left: 20px;
            }
        }
    }
    .switch-label {
        overflow: hidden;
        &:before {
            background: $lightgray;
            text-align: left;
            padding-left: 48px !important;
            width: 50px;
            height: 10px;
            top: 4px;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 11px 0 0 0;
            text-indent: -12px;
            border-radius: 20px;
            box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2), inset 0 0 3px rgba(0, 0, 0, 0.1);
            width: 50px;
            height: 10px;
            top: 4px;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 11px 0 0 0;
            text-indent: -12px;
            border-radius: 20px;
            box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2), inset 0 0 3px rgba(0, 0, 0, 0.1);
        }
        &:after {
            width: 50px;
            height: 10px;
            top: 4px;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 11px 0 0 0;
            text-indent: -12px;
            border-radius: 20px;
            box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2), inset 0 0 3px rgba(0, 0, 0, 0.1);
            text-align: left;
            text-indent: 9px;
            background: $lightgreen !important;
            left: -100px !important;
            opacity: 1;
            width: 100% !important;
            width: 50px;
            height: 10px;
            top: 4px;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 11px 0 0 0;
            text-indent: -12px;
            border-radius: 20px;
            box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2), inset 0 0 3px rgba(0, 0, 0, 0.1);
        }
    }
}
.switch-label {
    transition: All 0.3s ease;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
    position: relative;
    display: block;
    height: inherit;
    font-size: 10px;
    text-transform: uppercase;
    background: $lightgray;
    border-radius: inherit;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
    box-sizing: content-box;
    transition: All 0.3s ease;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
    &:before {
        content: attr(data-off);
        right: 11px;
        color: $grey-hover;
        text-shadow: 0 1px rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 50%;
        margin-top: -5px;
        line-height: 0;
        -webkit-transition: inherit;
        -moz-transition: inherit;
        -o-transition: inherit;
        transition: inherit;
        box-sizing: content-box;
    }
    &:after {
        position: absolute;
        top: 50%;
        margin-top: -5px;
        line-height: 0;
        -webkit-transition: inherit;
        -moz-transition: inherit;
        -o-transition: inherit;
        transition: inherit;
        box-sizing: content-box;
    }
}
.switch-handle {
    transition: All 0.3s ease;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
    transition: All 0.3s ease;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 18px;
    height: 18px;
    background: linear-gradient(to bottom, $white 40%, #f0f0f0);
    background-image: -webkit-linear-gradient(top, $white 40%, #f0f0f0);
    border-radius: 100%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -6px 0 0 -6px;
        width: 12px;
        height: 12px;
        background: linear-gradient(to bottom, $white-shade2, $white);
        background-image: -webkit-linear-gradient(top, $white-shade2, $white);
        border-radius: 6px;
        box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
    }
}
span.switch-label {
    &:after {
        content: attr(data-on);
        left: 11px;
        color: $white;
        text-shadow: 0 1px rgba(0, 0, 0, 0.2);
        position: absolute;
    }
}

.appSwitcher-div .modal-dialog {
    max-width: 800px !important;
}
.app-icon {
    margin-right: 20px !important;
    margin-left: -15px;
    margin-top: -10px;
    -webkit-transform: translate(0px, 4px);
    transform: translate(0px, 4px);
}
.placeholderText {
    padding: 10px;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: -3px;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0.5;
}

textarea {
    &:focus-visible,
    &:focus {
        + {
            .placeholderText {
                opacity: 1;
                transform: scale(0.75) translateY(-100%) translateX(-30px);
                background-color: $white;
                left: 20px;
                position: absolute;
                padding: 0px;
                color: $primaryColor-enable;
                top: 0px;
                opacity: 1;
                transform: scale(0.75) translateY(-100%) translateX(-30px);
                background-color: $white;
                left: 20px;
                position: absolute;
                padding: 0px;
                color: $primaryColor-enable;
                top: 0px;
            }
        }
        border: 2px solid #005FCC;
        outline: none;
    }
    &:not(:placeholder-shown) {
        + {
            .placeholderText {
                opacity: 1;
                transform: scale(0.75) translateY(-100%) translateX(-30px);
                background-color: $white;
                left: 20px;
                position: absolute;
                padding: 0px;
                color: $primaryColor-enable;
                top: 0px;
                opacity: 1;
                transform: scale(0.75) translateY(-100%) translateX(-30px);
                background-color: $white;
                left: 20px;
                position: absolute;
                padding: 0px;
                color: $primaryColor-enable;
                top: 0px;
            }
        }
    }
    box-shadow: none !important;
    // border: #eaeaea 2px solid;
}
select {
    &:focus {
        + {
            .placeholderText {
                opacity: 1;
                transform: scale(0.75) translateY(-100%) translateX(-30px);
                background-color: $white;
                left: 20px;
                position: absolute;
                padding: 0px;
                color: $primaryColor-enable;
                top: 0px;
                opacity: 1;
                transform: scale(0.75) translateY(-100%) translateX(-30px);
                background-color: $white;
                left: 20px;
                position: absolute;
                padding: 0px;
                color: $primaryColor-enable;
                top: 0px;
            }
        }
        border: $primaryColor-enable 1px solid !important;;
    }
    &:not(:placeholder-shown) {
        + {
            .placeholderText {
                opacity: 1;
                transform: scale(0.75) translateY(-100%) translateX(-30px);
                background-color: $white;
                left: 20px;
                position: absolute;
                padding: 0px;
                color: $primaryColor-enable;
                top: 0px;
                opacity: 1;
                transform: scale(0.75) translateY(-100%) translateX(-30px);
                background-color: $white;
                left: 20px;
                position: absolute;
                padding: 0px;
                color: $primaryColor-enable;
                top: 0px;
            }
        }
    }
    box-shadow: none !important;

    // border: #eaeaea 2px solid;
}

@media (max-width: 575.99px) {
    .switchApp-btn {
        left: 50px !important;
        width: 100px !important;
    }
}
@media (max-width: 1072px) and (min-width: 990px) {
    .switchApp-btn {
        position: absolute;
        left: -180px;
    }
}
@media (max-width: 991) and (min-width: 768px) {
    .switchApp-btn {
        position: absolute;
        left: 100px;
    }
}

.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    // position: absolute;
    // opacity: 0;
    // cursor: pointer;
    // height: 0;
    // width: 0;
}

/* Create a custom checkbox */

.appSwitcher .check-container {
    position: relative;
    display: block;
    // overflow: hidden;
    $white-space: nowrap;
    text-overflow: ellipsis;
    width: 139px;
    padding-left: 20px;
    /* left: 20px; */
    position: relative;

    input {
        display: none;
    }

    .step-span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        $white-space: nowrap;
        padding-left: 20px;
    }
}

.padding-chk-0 {
    padding: 0 !important;
}
.appSwitcher {
    .checkmark {
        position: absolute;
        top: 2px;
        left: 5px;
        height: 18px;
        width: 18px;
        // background-color: #eee;
        border-radius: 2px;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .check-container {
        display: inline-block;
    }

    /* Show the checkmark when checked */
    .check-container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .check-container .checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        visibility: visible;
    }

    .check-container input:disabled ~ .checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid transparent;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        visibility: visible;
    }
}

.ellipsis {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.appSwitcher-label {
    position: absolute;
    top: 69px;
    font-size: 14px;
    font-weight: 700;
    color: #707070;
    width: 130px;

    // right: 841px;
}