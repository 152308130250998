@import "style_variables";

.conversation-modal {
    .custom-modal-body {
        padding: 10px;
    }
    
    .userDetailModalContainer {
        margin-bottom: 20px;
        line-height: 23px;
    }
    
    .modal-content {
        height: auto;
        max-height: 600px !important;
    }
    
    .card {
        width: 100%;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }
    
    .card-title {
        background-color: #cfd0dd;
        text-align: center;
        color: black;
        padding: 10px;
        cursor: pointer;
        margin-bottom: 0px !important;
    }
    
    .card-content {
        padding: 20px;
        transition: 1s ease-in-out;
        height: auto;
        max-height: 280px !important;
        overflow-y: auto;
    }
    
    .inner-card {
        width: 100%;
        height: auto;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        font-size: 13px;
    }
    
    .inner-card-title {
        background-color: rgba(207, 208, 221, 0.4) !important;
        color: black;
        font-weight: 600;
        padding: 10px;
        height: auto;
    }
    
    .inner-card-body {
        padding: 10px;
        height: auto;
    }
    
    .noDataFound {
        font-weight: 600;
        text-align: center;
        font-size: 12px;
    }
    
    .inputSection {
        margin-bottom: 20px;
        margin-top: 10px;
    }
    
    .loaderArea {
        max-width: 30px;
        max-height: 30px;
        font-weight: 600;
    }
    
    .remarkInputContainer {
        width: 95% !important;
    }
    
    .remarkInput {
        width: 95% !important;
        border: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        outline: none !important;
    }
    
    .createCat-button {
        float: right !important;
        right: 0px;
        color: white;
        font-size: 12px;
        border-radius: 4px;
        padding: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        border-radius: 50%;
        border: none;
        height: 30px;
        background-color: $primaryColor-enable;
    }
    
    .remarkInput:hover {
        border: none;
        border-bottom: 2px solid black !important;
        outline: none !important;
    }
    
    .remarkInput:focus {
        border: none;
        border-bottom: 2px solid black !important;
        outline: none !important;
    }
    
    .remarkData {
        display: flex;
        flex-direction: column;
    }
    
    .mr-10 {
        margin-right: 10px;
    }
    
    .mt-2 {
        margin-top: 2px;
    }
    
    .mt-10 {
        margin-top: 10px;
    }
    
    .inner-card-title {
        background-color: rgb(224, 220, 220);
    }
}

