$mobile-width-variable: 10px;
@import "style_variables";

.headerLogoDiv {
    position: relative;
    top: 17px;
    // width: 170px;
    position: relative;
    padding-left: 0px;
    // left: 15px;
    .logoSize {
        // width: 170px;
        height: 40px;
    }
}

.logoDivider {
    background-color: $section-tooltip-bg;
    width: 1px;
    height: 58px;
    position: absolute;
    left: 135px;
    top: 1px;
}

.dropdownMenuUser {
    width: auto;
    max-width: 200px;
    height: auto;
    min-width: 300px;
    margin-right: 5px !important;
    margin-left: 0px !important;
    padding: 0px;
    border: none;
    box-shadow:
        0 1px 5px 0 rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.dropdown_options {
    padding: 10px;
}

.profileImage {
    padding: 0px 20px;
    position: relative;
    top: calc(50% - 17px);
}

.profile_img {
    width: 35px;
    height: 35px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}

.widthProfileImage {
    width: calc(100% - 0px);
    border-radius: 50%;
    margin-top: -2px;
}

.initialsDiv {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: black;
    text-transform: uppercase;
}

//   .initialsDiv {
//     width: $mobile-width-variable;
//   }

.userRole_info {
    background-color: $lightgreen;
    padding: 8px 4px;
    text-align: center;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.userInfo {
    background-color: $table-body-data;
    border-radius: 4px 4px 0px 0px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    display: flex;
    height: auto;
    position: relative;
}

.orgLogo_Div {
    max-width: 300px;
    height: 45px;
    left: 15px;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: -18px;
    padding-right: 15px;

    .orgLogo_bg {
        background-size: contain !important;
        /* width: 200px; */
        height: 40px;
        width: 40px;
        min-width: 40px;

        background-repeat: no-repeat;
        // min-width: 100px;
        // max-width: 200px;

        background-color: $white;
        background-position: center;
        // border-radius: 6px 0px 0px 6px;
        border: 1px solid $grey6;
    }

    .orgName_span {
        margin-left: 5px;
        font-size: 18px;
        color: $grey1;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 50px;
        cursor: default;
    }
}

.switchOrg_btn {
    padding: 4px 7px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: transparent;
    background-size: cover;
    font-family: "Lato", sans-serif;
    font-size: 15px;
    color: $grey-active;
    -webkit-text-decoration: none solid $grey-active;
    text-decoration: none solid $grey-active;
    text-align: center;
    // position: absolute;
    top: -2px;
    /* right: -29px; */
    // margin-left: 10px;
    cursor: pointer;
    img {
        height: 30px;
    }
}

.user_app_data {
    display: flex;
    position: absolute;
    right: 19px;
}

.appApplication_div {
    position: relative;
    right: 14px;
    top: -4px;
}

.selected_app_div {
    // position: absolute;
    // right: 140px;
    border: 1px solid $primaryColor-active;
    height: 35px;
    border-radius: 0px 4px 4px 0px;
    width: 198px;
    position: relative;
    top: -2px;
    left: 9px;
    border-left: 1px solid transparent !important;
}

.analyticsLogo_div {
    display: -webkit-box;
    display: flex;
    text-align: center;
    border-radius: 4px;
    padding: 1px 10px 2px 10px;
    border: 1px solid transparent;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    line-height: 1;
    &:hover {
        // border: 1px solid #c5c5ce;
    }
    &:hover .spiltTriangle {
        visibility: visible;
    }

    .appLogo {
        width: 26px;
        height: 26px;
        margin-top: 2;
    }
}

.userData_div {
    display: flex;
    text-align: center;
    .spiltTriangle {
        width: 10px;
        height: 10px;
        background-color: transparent;
        border-left: 10px solid #c5c5cd;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        position: absolute;
        right: -10px;
        transform: rotate(45deg);
        top: 30px;
        visibility: hidden;
    }
    .appDetails {
        text-align: left;
        margin-right: 10px;
        display: inline-block;

        span {
            display: flex;
            // direction: rtl;
        }
        .analytics_text {
            color: $grey1;
            font-size: 14px;
            line-height: 1;
            height: 16px;
            // margin-top: -4px;
            margin-left: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 142px;
            display: inline-block;
        }
    }
    .myIntel_logo {
        height: 18px;
        width: 18px;
        border-radius: 4px;
        // border: 1px solid #c5c5ce;
        // border-radius: 20px;
    }
    .myIntel_logo1 {
        height: 26px;
        width: 26px;
        border-radius: 4px;
    }
}

.user_area {
    color: $fontgrey !important;
    top: -6px;
    // border-left: 1px solid $table-lower-border;
    height: 74px;
    padding-left: 0px;
    font-size: 14px;
    right: -6px;

    .user_menu {
        right: -112px;
        top: 15px !important;
        margin: 0;
        position: absolute;
        z-index: 1000;
        width: 198px;
        margin-right: 5px !important;
        margin-left: 0px !important;
        padding: 0px;
        border: none;
        padding: 8px;
        gap: 4px;
        border-radius: 4px;
        // opacity: 0px;
        background: #ffffff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
        // height: 350px;

        .switch_org_btn {
            border: none;
            color: $black;
            font-size: 14px;
            text-align: left;
            /* // padding: 10px 15px; */
            width: 100%;
            &:hover {
                /* // background-color: #a5e3fd; */
                // color: rgba(0, 0, 0, 0.5);
                // box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
            }
            .user_info {
                padding: 20px 20px;
                background-color: $greySideNavColor;
                color: $white;
                .userName {
                    font-weight: 600;
                    color: $white;
                }
                .userEmailId_text {
                    font-size: 12px;
                    margin-top: -8px;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: $white;
                }
                .userDetails_text {
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: $white;
                    .orgImage {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        border: 2px solid $grey6;
                    }
                }

                .org_logo_div {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 10px;
                    border: 1px solid $lightgray;
                    display: flex;
                    // margin-top: -12px;
                    .orgImage {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        margin: auto;
                    }
                }

                .org_name_p {
                    background-color: $loader-bg;
                    padding: 10px 18px;
                    /* border-radius: 4px; */
                    margin-left: -20px;
                    margin-right: -20px;
                    margin-bottom: -20px;
                    margin-top: 10px;
                    color: $fontgrey;

                    .orgText {
                        color: #9e9e9e;
                        margin-right: 5px;
                    }

                    .orgName {
                        font-weight: 700;
                    }
                }
            }
            .user_settings {
                padding: 10px 0px;
                // box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);
                .resetPassword_text {
                    padding-top: 5px;
                    /* // border-top: 1px solid #b4bcbf; */
                    // padding-bottom: 5px;
                    /* // border-top: 1px solid #b4bcbf; */
                    /* font-size: 12px; */
                    color: $grey6 !important;
                    font-size: 14px!important;
                    font-weight: 400;
                    padding-left: 20px;
                    padding-right: 20px;
                    height: 40px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    
                    .logout_svg {
                        width: 20px;
                        height: 20px;
                        margin-left: -10px;
                        margin-right: 10px;
                    }
                    .change_password {
                        width: 22px;
                        height: 22px;
                        margin-left: -10px;
                        margin-right: 8px;
                    }
                    .help_url {
                        width: 18px;
                        height: 18px;
                        margin-left: -10px;
                        margin-right: 13px;
                    }
                }
                .resetPassword_text i,
                .logout-text i,
                .adminLink img {
                    margin-right: 8px;
                }
                .logout_text {
                    color: $grey6 !important;
                    font-size: 14px!important;
                    font-weight: 400;
                    padding-top: 0px;
                    padding-bottom: 5px;
                    margin-bottom: 0px;
                    padding-left: 20px;
                    padding-right: 20px;
                    height: 40px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                }
                .adminLink {
                    color: $grey6 !important;
                    font-size: 14px!important;
                    font-weight: 400;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    margin-bottom: 0px;
                    text-decoration: none;
                    padding-left: 20px;
                    padding-right: 20px;
                    height: 40px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    img {
                        width: 18px;
                        margin-left: -5px;
                    }
                    a {
                        text-decoration: none;
                        color: $grey6 !important;
                    }
                }
                .resetPassword_text:hover,
                .logout-text:hover,
                .adminLink:hover {
                    background-color: $loader-bg;
                    cursor: pointer;
                }
            }
        }
        .user_avatar {
            float: none;
            height: 35px;
            width: 35px;
            border-radius: 50%;
        }
    }
    a {
        display: flex;
        align-items: center;

        &:hover {
            text-decoration: none;
        }
    }

    .initialsDiv {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        color: $table-header-font;
        text-transform: uppercase;
    }
    .user_icon_name {
        line-height: 38px;
        /* float: right; */
        /* padding: 10px; */
        margin-left: 10px;
        font-size: 13px;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        margin-right: 10px;
    }
    .userArrow {
        // font-size: 20px;
        // position: relative;
        // /* top: 9px; */
        // /* left: 3px; */
        // color: $fontgrey !important;
        // position: absolute;
        // right: 15px;
        font-size: 20px;
        position: relative;
        top: -3px;
        left: 3px;
        color: #000000 !important;
    }
    .user_avatar_name {
        font-size: 14px;
        color: $table-header-font !important;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;

        span {
            width: 60px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.switchApp_btn {
    display: flex;
    align-items: center;
    width: 120px;
    border-radius: 4px 0px 0px 4px;

    position: relative;
    left: 10px;
    top: -2px;
    /* width: 40px; */
    height: 35px;
    font-size: 14px;
    color: $primaryColor-active;
    border: 1px solid $primaryColor-active;
    background-color: #ebf7f5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 15px;
    font-weight: 500;
    border-right: 1px solid transparent;
    &:hover {
        color: $white;
        border-color: $primaryColor-active;
        background-color: $primaryColor-active;
    }
    &:focus {
        box-shadow: none;
    }
    &:not([disabled]) {
        &:not(.disabled) {
            &:active {
                border-color: $primaryColor-active;
                background-color: $primaryColor-active;
                box-shadow: none;
                color: $white;
            }
        }
    }

    &:disabled {
        border: 1px solid $grey-enable;
        background-color: $grey6;
        color: $grey1;
    }

    &:disabled:hover {
        border: 1px solid $grey-enable;
        background-color: $grey6;
        color: $grey1;
    }

    .app_icon {
        margin-right: 20px !important;
        margin-left: -15px;
        margin-top: 0px;
        -webkit-transform: translate(0px, 4px);
        transform: translate(0px, 4px);
    }
}

.dropdown-menu {
    right: 140px;
    left: auto;
    border-radius: 6px;
}
@media (max-width: 620px) {
    .analyticsLogo_div {
        margin-top: 0px;
        left: -50;
    }
}

@media screen and (max-width: 576px) {
    // .navbar-menuBtn {
    //     display: block;
    // }
    // .collapse.show {
    //     display: block!important;
    // }
    // .collapse {
    //     display: none!important;
    // }
    .appApplication_div {
        right: 150px;
    }
}

@media (max-width: 320px) {
    .analyticsLogo_div {
        left: -70px;
    }
    .appApplication_div {
        right: 150px;
    }
}

.font56 {
    font-size: $mobile-width-variable;
}

.logout_div {
    color: $fontgrey !important;

    a:active {
        color: $fontgrey !important;
        text-decoration: none;
        background-color: transparent;
    }
}

.wordWrap {
    word-wrap: break-word;
}

.optns {
    padding: 10px;
}

.verticalAlign {
    border: 1px solid $white;
    border-radius: 50%;
    padding: 2px;
    height: 40px;
    width: 40px;
}

.initialAppChar {
    background-color: rgb(161, 200, 122);
    height: 26px;
    width: 26px;
    box-sizing: border-box;
    border: 1px solid grey;
    text-align: center;
    /* margin: auto; */
    /* display: flex; */
    padding-top: 5px;
    box-sizing: border-box;
    color: black;
}

.orgLoaderDiv {
    width: 200px;
    height: 60px;
    position: absolute;
    left: 250px;
    top: 0px;
    display: flex;
    .loaderImg {
        margin: auto;
        height: 60px;
        width: 60px;
    }
}
