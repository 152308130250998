@import 'style_variables';

.Collapsible{
    border: 1px solid $lightgray;
    border-radius: 4px;
    margin-bottom: 10px;

}
.question {
    height: 25px;
    padding: 12px 20px;
    font-size: 16px;
    margin-bottom: 0px;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    width: 100%;

    .questionIndex{
        width: 4%;
        color: $grey;
        display: inline-block;
    }

    .questionText{
        width: 65%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $table-font-link;
        font-weight: 600;
    }

    .deletedText {
        width: 10%;
        display: inline-block;
    }

    .questionType{
        width: 20%;
        display: inline-block;
        color: $grey;
        font-size: 14px;
    }

    .responses{
        display: inline-block;
        color: $grey;
        font-size: 14px;
    }
}


.accordion {
    border: 1px solid transparent;
    border-radius: 2px;
    margin-bottom: 10px;
}

.accordion__item + .accordion__item, .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    border-radius: 4px;   
}

.accordion__item {
display: grid!important;
}

.accordion__button {
   display: flex;
   align-items: center;
}

.accordion__button:hover {
    background-color: #ddd;
}

// .accordion__button:before {
//     display: inline-block;
//     content: '';
//     height: 10px;
//     width: 10px;
//     margin-right: 12px;
//     border-bottom: 2px solid currentColor;
//     border-right: 2px solid currentColor;
//     transform: rotate(-45deg);
// }

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}

[hidden] {
    display: none;
}

.accordion__heading {
    border-bottom: 1px solid $lightgray;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
}


.accordion__button:after {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
}

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
    transform: rotate(-135deg);
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}