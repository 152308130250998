@import "style_variables.scss";

.dashbordCharts {
    .dashboardFilters-margin {
        .marginLeft-10 {
            margin-left: 10px !important;
        }
        z-index: 2;
        display: block !important;
        height: 60px;

        .segmentsFilterDiv, 
        .segmentFilterTitle, 
        .guideStatusTitle, 
        .userEngement_eventSourceFilterTitle, 
        .eventSourceFilterTitle,
        .tooltipFilterTitle {
            position: static !important;
            margin-bottom: 0px;
        }

        .tooltipModal-container  {
            margin-left: -10px;
            position: relative;
            .tooltipFilter {
                position: static !important;
            }

            .source-but {
                right: -20px;
                top: 20px;
            }
        }
    }

    .appName {
        background-color: $loader-bg;
        padding: 8px 15px;
        border-radius: 14px;
        width: fit-content;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: 1px solid $white-shade2;
    }
    .guide-count {
        margin: 0 auto;
        text-align: center;
    }

    .borderBottom_0 {
        border-bottom: 5px solid #60a550;
    }

    .borderBottom_1 {
        border-bottom: 5px solid $live;
    }

    .borderBottom_2 {
        border-bottom: 5px solid $mediumorchid;
    }
    .stat-digit {
        text-align: center;
        display: block;
    }

    .tileBoxes {
        margin-bottom: 1.5rem;
    }
    .searchTiles-margin {
        margin-left: -30px;
        margin-right: -30px;
    }
    .tiles-border {
        border: 1px solid $lightgray;
        /* background-color: #a2a0a0; */
        background-color: $white;
        border-radius: 10px;
        /* box-shadow: 0 0 10px #bfbfbf;*/
    }

    .borderBottom_0 {
        border-bottom: 5px solid #60a550;
    }

    .borderBottom_1 {
        border-bottom: 5px solid $live;
    }

    .borderBottom_2 {
        border-bottom: 5px solid $mediumorchid;
    }

    .statColor_0 {
        color: #60a550;
    }

    .statColor_1 {
        color: $live;
    }

    .statColor_2 {
        color: $mediumorchid;
    }

    .deleteChart {
        font-weight: 600;
        padding: 30px 20px 50px;
    }
}

.serviceAvailable {
    display: flex;
    align-items: center;
    justify-content: center;
}

.leftBox {
    width: calc(50% + 15px);
    margin-left: -15px;

    // margin-top: 30px;
}
.rightBox {
    width: calc(50% + 14px);
    margin-right: -15px;
    // margin-top: 30px;
}
.dataAvailable {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flexTiles {
    flex-direction: column;
    // justify-content: space-evenly;
}

.search_section {
    .rangeBox {
        .keyword-range {
            display: inline-block;
            width: 55px;
            height: 16px;
        }

        .fontRange {
            font-size: 9pt;
            font-family: Consolas, courier;
            fill: #aaa;
        }
    }

    .search-tooltip{
        height: 100px;
    }

    .stat-icon .info-icon-color{
        position: absolute;
        right: 10px;
        top: 10px;

    }
}

.customDashboard-charts-container {
    padding: 25px 32px;
    display: flex;
    justify-content: center;
}
