@import "style_variables";

.response-card {
    margin: 0px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12);
    background-color: #f5f5f5;
    border-radius: 6px;
    margin-bottom: 15px;
    padding: 0 0 10px 15px;
    text-align: left;
}

.userDetailContainer {
    height: 40px;
    padding-top: 8px;
}

.responseCount {
    padding: 2px 10px;
    color: white;
    font-weight: 600;
    background-color: $primaryColor-active;
    border-radius: 10px;
    font-size: 12px !important;
    width: fit-content;
    float: right;
    margin-right: 15px;
}

.responseBtn {
    float: right;
    margin-right: 45px;
    margin-top: 5px;
}

.responseBtn:hover {
    cursor: pointer;
}


///////////////////////////////////////////////////////////

.colFlex {
    display: flex;
    flex-direction: column;
}

.mr10 {
    margin-right: 10px;
}