
    .btns-div{
        height: 200px;
        margin-top: 20px;
    }

    .downloadCsv span {
        // color: #26273b !important;
        border: 1px solid #acadc1;
        background-color: #ffffff;
        padding: 6px;
        cursor: pointer;
        margin: 63.25px 64.5px;
        width: 120px;
    }

    .text {
        position: absolute;
        left: 75%;
        top: 25px;
        transform: translateX(-50%);
        width: 175px;
    }
