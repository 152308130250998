@import "style_variables";

.tooltipFilterTitle {
	font-size: 14px;
	top: 0;
	// position: absolute;
	right: 308px;
	z-index: 1;
	color:#707070;
	font-weight: 700;
}

.source-but:hover {
	background-color: $filter-font;
	color: #fff;
}

.source-but:hover[disabled] {
	background-color: #ecedf3;
	color: $filter-font;
}

.source-but:active {
	background-color: $filter-font;
	color: #fff;
}

.source-but span {
	display: inline-block;
	margin-left: 8px;
	text-align: left;
}

.source-but {
	height: 40px;
	padding: 0.375rem 0.75rem;
	min-width: 140px;
	// margin-right: 20px;
	font-size: 14px;
	color: #454560;
	border: 1px solid #ced4da;
	background-color: white;
	border-radius: 4px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	z-index: 9;
	width: 150px;
	right: 267px;
	align-items: center;
	white-space: break-spaces;
	line-height: 1;
	// position: absolute !important;
	top: 21px;
}

.player-span {
	position: fixed;
	top: 80px;
	float: right;
	right: 442px;
	background-color: #fff;
	border: 1px solid #bfc0c1;
	color: #495057;
	border-radius: 4px;
	width: 140px;
	padding: 0 15px;
	height: 40px;
	line-height: 2.5;
	font-size: 14px;
	z-index: 9;
	display: flex;
	align-items: center;
	opacity: 0.4;
}

.tooltipList {
	padding: 10px 20px;
	height: 50vh;
	overflow-y: auto;

	.tooltipFilter {
		position: fixed !important;
		padding: 8px 12px !important;
	}

	.guideListCard {
		border: 1px solid #d3e5ff;
		/* line-height: 1; */
		padding: 5px;
		border-radius: 4px;
		margin-bottom: 15px;
		display: flex;
		.userDetails {
			// margin-left: 15px;
			.userName {
				width: 500px !important;
				color: #444444;
				font-weight: 600;

				.tooltipTitle-span {
					display: inline-block;
					white-space: nowrap;
					width: fit-content;
					max-width: 500px;
					text-overflow: ellipsis;
					overflow: hidden;
					margin-top: 8px;
				}
			}
		}

		.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
			background-color: $primaryColor-enable !important;
			border: 1px solid $primaryColor-enable;
		}

		/* Create a custom checkbox */

		.checkmark {
			height: 16px;
			width: 16px;
			background-color: #eee;
			border-radius: 15px;
			position: relative;
			right: 5px;
			top: 0px;
		}

		/* When the checkbox is checked, add a blue background */

		.checkedCheckmark {
			// background-color: #3ed0b5;
		}

		/* Style the checkmark/indicator */
	}

	.check-container .checkmark:after {
		left: 6px;
		top: 1px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
		visibility: visible;
	}

	.stepDeleted {
		color: $error-red;
		font-size: 12px;
		display: block;
		margin-top: 2px;
	}

	.toolTipTitleOverflow {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.deletedToolTipLabel {
		background-color: rgba($color: $lightpink, $alpha: 0.3) !important;
		border: 1px solid $brightred;
	}

	._85Width {
		width: 85%;
	}

	._15Width {
		width: 16% !important;
	}

	._100Width {
		width: 100% !important;
	}

	.toolTipTitleOverflow:hover .tooltiptextTop {
		visibility: visible;
	}

	.toolTipTitleOverflow .tooltiptextTop {
		visibility: hidden;
		position: absolute;
		max-width: 350px;
		color: $black !important;
		background-color: $lightgray !important;
		border: 1px solid $section-tooltip-border !important;
		padding: 10px 20px !important;
		text-align: center;
		border-radius: 4px;
		max-width: 350px !important;
		white-space: normal !important;
		word-break: normal !important;
		word-wrap: break-word;
		word-spacing: normal;
		z-index: 1;
		bottom: 120%;
		left: 0%;
		margin-left: 20px;
	}

	.toolTipTitleOverflow .tooltiptextTop::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: black transparent transparent transparent;
	}

	.toolTipTitleOverflow:hover .tooltiptextBottom {
		visibility: visible;
	}

	.toolTipTitleOverflow .tooltiptextBottom {
		visibility: hidden;
		position: absolute;
		max-width: 350px;
		color: $black !important;
		background-color: $lightgray !important;
		border: 1px solid $section-tooltip-border !important;
		padding: 10px 20px !important;
		text-align: center;
		border-radius: 4px;
		max-width: 350px !important;
		white-space: normal !important;
		word-break: normal !important;
		word-wrap: break-word;
		word-spacing: normal;
		z-index: 1;
		top: 120%;
		left: 0%;
		margin-left: 20px;
	}

	.toolTipTitleOverflow .tooltiptextBottom::after {
		content: "";
		position: absolute;
		bottom: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: transparent transparent black transparent;
	}
}
