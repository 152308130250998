@import "style_variables";

#blockChart > div:not(:last-child) {
    display: none;
}

.minHeight492 {
    min-height: 492px;
}

.paginationPosition {
    position: absolute;
    bottom: -100px;
    right: 20px;
    left: 20px;
}

.exportPdf-btn {
    // margin-right: 20px;
    // margin-top: 0px;
    // position: absolute;
    // right: -260px;
    cursor: pointer;
}

.blockImportant {
    display: block !important;
}

.createPage-btn-square {
    // position: absolute;
    // right: 142px;

    span {
        display: flex;
        align-items: center;
    }
}

.color_table thead th {
    /*background-color: $white;*/
    /*border-bottom-color: $white;*/
    // background-color: $tableHeader-purple;
    // color: $white;
}

.mainSection {
    .section-heading-div {
        position: relative;
        align-items: center;
        // margin-top: -10px;
        margin-bottom: 6px;

        .createBtnDiv {
            position: relative;
            height: 35px;
        }
    }
    .sectionSub-heading {
        padding: 0px;
        .sectionTitle {
            height: 60px;
            .section-col-div {
                padding-left: 0px;
                text-align: left;
            }
        }
    }
    .statCards {
        .borderBottom-1 {
            border-bottom: 4px solid #0b8be6;
            border-radius: 9px;
        }
        .borderBottom-2 {
            border-bottom: 4px solid #d4737c;
            border-radius: 9px;
        }
        .borderBottom-3 {
            border-bottom: 4px solid #6ebd46;
            border-radius: 9px;
        }
        .borderBottom-4 {
            border-bottom: 4px solid #bd9e46;
            border-radius: 9px;
        }
        table {
            width: 100% !important;
        }
        table th:nth-child(1) {
            width: 44%;
        }

        // .usageTable table th:nth-child(1) {
        //     width: 14%;
        // }

        // @for $i from 2 through 7 {
        //     table th:nth-child(#{$i}) {
        //         width: 8%;
        //     }
        // }
        .stats-refreshIcon {
            margin-top: 4px !important;
            margin-right: 13px !important;
        }
    }

    .warningMsg-text-pages {
        color: #f79b42;
        display: inline-block;
        width: 100%;
        margin: 0px;
        font-size: 14px;
    }

    .dashboardTiles {
        min-height: 535px!important;
        height: 100% !important;
        padding: 0;
        display: flex !important;
        background-color: $white;
        border-radius: 4px;
        margin-bottom: 20px;
        .table-container {
            padding-right: 0;
            .chart-wrapper5 {
                margin-top: 0px;
                .cardPadding {
                    padding: 20px 20px 0px;
                    .info-icon-color {
                        margin-left: 10px;
                    }
                    .table-switch-btn {
                        height: inherit;
                        position: absolute;
                        top: 107px;
                        .tabSwitch-btn1 {
                            font-size: 14px;
                            border: 0;
                            // color: #42427d;
                            // border: none;
                            // background-color: #ecedf3;
                            padding: 5px 1.8rem;
                            line-height: 1;
                            // border-radius: 8px;
                            &:hover {
                                cursor: pointer;
                            }
                        }
                        .taggedBtn {
                            margin-right: 5px;
                        }
                        .btnActive,
                        .activeBtn-bg {
                            color: $primaryColor-enable;
                            border: 1px solid $primaryColor-enable;
                            opacity: 1;

                            // background-color: #42427d;
                        }
                    }
                }
            }
            .card-body {
                min-height: 480px;
                overflow-y: hidden;
                .paginationPosition {
                    position: absolute !important;
                    top: 380px !important;
                }
            }
        }
        .table-section {
            padding: 0;
            .chart-wrapper-showme {
                margin-top: 0px;
                .page-data-div {
                    padding: 20px 20px 0px;
                    .info-icon-color {
                        margin-left: 10px;
                    }
                }
                .card-body {
                    min-height: 354px;
                    overflow-y: hidden;
                    .graphWidth {
                        width: 100%;
                        .graph-container {
                            width: 220px;
                            height: 360px;
                            padding: 20px;
                            margin-right: auto;
                            margin-left: auto;
                            .graph-height-div {
                                height: 320px;
                                width: 130px;
                                display: inline;
                                float: left;
                                border: 2px solid $white;
                                .untaggedHeight1,
                                .taggedHeight {
                                    background-color: $white;
                                    width: 100%;
                                    position: relative;
                                    .textMiddle {
                                        position: absolute;
                                        /*vertical-align: middle;*/
                                        transform: translateY(-50%);
                                        top: 50%;
                                        /* height: inherit; */
                                        p {
                                            font-size: 10px;
                                            margin-bottom: 0px;
                                        }
                                    }
                                }
                            }
                            .graph-data {
                                height: 320px;
                                width: 50px;
                                border: 2px solid $white;
                                display: inline;
                                float: left;
                                background-color: $lightgreen;
                                .untaggedHeight {
                                    width: 100%;
                                    background-color: $lightgray;
                                }
                            }
                        }
                        .graph-legend-section {
                            width: 220px;
                            height: 360px;
                            padding: 20px;
                            margin-right: auto;
                            margin-left: auto;
                            // .textCenter{
                            //     display: flex;
                            //     padding: 0px;
                            .percent-one,
                            .percent-three {
                                width: 50px;
                                p {
                                    font-size: 8px;
                                }
                            }
                            .percent-two {
                                width: 80px;
                            }

                            .div-sec-1 {
                                height: 280px;
                                width: 50px;
                                border: 2px solid $white;
                                display: inline;
                                float: left;
                                background-color: $darkturquoise;
                                position: relative;
                                .leftBar {
                                    width: 100%;
                                    background-color: $lightgray;
                                }

                                .leftBarCenter {
                                    position: absolute;
                                    bottom: 0px;
                                    width: 46px;
                                    background-color: $darkturquoise;
                                }
                            }
                            .div-sec-2 {
                                height: 280px;
                                width: 80px;
                                display: inline;
                                float: left;
                                border: 2px solid $white;
                                .sec-2-bg {
                                    background-color: $white;
                                    width: 100%;
                                    height: 20%;
                                }
                                .leftBarPercntInc {
                                    background-color: $white;
                                    width: 0px;
                                    height: 0px;
                                    border-right: solid 76px $skyblue;
                                }
                                .leftBarOpp {
                                    background-color: $skyblue;
                                    width: 100%;
                                    border-bottom: solid 2px $white;
                                }
                            }
                            .div-sec-3 {
                                height: 280px;
                                width: 50px;
                                border: 2px solid $white;
                                display: inline;
                                float: left;
                                background-color: $darkturquoise;
                                .textCenter {
                                    margin-top: 0px;
                                    color: $grey-focus;
                                }
                                .rightBar {
                                    width: 100%;
                                    height: 20%;
                                    background-color: $lightgray;
                                }
                            }
                        }
                    }
                }
            }
        }

        .createTab-div {
            position: absolute !important;
            width: 70%;
            top: 60px;
            right: 18px;
        }
    }

    .createPageTag-btn {
        // position: absolute;
        // left: 10px;
        // top: 87px
    }

    .showExport .table.tableId1 tbody tr td:nth-child(1) {
        width: 40%;
        .tableContent {
            max-width: 270px;
            cursor: pointer;
        }
    }

    .pageComponent {
        .noDomainWarning {
            display: block;
        }

        .table.tableId1 tbody tr td:nth-child(1) {
            width: 20%;
            .tableContent {
                // cursor: default !important;
            }
        }

        .table.tableId1 tbody tr td:nth-child(2) {
            width: 15%;
        }

        .table.tableId1 tbody tr td:nth-child(3) {
            width: 15%;
        }

        .table.tableId1 tbody tr td:nth-child(4) {
            width: 15%;
        }

        .table.tableId1 tbody tr td:nth-child(5) {
            width: 15%;
        }

        .table.tableId1 tbody tr td:nth-child(6) {
            width: 20%;
        }


        .table.tableId1 th:nth-child(1) {
            width: 20%;
        }

        .table.tableId1 th:nth-child(2) {
            width: 15%;
        }

        .table.tableId1 th:nth-child(3) {
            width: 15%;
        }

        .table.tableId1 th:nth-child(4) {
            width: 15%;
        }

        .table.tableId1 th:nth-child(5) {
            width: 15%;
        }

        .table.tableId1 th:nth-child(6) {
            width: 20%;
        }

        .table.tableId2 tbody tr td:nth-child(1) {
            width: 35%;
            .tableContent {
                // cursor: default !important;
            }
        }

        .table.tableId2 tbody tr td:nth-child(2) {
            width: 15%;
        }

        .table.tableId2 tbody tr td:nth-child(3) {
            width: 15%;
        }

        .table.tableId2 tbody tr td:nth-child(4) {
            width: 15%;
        }

        .table.tableId2 tbody tr td:nth-child(5) {
            width: 20%;
        }

        .table.tableId2 th:nth-child(1) {
            width: 35%;
        }

        .table.tableId2 th:nth-child(2) {
            width: 15%;
        }

        .table.tableId2 th:nth-child(3) {
            width: 15%;
        }

        .table.tableId2 th:nth-child(4) {
            width: 15%;
        }

        .table.tableId2 th:nth-child(5) {
            width: 20%;
        }
    }

    .usageTable table tbody tr td:nth-child(1) {
        // width: 40%;
        .tableContent {
            max-width: 280px;
            cursor: pointer !important;
        }
    }

    .usageTable table tbody tr td:nth-child(2) {
        width: 25%;
    }

    .usageTable table tbody tr td:nth-child(3) {
        width: 20%;
    }

    .usageTable table tbody tr td:nth-child(4) {
        width: 15%;
    }

    .usageTable table th:nth-child(1) {
        width: 40%;
    }

    .usageTabletable th:nth-child(2) {
        width: 25%;
    }

    .usageTabletable th:nth-child(3) {
        width: 20%;
    }

    .usageTable table th:nth-child(4) {
        width: 15%;
    }
}

.fa-star {
    color: $yellowsandybrown;
}

.loader-img {
    height: 300px;
}

.menu {
    list-style-type: none;
    overflow: hidden;
    width: 100%;
    margin-bottom: -8px;
    background-color: $section-header;
    border-radius: 8px 8px 0px 0px;
    color: $table-header-font;
    font-size: 18px;
    border: 1px solid $lightgray;
    height: 47px;
    padding: 0;
}

.menu li {
    float: left;
}

.menu li a {
    display: block;
    color: $table-header-font;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    border-right: 1px solid $lightgray;
    font-size: 14px;
    width: 160px;
    display: flex;
    align-items: center;
    height: 45px;
}

.menu li a:hover {
    background-color: $grey-enable;
    cursor: pointer;
}

.card-body-2 {
    height: 324px;
}

.modal-header {
    border-bottom-color: #eeeeee;
    background-color: #f5f5f5;
    display: block;
}

.tableBodyScroll tbody {
    display: block !important;
    max-height: 230px;
    overflow-y: auto;
}

.tableBodyScroll thead,
.tableBodyScroll tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;

    td {
        line-height: 2;
    }
}

.width40 {
    width: 40%;
}

.pagesTitle-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.selected-menu-bg {
    background-color: $grey-enable;
    height: 45px;
}

.appUsage-dashboard-table {
    text-align: left;
}

.cursorDefault {
    cursor: default !important;
}

.untaggedTable tbody tr td:nth-child(1) .textCapitalize {
    text-transform: lowercase;
}

.ruleOuterDiv{
    overflow-y: auto;
    padding-inline: 16px;
}