
.orgModal{
    .noData-div {
        font-size: 13px;
        font-weight: 600;
        padding: 15px;
        background-color: white;
        position: relative;
        text-align: center;
    }
}
