@import "style_variables";

.marginBottom5 {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.login-loaderHeight {
    height: calc(100vh - 100px);
}
.login {
    width: 100% !important;
    height: 100% !important;
    .loginContainer {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        background-color: $auth-section-bg;
        height: 100vh !important;
        .loginContainerParts {
            display: flex;
            flex-wrap: wrap;
            height: 100% !important;
            margin-right: -15px;
            margin-left: -15px;
            .loginForm {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                background-color: $white !important;

                .loginLogo {
                    width: 100% !important;
                    padding: 1rem !important;
                    .mainLogo {
                        width: 110px;
                        height: auto;
                    }
                }

                .loginHead {
                    color: $primaryColor-active !important;
                    font-weight: 400;
                    text-align: center;
                }

                .loginRemember {
                    height: 30px;
                    padding-top: 5px;
                    box-sizing: border-box;
                    .checkbox {
                        display: flex !important;
                        margin: 0px !important;
                        .check-container {
                            position: relative;
                            display: block;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            /* width: 139px; */
                            padding-left: 3px !important;
                            /* left: 20px; */
                            position: relative;

                            input {
                                display: none !important;
                            }

                            .step-span {
                                display: block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                padding-left: 20px;
                            }

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        .checkmarkBox-color {
                            border: 1px solid #eee;
                            background-color: #eee !important;
                        }

                        /* When the checkbox is checked, add a blue background */
                        .check-container input:checked ~ .checkmark {
                            background-color: $primaryColor-active !important;
                            border: 1px solid $primaryColor-active;
                        }

                        /* On mouse-over, add a grey background color */
                        .check-container:hover input:checked ~ .checkmark {
                            background-color: $primaryColor-hover !important;
                            border: 1px solid $primaryColor-hover;
                            cursor: pointer;
                        }

                        .check-container input:disabled ~ .checkmark {
                            background-color: $grey6 !important;
                            border: 1px solid $grey6;
                            cursor: default;
                        }

                        .check-container:hover input:disabled ~ .checkmark {
                            background-color: $grey6 !important;
                            border: 1px solid $grey6;
                        }

                        // tooltip
                        .checkedCheckmark {
                            background-color: $primaryColor-active !important;
                            border: 1px solid $primaryColor-active;
                        }

                        // .checkmark {
                        //     position: absolute;
                        //     top: 5px;
                        //     left: 5px;
                        //     height: 18px;
                        //     width: 18px;
                        //     background-color: #eee!important;
                        //     border-radius: 2px;
                        // }
                        // .checkmarkBox-color {
                        //     border: 1px solid #eee;
                        //     background-color: #eee !important;

                        //     &:hover{
                        //       border: 1px solid #ccc;
                        //       background-color: #ccc !important;
                        //       cursor: pointer;
                        //     }
                        // }
                    }

                    .checkbox {
                        display: inline-block !important;
                    }
                    .passwordLink {
                        color: $primaryColor-enable !important;
                        font-weight: 600;
                        margin-bottom: 10px;
                        font-size: 14px;
                    }
                    .passwordLink:focus,
                    .passwordLink:hover {
                        color: #23527c;
                        text-decoration: underline;
                    }
                }
                .loginCaptcha {
                    margin-top: 1rem !important;
                }

                .loginFooter {
                    color: #acadc1;
                    width: 60%;
                    text-align: center;
                    font-size: 90%;
                    font-weight: 400;
                    padding: 1rem !important;
                }
            }
        }

        .checkmark {
            position: absolute;
            top: 5px;
            left: 5px;
            height: 18px;
            width: 18px;
            // background-color: #eee;
            border-radius: 2px;
        }

        .padding-chk-0 {
            padding: 0 !important;
        }

        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        /* Create a custom checkbox */

        .check-container {
            position: relative;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            /* width: 139px; */
            padding-left: 20px;
            /* left: 20px; */
            position: relative;

            input {
                display: none;
            }

            .step-span {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-left: 20px;
            }
        }

        /* Show the checkmark when checked */
        .check-container input:checked ~ .checkmark:after {
            display: block;
        }

        /* Style the checkmark/indicator */
        .check-container .checkmark:after {
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            visibility: visible;
        }

        .check-container input:disabled ~ .checkmark:after {
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid transparent;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            visibility: visible;
        }

        .padding-chk-0 .checkmark {
            top: 0px !important;
        }
    }
}

.forgotPassword {
    width: 100% !important;
    height: 100% !important;

    .resendPassword {
        border: none;
        background-color: transparent;
    }
    .loginContainer {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        background-color: $auth-section-bg;
        height: 100vh !important;
        .loginContainerParts {
            display: flex;
            flex-wrap: wrap;
            height: 100% !important;
            margin-right: -15px;
            margin-left: -15px;
            .loginForm {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                background-color: $white !important;

                .loginLogo {
                    width: 100% !important;
                    padding: 1rem !important;
                    .mainLogo {
                        width: 110px;
                        height: auto;
                    }
                }

                .loginHead {
                    color: $primaryColor-active !important;
                    font-weight: 400;
                    text-align: center;
                }

                .errorContainer {
                    margin: 0px;
                    height: 20px;
                    .requiredErrorMsg {
                        font-size: 12px;
                        color: $error-red;
                        // margin-left: 10px;
                    }
                }

                .loginRemember {
                    height: 30px;
                    padding-top: 5px;
                    box-sizing: border-box;
                    .checkbox {
                        display: flex !important;
                        margin: 0px !important;
                        .check-container {
                            position: relative;
                            display: block;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            /* width: 139px; */
                            padding-left: 3px !important;
                            /* left: 20px; */
                            position: relative;

                            input {
                                display: none !important;
                            }

                            .step-span {
                                display: block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                padding-left: 20px;
                            }

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        .checkmarkBox-color {
                            border: 1px solid #eee;
                            background-color: #eee !important;
                        }

                        /* When the checkbox is checked, add a blue background */
                        .check-container input:checked ~ .checkmark {
                            background-color: $primaryColor-active !important;
                            border: 1px solid $primaryColor-active;
                        }

                        /* On mouse-over, add a grey background color */
                        .check-container:hover input:checked ~ .checkmark {
                            background-color: $primaryColor-hover !important;
                            border: 1px solid $primaryColor-hover;
                            cursor: pointer;
                        }

                        .check-container input:disabled ~ .checkmark {
                            background-color: $grey6 !important;
                            border: 1px solid $grey6;
                            cursor: default;
                        }

                        .check-container:hover input:disabled ~ .checkmark {
                            background-color: $grey6 !important;
                            border: 1px solid $grey6;
                        }

                        // tooltip
                        .checkedCheckmark {
                            background-color: $primaryColor-active !important;
                            border: 1px solid $primaryColor-active;
                        }

                        // .checkmark {
                        //     position: absolute;
                        //     top: 5px;
                        //     left: 5px;
                        //     height: 18px;
                        //     width: 18px;
                        //     background-color: #eee!important;
                        //     border-radius: 2px;
                        // }
                        // .checkmarkBox-color {
                        //     border: 1px solid #eee;
                        //     background-color: #eee !important;

                        //     &:hover{
                        //       border: 1px solid #ccc;
                        //       background-color: #ccc !important;
                        //       cursor: pointer;
                        //     }
                        // }
                    }

                    .checkbox {
                        display: inline-block !important;
                    }
                    .passwordLink {
                        color: $primaryColor-enable !important;
                        font-weight: 600;
                        margin-bottom: 10px;
                        font-size: 14px;
                    }
                    .passwordLink:focus,
                    .passwordLink:hover {
                        color: #23527c;
                        text-decoration: underline;
                    }
                }
                .loginCaptcha {
                    margin-top: 1rem !important;
                }

                .loginFooter {
                    color: #acadc1;
                    width: 60%;
                    text-align: center;
                    font-size: 90%;
                    font-weight: 400;
                    padding: 1rem !important;
                }
            }
        }

        .padding-chk-0 .checkmark {
            top: 0px !important;
        }
    }
}

.otp {
    width: 100% !important;
    height: 100% !important;
    .loginContainer {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        background-color: $auth-section-bg;
        height: 100vh !important;
        .loginContainerParts {
            display: flex;
            flex-wrap: wrap;
            height: 100% !important;
            margin-right: -15px;
            margin-left: -15px;
            .loginForm {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                background-color: $white !important;

                .loginLogo {
                    width: 100% !important;
                    padding: 1rem !important;
                    .mainLogo {
                        width: 110px;
                        height: auto;
                    }
                }

                .loginHead {
                    color: $primaryColor-active !important;
                    font-weight: 400;
                    text-align: center;
                }
                .otpInfoText {
                    font-size: 80%;
                    color: $filter-font;
                    padding: 25px;
                    line-height: 1.5;
                }

                .errorContainer {
                    margin: 0px;
                    height: 20px;
                    .requiredErrorMsg {
                        font-size: 12px;
                        color: $error-red;
                    }
                }

                .loginRemember {
                    height: 30px;
                    padding-top: 5px;
                    box-sizing: border-box;
                    .otpNotRecieved {
                        .otpEnable {
                            cursor: pointer;
                            color: #00a1e1;
                        }

                        .otpNotEnable {
                            color: $table-header-font;
                            font-weight: 600;
                        }

                        .counterColor {
                            color: $table-header-font;
                            font-weight: 600;
                        }
                    }
                    .passwordLink {
                        color: $primaryColor-enable !important;
                        font-weight: 600;
                        margin-bottom: 10px;
                        font-size: 14px;
                    }
                    .passwordLink:focus,
                    .passwordLink:hover {
                        color: #23527c;
                        text-decoration: underline;
                    }
                }

                .loginFooter {
                    color: #acadc1;
                    width: 60%;
                    text-align: center;
                    font-size: 90%;
                    font-weight: 400;
                    padding: 1rem !important;
                }
            }
        }
    }
}
