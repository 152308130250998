@import "../../style_variables.scss";

.chart-wrapper-dashboard {
    height: 490px;
    overflow-y: hidden;
}

.legendKey {
    min-height: 20px;
    // margin-left: 462.5px;
}

.filterContainer {
    display: flex;
    // flex-direction: row;
    // justify-content: flex-end;
    align-items: center;
    min-height: 70px;
    width: 100%;
}

.dashboardCardBorder0 {
    border-radius: 0px;
}

.appName {
    background-color: $loader-bg;
    padding: 8px 15px;
    border-radius: 14px;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid $white-shade2;
}

.appNameDiv {
    width: 450px !important;
    height: 40px !important;
    margin: auto;
}

.spaceDiv {
    width: 200px !important;
    height: 40px !important;
    margin: auto;
}

.eventSourceFilter {
    width: 180px !important;
    height: 40px !important;
    margin: auto;
}

.guideStateFilter {
    width: 180px !important;
    height: 40px !important;
    margin: auto;
}

.toolipFilter {
    width: 180px !important;
    height: 40px !important;
    margin: auto;
}

.ActivityExpand {
    display: block;
    justify-content: space-between;
}

.expand {
    cursor: pointer;
    font-size: 16px;
    color: #26273b;
    font-weight: bolder;
    margin-bottom: 20px;
}

.tab {
    color: #26273b !important;
    // border: 1px solid transparent !important;
    // font-weight: 800;
}

.arrowWeightDashBoard {
    font-weight: 600 !important;
    font-size: 18px !important;
}

.exportData-button {
    height: 40px;
    padding: 0.375rem 0.75rem;
    min-width: 140px;
    margin-right: 20px;
    font-size: 14px;
    color: #454560;
    border: 1px solid #ced4da;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 9;
    width: 150px;
    right: 118px;
    align-items: center;
    white-space: break-spaces;
    line-height: 1;
    position: absolute !important;
    top: 7px;
    z-index: 0;
}

.mainSection {
    .section-heading-div {
        position: relative;
        align-items: center;
        // margin-top: -10px;
        margin-bottom: 6px;

        .createBtnDiv {
            position: relative;
            height: 35px;
        }
    }

    //tableIDGuides

    .guideSummaryTable>div {
        overflow-x: auto;

        .table th span,
        .table th span p {
            width: 200px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: break-spaces;
        }
    }

    .dashboardSection .guideSummaryTable {

        .table tbody tr td:nth-child(1),
        .table th:nth-child(1) {
            width: 250px;
            max-width: 250px;
            position: sticky;
            left: 0px;
            background-color: white;
            z-index: 1;
        }

        .table tbody tr td:nth-child(2),
        .table th:nth-child(2) {
            // width: 100px;
            width: 200px;
        }

        .table tbody tr td:nth-child(3),
        .table th:nth-child(3) {
            // width: 12%;
            width: 200px;
        }

        .table tbody tr td:nth-child(4),
        .table th:nth-child(4) {
            // width: 10%;
            width: 200px;
        }

        .table tbody tr td:nth-child(5),
        .table th:nth-child(5) {
            // width: 10%;
            width: 200px;
        }

        .table tbody tr td:nth-child(6),
        .table th:nth-child(6) {
            // width: 10%;
            width: 200px;
        }

        .table tbody tr td:nth-child(7),
        .table th:nth-child(7) {
            // width: 9%;
            width: 200px;
        }

        .table tbody tr td:nth-child(8),
        .table th:nth-child(8) {
            // width: 9%;
            width: 200px;
        }

        .table {
            display: inline-table !important;

            // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05); 
            // border: 1px solid rgba(0, 0, 0, 0.08);
        }
    }

    // .guideSummaryTable  tbody tr td:nth-child(1),
    // .guideSummaryTable  th:nth-child(1) {
    //     width: 25%;
    //     .tableContent {
    //         // max-width: 180px;
    //         // max-width: 28%;
    //         // cursor: default !important;
    //     }
    // }
    // .guideSummaryTable  tbody tr td:nth-child(2),
    // .guideSummaryTable  th:nth-child(2) {
    //     width: 15%;
    // }

    // .guideSummaryTable  tbody tr td:nth-child(3),
    // .guideSummaryTable  th:nth-child(3) {
    //     width: 10%;
    // }

    // .guideSummaryTable  tbody tr td:nth-child(4),
    // .guideSummaryTable  th:nth-child(4) {
    //     width: 10%;
    // }

    // .guideSummaryTable  tbody tr td:nth-child(5),
    // .guideSummaryTable  th:nth-child(5) {
    //     width: 10%;
    // }

    // .guideSummaryTable  tbody tr td:nth-child(6),
    // .guideSummaryTable  th:nth-child(6) {
    //     width: 10%;
    // }
    // .guideSummaryTable  tbody tr td:nth-child(6),
    // .guideSummaryTable  th:nth-child(7) {
    //     width: 10%;
    // }

    // tableIdWorkFlows

    .tableIdWorkflows tbody tr td:nth-child(1),
    .tableIdWorkflows th:nth-child(1) {
        width: 17%;

        .tableContent {
            max-width: 180px;
        }
    }

    .tableIdWorkflows tbody tr td:nth-child(2),
    .tableIdWorkflows th:nth-child(2) {
        width: 15%;

        .tableContent {
            max-width: 150px;
        }
    }

    .tableIdWorkflows tbody tr td:nth-child(3),
    .tableIdWorkflows th:nth-child(3) {
        width: 17%;
    }

    .tableIdWorkflows tbody tr td:nth-child(4),
    .tableIdWorkflows th:nth-child(4) {
        width: 17%;
    }

    .tableIdWorkflows tbody tr td:nth-child(5),
    .tableIdWorkflows th:nth-child(5) {
        width: 17%;
    }

    .tableIdWorkflows tbody tr td:nth-child(5),
    .tableIdWorkflows th:nth-child(6) {
        width: 17%;
    }

    // tableIdSurveys

    .tableIdSurveys tbody tr td:nth-child(1),
    .tableIdSurveys th:nth-child(1) {
        width: 25%;

        .tableContent {
            // max-width: 28%;
            // cursor: default !important;
        }
    }

    .tableIdSurveys tbody tr td:nth-child(2),
    .tableIdSurveys th:nth-child(2) {
        width: 15%;
    }

    .tableIdSurveys tbody tr td:nth-child(3),
    .tableIdSurveys th:nth-child(3) {
        width: 15%;
    }

    .tableIdSurveys tbody tr td:nth-child(4),
    .tableIdSurveys th:nth-child(4) {
        width: 10%;
    }

    .tableIdSurveys tbody tr td:nth-child(5),
    .tableIdSurveys th:nth-child(5) {
        width: 15%;
    }

    .tableIdSurveys tbody tr td:nth-child(6),
    .tableIdSurveys th:nth-child(6) {
        width: 20%;
    }
}

.downArrowDashboard {}

.exportDiv {
    .exportFilter {
        border: 1px solid #acadc1;
        background-color: #f0f5ff;
        border-radius: 4px;
    }

    .btns-div {
        height: 200px;
        margin-top: 20px;
    }

    .downloadCsv span {
        // color: #26273b !important;
        border: 1px solid #acadc1;
        // background-color: #ffffff;
        padding: 6px;
        cursor: pointer;
        margin: auto;
        height: 35px;
        width: 120px;
    }

    .text {
        position: absolute;
        left: 75%;
        top: 25px;
        transform: translateX(-50%);
        width: 180px;
    }

    .exportModal-btn {
        height: 35px;
        margin: auto;
        background-color: white;
        border: 1px solid $filter-btn-border;
        color: $filter-font;
    }
}

.countryListDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    // height: 95px;
    padding: 10px 0px;

    // padding-top: 10px;
    .countryNameDiv {
        min-width: 100%;
        max-width: 100%;
        white-space: nowrap;
        min-height: 25px !important;

        p {
            margin-bottom: 0px;
            flex-wrap: wrap;
            display: flex;
        }
    }
}

.chipParagraph {
    .infoChip {
        padding: 5px 10px;
        border-radius: 15px;
    }

    .dateChips {
        background-color: $orangesandybrown;
        font-size: 13px;
    }

    .countryNameChip {
        // background-color: $turquoise;
        // background-color: #56e7ce;
        font-size: 13px;
        margin-bottom: 5px;
        margin-right: 5px;
        height: 27px;
        padding: 4px 10px 0px;
        box-sizing: border-box;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: white;
        
        .countryLabel {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.buubleDiv {
    border-left: 1px solid #d0d0dd;
}

.bg-yellow {
    background-color: rgb(236, 236, 84) !important;
    padding: 8px;
    border-radius: 8px;
}

.chartInfo-div {
    height: 87px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;

    margin-top: 20px;

    div {
        height: 55px;
        width: 177px;
        padding: 16px;
        box-sizing: content-box;
        .infoKey{
            font-size: 14px;
            font-weight: 400;
            color: #383838;
            margin-bottom: 10px;
        }
        .infoValue{
            font-size: 20px;
            font-weight: 900;
            color: #383838;
        }
    }

    .infoDivider {
        width: 1px;
        /* background-color: #e6e6e6; */
        margin: 0PX 16px;
        height: 55px;
        margin-top: 16px;
    }
    
}

.infoMessage {
    font-size: 12px;
    background-color: yellow !important;
    padding: 3px 6px;
    margin-bottom: 4px;
    border-radius: 4px;
    display: inline-block;
}

.dashDefault-btn {
    position: absolute;
    top: 230px;
    right: 30px;
}