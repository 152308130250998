@import "../../style_variables.scss";

.noChartsAdded{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    width:100%;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 8px;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    color: $filter-font;
}

.outerDiv {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 16px;

}