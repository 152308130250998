@import "style_variables";

.eventSource-div {
    btn-primary:focus {
        border-color: $primaryColor-enable !important;
    }
}

.eventSourceFilterTitle {
    font-size: 14px;
    color: #707070;
    font-weight: 700;
    top: 0;
    right: 428px;
    z-index: 1;
    width: 175px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.userEngement_eventSourceFilterTitle {
    font-size: 14px;
    color:#707070;
    font-weight: 700;
    top: 0;
    // position: absolute;
    right: 296px;
    z-index: 1;
}

.eventSourceModalFilterTitle {
    font-size: 13px;
    color: #383838;
    /* font-weight: 700; */
    top: 0;
    right: 297px;
    z-index: 1;
    position: absolute;
}


.source-but-event-source {
    right: 186px;
    width: 180px;
    // position: absolute;
    height: 40px;
    font-size: 16px;
    padding: 5px 25px 5px 23px;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    top: 21px;

    color: #000 !important;
    border-radius: 4px;
    cursor: hand;
    border: 1px solid #BBBBBB !important;
    text-align: left;
    overflow: hidden;
    background: linear-gradient(180deg, #ffffff80, #f5f5f5) !important;
    text-transform: capitalize;

    &:focus {
        border: 2px solid #005FCC !important;
    }

    &:hover {
        border: 1px solid #BBBBBB !important;
    }

    .switchApp-btn-icon {
        position: absolute;
        right: 10px;
        top: 13px;
    }

    span.caret {
        display: none;
    }
}

.exportRequestCardDiv {
    .source-but-event-source {
        top: 0px !important;
    }
}

.source-but-event-source-dashboard {
    position: static !important;
    right: 295px;
    width: 180px;
    // position: absolute;
    height: 40px;
    font-size: 14px;
    color: $filter-font !important;
    background-color: #ffffff !important;
    padding: 0.5rem 20px;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid $filter-btn-border;
    position: static !important;
    z-index: 999 !important;

    .switchApp-btn-icon {
        // position: absolute;
        right: 10px;
        top: 13px;
    }

    span.caret {
        display: none;
    }
}

.source-but-event-source-dashboard:hover {
    background-color: #ffffff !important;
    color: $filter-font !important;
}

.source-but-event-source-dashboard:active {
    background-color: #ffffff !important;
    color: $filter-font !important;
}

.source-but-event-source-dashboard span {
    display: inline-block;
    // margin-left: 8px;
    text-align: left;
}

// .source-but-event-source-dashboard {
//     position: static !important;
// }

.source-but-event-source-guide {
    right: 370px !important;
}

.source-but-event-source-tooltips {
    right: 370px !important;
}

.source-but-event-source-dashboard-export-modal {
    width: 250px !important;
    position: static !important;
    z-index: 999 !important;
}

.buttonPositionEventSource {
    // position: fixed !important;
    z-index: 999 !important;
}

.source-but-event-source:hover {
    background-color: #ffffff !important;
    color: $filter-font !important;
}

.source-but-event-source:active {
    background-color: #ffffff !important;
    color: $filter-font !important;
}

.source-but-event-source .eventSource-label {
    display: inline-block;
    // margin-left: 8px;
    text-align: left;
    width: 58%;
    // position: absolute;
    left: 30px;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1;
}

.source-but-event-source .eventSource-label-leftPadding {
    display: inline-block;
    // margin-left: 8px;
    text-align: left;
    width: 58%;
    // position: absolute;
    left: 15px;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1;
}


.source-icon {
    float: left;
    font-size: 20px !important;
}

.source-menu-event-source {
    text-align: left;
    top: 63px !important;
    min-width: 150px;
    padding-top: 0 !important;
    right: 186px !important;
    left: auto !important;
    transform: none !important;
    position: static !important;
    width: 180px;
    min-height: 232px;
}


.source-menu-event-source-dashboard {
    // right: 469px!important;
    text-align: left;
    // top: 120px !important;
    min-width: 150px;
    // padding: 0 !important;
    // position: absolute;
    // right: 294px;
    // left: auto !important;
    transform: none !important;
    // position: fixed!important;
    width: 200px;
    // width: 148px;
    position: static !important;
    z-index: 999 !important;
}


.source-menu-event-source-guide {
    right: 370px !important;
    top: 63px !important;
}

.source-menu-event-source-tooltips {
    right: 369px !important;
    min-height: 190px !important;
}

.source-menu-event-source-dashboard-export-modal {
    width: 250px !important;
}

.eventSourceList {
    max-height: 140px;
    overflow-y: auto;
}

.highlight {
    background-color: #e5e6f0;
}

.source-list1,
.source-list2,
.source-list3 {
    border: 1px solid;
    border-radius: 8px;
    width: 122px;
    text-align: center;
    margin-left: 13px;
    margin-right: 13px;
    margin-bottom: 7px;
    margin-top: 5px;
    font-size: 14px;
    padding: 4px;
    cursor: pointer;
}

// .round-input {
//   width: 200px !important;
// }

// .active-button{
//    background: #62628c !important;
//     color: #fff !important;
//     border-radius: 49px;
//     border-color: #62628c !important;
// }

.eventSource-div {
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .container input {
        // position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */

    .check-container {
        position: relative;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        /* width: 139px; */
        padding-left: 20px;
        /* left: 20px; */
        position: relative;
        font-size: 14px;
        height: 40px;

        .selectAll-div {
            margin-top: 10px !important;
        }

        input {
            display: none;
        }

        .step-span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 20px;
        }
    }

    .checkmark {
        position: absolute;
        top: 2px;
        left: 5px;
        height: 24px;
        width: 24px;
        // background-color: #eee;
        border-radius: 2px;
    }

    .padding-chk-0 {
        padding: 0 !important;
        margin-left: 5px
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .check-container input:checked~.checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .check-container .checkmark:after {
        left: 7px;
        top: 2px;
        width: 8px;
        height: 14px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        visibility: visible;
    }

    .check-container input:disabled~.checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid transparent;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        visibility: visible;
    }

    .displaySentimentsFilter {
        width: 200px;
    }

    .padding-chk-0 .checkmark {
        top: 0px !important;
    }

    .applyButton {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 10px;
    }

    .events-apply-btn {
        margin: 0 auto;
        top: 15px;
        margin-top: 8px;
        width: 100px;
        height: 30px;
        line-height: 1;
        border: 1px solid $primaryColor-active;
        background-color: $primaryColor-active;
        color: $white;
        font-size: 14px;
        margin-right: 0px;

        &:hover {
            background-color: $primaryColor-hover;
            cursor: pointer;
            color: $white;
        }

        &:disabled {
            border: 1px solid $grey6;
            background-color: $white;
            color: $grey6;
            cursor: default;
        }
    }
}

.eventSource-dashboard-div {
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */

    .check-container {
        position: relative;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        /* width: 139px; */
        padding-left: 20px;
        /* left: 20px; */
        position: relative;
        font-size: 14px;
        height: 40px;
        input {
            display: none;
        }

        .step-span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 20px;
        }
    }

    .checkmark {
        position: absolute;
        top: 2px;
        left: 5px;
        height: 18px;
        width: 18px;
        // background-color: #eee;
        border-radius: 2px;
    }

    .padding-chk-0 {
        padding: 0 !important;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .check-container input:checked~.checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .check-container .checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        visibility: visible;
    }

    .check-container input:disabled~.checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid transparent;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        visibility: visible;
    }

    .events-apply-btn {
        margin: 0 auto;
        top: 15px;
        margin-top: 8px;
        width: 100px;
        height: 30px;
        line-height: 1;
        border: 1px solid $primaryColor-active;
        background-color: $primaryColor-active;
        color: $white;
        font-size: 14px;
        margin-right: 10px;

        &:disabled {
            border: 1px solid $grey-enable;
            background-color: $grey6;
            color: $grey-enable;
            cursor: default;
        }
    }
}


.exportDiv {
    .source-menu-event-source {
        // position: absolute !important;
        top: 40px !important;
        right: 0px !important;
    }

    .eventSource-label-padding {
        display: inline-block;
        // margin-left: 8px;
        text-align: left;
        width: 56%;
        position: absolute;
        left: 15px;
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 1;
    }
}


.eventSource-count {
    position: absolute;
    top: 0px;
    /* overflow: hidden; */
    left: 0px;
    background: $primaryColor-active;
    font-size: 12px;
    color: white;
    font-weight: 600;
    width: 20px;
    height: 20px;
    text-align: center !important;
    border-radius: 50%;
}