
.filter-outer-div {
    top: 74px;
    position: fixed !important;
    width: calc(100% + 280px) !important;
    z-index: 21;
    /* left: 280px; */
    /* right: 280px; */
    left: 0px;
    height: 74px;
    display: flex;
    justify-content: flex-end;
}