@import "style_variables";

.guideAnalyticsDetail {
    table {
        display: block;
    }

    table tbody tr td:nth-child(1),
    table th:nth-child(1) {
        width: 28%;
        .tableContent {
            // max-width: 28%;
            // cursor: default !important;
        }
    }

    // .noDataText-font td:nth-child(1){
    //     width: 100vw;
    //     .tableContent {
    //         max-width: 100vw;
    //         // cursor: default !important;
    //     }
    // }

    table tbody tr td:nth-child(2),
    table th:nth-child(2) {
        width: 18%;
    }

    table tbody tr td:nth-child(3),
    table th:nth-child(3) {
        width: 18%;
    }

    table tbody tr td:nth-child(4),
    table th:nth-child(4) {
        width: 18%;
    }

    table tbody tr td:nth-child(5),
    table th:nth-child(5) {
        width: 18%;

        div {
            width:100%!important;
            height: 35px;
            display: flex;
        }
    }

    .guideStatus {
        padding: 2px 10px;
        border-radius: 10px;
        margin-left: 0;
        font-size: 11px;
        color: #273441;
    }

    .colorGrey {
        background-color: $unpublised;
    }

    .colorRed {
        background-color: $deleted;
        color: white;
    }

    .colorGreen {
        background-color: $success;
    }

    .colorYellow {
        background-color: #fbeb6d;
    }

    .userActivityBtn {
        right: 145px;
        top: 0px;
    }

    .opaque {
        cursor: not-allowed !important;
    }

    .sentimentDot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: $unpublised;
        display: inline-block;
        margin: 0px 10px 0px 0px;
    }

    .guideSentiment {
        background-color: $color1;
    }
    .exitSentiment {
        background-color: $color2;
    }
    .stepSentiment {
        background-color: $color3;
    }

    .sentimentsCard {
        font-size: 13px;
        padding: 8px;
        // border: 1px solid lightgray;
        border-radius: 5px;
        margin-top: 40px;
    }

    .sentimentsRow {
        padding: 12px;
        height: 70px !important;
        margin-top: 8px;
        margin-bottom: 8px;
        text-align: center;
        border-radius: 4px;
    }
    .guide {
        border: 2px solid $color1;
    }
    .exit {
        border: 2px solid $color2;
    }
    .step {
        border: 2px solid $color3;
    }

    @mixin smallText($left-value) {
        color: black;
        top: -20px;
        left: $left-value;
        width: 10px;
    }

    @mixin barColors($color-value) {
        background-color: $color-value;
        background-image: none !important;
    }

    #showMePyramid {
        margin-left: 0px;
        margin-top: 20px;
        display: flex;

        .barsContainer {
            display: inline-block;
            margin: 0 auto;
            position: relative;
            margin-top: 30px;

            .p1 {
                .progress-bar {
                    @include barColors($ppt);
                }
                small {
                    @include smallText(10);
                }
            }

            .p2 {
                .progress-bar {
                    @include barColors($video);
                }
                small {
                    @include smallText(62px);
                }
            }

            .p3 {
                .progress-bar {
                    @include barColors($gif);
                }
                small {
                    @include smallText(106px);
                }
            }

            .p4 {
                .progress-bar {
                    @include barColors($pdf);
                }
                small {
                    @include smallText(151px);
                }
            }

            .p5 {
                .progress-bar {
                    @include barColors($docx);
                }
                small {
                    @include smallText(196px);
                }
            }

            .p6 {
                .progress-bar {
                    @include barColors($html);
                }
                small {
                    @include smallText(242px);
                }
            }
        }

        .barLegend-text {
            position: absolute;
            bottom: -22px;
            font-size: 12px;
            width: 25%;
            text-align: left!important;
        }
    }

    .progress-bar-vertical {
        width: 25px;
        min-height: 200px;
        display: flex;
        align-items: flex-end;
        margin-right: 10px;
        margin-left: 10px;
        float: left;
        border-radius: 14px;

        .progress-bar {
            width: 100%;
            height: 0;
            -webkit-transition: height 0.6s ease;
            -o-transition: height 0.6s ease;
            transition: height 0.6s ease;
            border-radius: 24px;
        }
    }

    .position-absolute {
        position: absolute !important;
    }

    .justify-content-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .d-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .progress:hover {
        box-shadow: 0 3px 11px 0 rgba(122, 122, 122, 0.24), 0 16px 29px 0 rgba(0, 0, 0, 0.19);
    }

    #linechart_mainDiv .nv-chart {
        margin: 0 auto;
    }

    .showme-tiles {
        .chartDiv {
            border: 1px solid $grey6;
            border-radius: 4px;
        }

        .chartDiv-dash {
            border: 1px dashed $grey6;
            border-radius: 4px;
        }
    }
}

.linkedTable-modal.modal-dialog {
    max-width: 1040px !important;
    .linkedSummary-tableDiv > div:first-child {
        padding: 0 20px;
    }

    table {
        display: block;
        
    }

    table tbody tr td:nth-child(1),
    table th:nth-child(1) {
        width: 20%;
        .tableContent {
            // max-width: 28%;
            // cursor: default !important;
        }
    }

    // .noDataText-font td:nth-child(1){
    //     width: 100vw;
    //     .tableContent {
    //         max-width: 100vw;
    //         // cursor: default !important;
    //     }
    // }

    table tbody tr td:nth-child(2),
    table th:nth-child(2) {
        width: 20%;
    }

    table tbody tr td:nth-child(3),
    table th:nth-child(3) {
        width: 20%;
    }

    table tbody tr td:nth-child(4),
    table th:nth-child(4) {
        width: 20%;
    }

    table tbody tr td:nth-child(4),
    table th:nth-child(4) {
        width: 20%;
    }
}



.userActivity-modal.modal-dialog {
    max-width: 800px !important;
}

.userActivity-modal {
    width: 800px !important;
}


.stepErrorDetails {
    padding: 15px !important;
    height: 350px;
    overflow-y: auto;

    .errorCard {
        border: 1px solid $grey6;
        margin-top: 10px;
    }
}