@import "style_variables";

.SurveyFilterButton {
    display: flex;
    // position: absolute;
    /* right: 130px; */
    /* position: fixed; */
    right: 564px;
    z-index: 1;
    top: 21px;
}

.SurveyFilterModalBody {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    padding: 10px;
    flex-direction: column;
    font-size: 14px;

    .FilterDiv,
    .SurveyTypeDiv {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100px;
        width: 550px;
        padding: 5px;
        flex-direction: column;

        .FilterDivTitle,
        .SurveyTypeDivTitle {
            display: flex;
            align-items: center;
            justify-content: left;
            width: 540px;
            font-size: 14px;
        }

        .FilterDivDropDown,
        .SurveyTypeDropdownDiv {
            width: 100% !important;

            .filter-dropdown {
                width: 100% !important;
                height: 40px;
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: space-between;
                padding: 8px 10px !important;
                background-color: #ffffff !important;
                border: 1px solid #707070 !important;
                border-radius: 4px;
                box-shadow: 0px 1px 2px 1px #55555533 inset;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:disabled .dropdown-label {
                    color: #BBBBBB !important;
                }

                &:disabled i {
                    color: #BBBBBB !important;
                }

                &:disabled {
                    border: 1px solid #f5f5f5 !important;
                    pointer-events: none !important;
                }

                .dropdown-label,
                i {
                    font-size: 16px;
                    font-weight: 400;
                    color: #000000;
                }

                .dropdown-count {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    background: #005BF0;
                    font-size: 12px;
                    color: white;
                    font-weight: 600;
                    padding-top: 6px;
                    text-align: center !important;
                    border-radius: 50%;
                }
            }

            .dropdown-menu {
                width: 100% !important;
                padding: 0 !important;
                top: 40px !important;
                cursor: pointer;
                border: 2px solid transparent;
                height: fit-content;

                &.show {
                    display: block !important;
                }

                .custom-control {
                    cursor: pointer;
                    border: 2px solid transparent;
                    display: flex!important;

                    label {
                        cursor: pointer;
                    }

                    &:hover {
                        border: 2px solid #005FCC;
                        border-radius: 4px;
                    }

                    &.active {
                        border: 2px solid #005FCC;
                        border-radius: 4px;
                    }
                }

                .applyButton {
                    padding-top: 10px !important;
                    padding-bottom: 10px !important;
                }
            }
        }
    }
}

.SurveyFilter-modal {
    max-width: 700px;
    width: 700px !important;
}