.bitiComponent {
    padding: 98px 24px !important;
    min-height: 500px !important;

    .bitiDiv {
        display: flex;
        flex-direction: column;
        min-height: 500px !important;
        gap: 16px !important;
        box-sizing: border-box;
        background-color: #ffffff !important;
        padding: 16px !important;
        box-shadow: 0px 2px 2px 0px #2122263D;
        box-shadow: 0px 0px 2px 0px #0000001F;
        border-radius: 2px;


        .header {
            font-size: 20px;
            font-weight: 900;
            line-height: 27px;
            text-align: left;
            color: #383838;
        }

        .content-container {
            .accordian {
                width: 100%;
                position: relative;

                .accordion-header {
                    width: 100%;
                    height: 72px;
                    padding: 16px 8px 16px 8px;
                    border-bottom: 1px solid #E6E6E6;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    color: #555555;
                    font-size: 16px;
                    font-weight: 400;

                    img {
                        position: absolute;
                        right: 30px;
                        pointer-events: none;
                    }
                }

                .accordion-body {
                    color: #555555;
                    font-size: 14px;
                    font-weight: 400;

                    .bold {
                        font-weight: 700;
                    }

                    .link {
                        color: #005BF0;
                        cursor: pointer;
                    }

                    .headingText {
                        font-size: 16px;
                        font-weight: 700;
                    }
                }
            }

            .generateUrl {
                justify-content: space-between;

                .generateUrlBtn {
                    width: fit-content !important;
                    padding: 8px 16px;
                    min-width: 200px;
                }

                .urlInput {
                    position: relative;

                    &:hover .copy-button {
                        display: block;
                    }

                    input {
                        padding-right: 70px;
                        width: 100%;
                        cursor: not-allowed;
                    }

                    .copy-button {
                        position: absolute;
                        min-width: 80px;
                        right: 0px;
                        top: 0px;
                        height: 40px;
                        display: none;
                        background-color: #005BF0;
                        color: white;
                        border: none;
                        padding: 8px 10px;
                        font-size: 15px;
                        font-weight: 700;
                        border-top-right-radius: 0.25rem;
                        border-bottom-right-radius: 0.25rem;
                        text-align: center;
                        cursor: pointer;
                    }
                }

                .note {
                    font-size: 13px;
                    font-weight: 700;
                }
            }
        }
    }
}