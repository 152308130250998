@import "style_variables";

#goalDetailsProgressBar{
    margin-right: 200px;
    .arrow_box{
        position:relative;
        border: 1px solid $black;
        background-color: $white;
        border-radius: 4px;
        padding: 5px;
    }
}

.status_watcher-div{
    display: inline-flex;
    align-items: center;
    .goalDetailStatus {
        padding: 2px 10px;
        color: white;
        font-weight: 600;
        border-radius: 10px;
        font-size: 12px !important;
        // position: absolute;
        right: 14px;
        // text-transform: capitalize;
        // background-color: $darkturquoise;
    }
    .Live{
        background-color: $live;
    }
    .Achieved{
        background-color: $text;
    }
    .Ended{
        background-color: $video;
    }
    .Achieved_and_live{
        background-color: $pdf;
    }
    .Saved{
        background-color: #626262;
    }
    .Published{
        background-color: $docx;
    }

    .watcher-div{
        display: flex;
        border: 1px dotted #27273b;
        align-items: center;
        border-radius: 22px;
        padding: 6px 6px;
        // margin-left: 
        
        .watcherText{
            background-color: $lightgray;
            color: #27273b;
            // opacity: 0.65;
            font-weight: 500;
            align-self: center;
            margin-left: 3px;
        }
        .watchbtn-div {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 4px;
        }
        .watchbtn{
            height: auto;
            margin-left: 15px;
            border-radius: 12px;
        }
        
    }
    .loader{
        margin-left: 120px;
    }
}

.payload-combined-div{
    display: flex;
    // display: inline-flex;
    align-items: center;
    .payload-div{
        margin-top: 10px;
        align-items: center;
        // gap: 5px;

        .startText{
            font-size: 14px;
        }
        .endText{
            font-size: 14px;
            margin-left: 25px;   
        }

    }
    .payloadDetail {
        padding: 2px 10px;
        border-radius: 10px;
        font-size: 13px !important;
        overflow: hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
        max-width: 120px !important;
        display:inline-block;
    }

    .payloadDetailDate {
        padding: 2px 10px;
        border-radius: 10px;
        font-size: 14px !important;
        overflow: hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
        max-width: 146px !important;
        display:inline-block;
    }
    .detailFlexAlignCenter{
        display: flex;
        align-items: center;
        min-height: 30px;
        flex-wrap: wrap;
    }
    .fontSize-14 {
        font-size: 14px;
    }
    .guides{
        // display: inline-block;
    }

    .payloadText{
        background-color: $lightgray;
        color: #555555;
        // opacity: 0.65;
        font-weight: 700;
        align-self: center;
        margin-right: 2px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
    }
}

.goalDetailsSection {
    padding-top: 147px!important;

    .goalDetail-info {
        background-color: white;
        padding: 16px;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1215686275);
        border-radius: 4px;
        
        .goalTitle {
            font-family: Lato;
            font-size: 32px;
            font-weight: 900;
            line-height: 40px;
            color: #383838;
            text-transform: capitalize;
            margin-bottom: 0px;
            margin-top: 16px;
        }
    }
}