@import "style_variables";


.monthPicker-section {
    .react-datepicker__input-container input {
        border: 1px solid #ced4da;
        height: 40px;
        width:250px;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 0px 10px;
        font-size: 14px;
    }

    .react-datepicker-popper {
        z-index: 90;
    }
}

.monthPicker-label {
    width: 45px;
    text-align: right;
}