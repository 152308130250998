@import "style_variables";

.paddingEntries1 {
    padding: 0px 0px 18px 0px;
}

.paddingEntries2 {
    padding: 0px 0px 18px 20px;
}

.entries-dropdown1 {
    background-color: $white;
    color: $grey1;
    border: 1px solid $grey6;
    height: 30px;
    width: 100px;
}

.entries-dropdown2 {
    background-color: $white;
    color: $grey1;
    border: 1px solid $grey6;
    height: 30px;
    width: 135px;
}
