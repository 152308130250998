@import "style_variables";

#guideAutomationDetailSection {
    .categoryTitle {
        margin-top: 10px;
        background-color: #f1f1f1;
        margin-left: 0px;
        color: #424242;
        padding: 5 20px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid #c7c7c7;
        display: flex;
        min-width: 800px;
        align-items: center;
        padding: 0 10px;
        height: 32px;
        .category-icon g {
            fill: #1c85d0;
        }
    }
    .guideDiv {
        background-color: #d9ecf5;
        padding: 5 10px;
        font-size: 13px;
        margin-top: 5px;
        border-radius: 4px;
        min-height: 30px;
        // height: 50px;
        display: flex;
        min-width: 800px;
        align-items: center;

        .statusChip {
            margin-left: 30px;
        }

        .greenFont {
            color: #4caf50;
            text-transform: capitalize;
            font-weight: 600;
        }
        .redFont {
            color: #ff5722;
            text-transform: capitalize;
            font-weight: 600;
        }
    }

    .marginLeft-auto {
        margin-left: auto;
    }

    .entries-dropdown {
        background-color: #ffffff;
        color: #26273b;
        border: 1px solid #cfd0dd;
        height: 30px;
        width: 120px;
    }
}
