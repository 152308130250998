.guideErrors {
    .table tbody tr td:nth-child(1) {
        width: 25%;

        .tableContent {
            max-width: 420px;
            // cursor: pointer !important;
        }
    }

    .table tbody tr td:nth-child(2) {
        width: 25%;
    }

    .table tbody tr td:nth-child(3) {
        width: 25%;
    }

    .table tbody tr td:nth-child(4) {
        width: 25%;
    }


    .table th:nth-child(1) {
        width: 25%;
    }

    .table th:nth-child(2) {
        width: 25%;
    }

    .table th:nth-child(3) {
        width: 25%;
    }

    .table th:nth-child(4) {
        width: 25%;
    }






    @media screen and (min-width: 1250px) {
        table {
            width: 100% !important;
        }

        table th:nth-child(1) {
            width: 60%;
        }

        table th:nth-child(2) {
            width: 20%;
        }

        table th:nth-child(3) {
            width: 20%;
        }
    }

    @media screen and (max-width: 1249px) {
        table th:nth-child(1) {
            width: 60%;
        }

        table th:nth-child(2) {
            width: 20%;
        }

        table th:nth-child(3) {
            width: 20%;
        }
    }


}