@import "style_variables";

.searchBox {
    border-bottom: 1px solid lightgray;
    margin-bottom: 20px;
    display: inline-block;
    margin-top: 15px;
    .guideTitle {
        // width: 600px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #444444;
        font-size: 14px;
        margin-right: 20px;
        text-align: left;
        margin-left: -20px;
        display: block;
        white-space: nowrap;
    }

    .userActivity-title{
         width: 100%
    }
}

.user-modal {
    padding: 10px 20px;
    height: 450px;
    overflow-y: auto;
    .userCard {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
        /* line-height: 1; */
        padding: 18px 5px;
        border-radius: 4px;
        margin-bottom: 15px;

        .userDetails {
            margin-left: 15px;

            .userName {
                color: #444444;
                font-weight: 600;
            }
        }

        .userInitial {
            width: 39px;
            height: 39px;
            border-radius: 50%;
            background-color: rgb(175, 149, 149);
            display: flex;
            align-items: center;
            //padding-left: 11px;
            box-sizing: border-box;
            font-size: 23px;
            font-weight: 600;

            span {
                margin: 0 auto;
            }
        }

        .guide-status {
            display: inline-block;
            width: 100%;

            .playedSpan {
                background-color: $orangesandybrown;
                padding: 3px 10px;
                border-radius: 16px;
                font-size: 11px;
                font-weight: 600;
                color: white;
                margin-left: 10px;
                float: right;
            }
            .completedSpan {
                background-color: $lightseagreen;
                padding: 3px 10px;
                border-radius: 16px;
                font-size: 11px;
                font-weight: 600;
                color: white;
                margin-left: 14px;
                margin-left: 10px;
                float: right;
            }
        }

        .playDetails {
            margin-top: 5px;
            color: $grey-focus;

            .lastPlay-text {
                color: $grey-focus;
                text-align: right;
                font-size: 12px;
            }

            .lastPlay-time {
                margin-bottom: 0;
                margin-top: -7px;
                font-size: 11px;
                color: $grey-focus;
                text-align: right;
            }
        }
    }

    .videoSpan {
        background-color: $video;
        padding: 3px 10px;
        border-radius: 16px;
        font-size: 11px;
        font-weight: 600;
        color: white;
        margin-left: 10px;
        float: right;
    }

    .gifSpan {
        background-color: $gif;
        padding: 3px 10px;
        border-radius: 16px;
        font-size: 11px;
        font-weight: 600;
        color: white;
        margin-left: 14px;
        margin-left: 10px;
        float: right;
    }

    .slideshowSpan {
        background-color: $ppt;
        padding: 3px 10px;
        border-radius: 16px;
        font-size: 11px;
        font-weight: 600;
        color: white;
        margin-left: 5px;
        float: right;
    }
}

.searchPosition {
    position: absolute;
    width: 300px!important;
    right: 35px;
}

.guidePlay-icon-table  {
    height: 15px;
    position: relative;
    top: -1px;
    path {
        fill: $grey;
    }
}