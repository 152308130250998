@import "style_variables";

.expandedDiv {
    display: none;
}

.vertAlignT {
    vertical-align: top;
    font-family: 'Lato', sans-serif;
}

.sNDashboardTitle-input {
    display: flex;
    align-items: center;

    .fa-pencil {
        display: none;
        margin-left: 5px;
    }

    &:hover {
        .fa-pencil {
            display: inline-block;
            cursor: pointer;
        }
    }

    .sNDashbordLabelClass {
        background-color: transparent !important;
        border: 1px solid transparent !important;
        padding: 5px 10px 8px !important;
        height: 25px !important;
        outline: none !important;
        margin: 0px;
        cursor: pointer;
    }

    .sNDshbordInputClass {
        background-color: white !important;
        border: 1px solid $lightgray !important;
        padding: 5px 10px 8px !important;
        height: 25px !important;
        outline: none !important;
        line-height: 0;
    }
}

#inputClass1 {
    height: 25px;
    width: 125px;
    background-color: transparent;
    box-sizing: border-box;
    border: solid white 1px;
    border-radius: 4px;
}

#inputClass2 {
    height: 25px;
    width: 125px;
    background-color: transparent;
    box-sizing: border-box;
    border: solid white 1px;
    border-radius: 4px;
}

#inputClass3 {
    height: 25px;
    width: 125px;
    background-color: transparent;
    box-sizing: border-box;
    border: solid white 1px;
    border-radius: 4px;
}

.checkOverlap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}

.sidenavHeight {
    .icon-box {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        margin: 0 8px;

        svg {
            width: 15px;
            height: 15px;
        }
    }
}

.mg-sideNav {
    color: white;
    width: 100%;

    .mainLink {
        font-size: 14px;
        box-sizing: border-box;
        min-height: 50px;
        line-height: 21px;
        // background-color: $greySideNavColor;
    }

    .menuLevel1 {
        background-color: $greySideNavColor;
    }

    .menuLevel2 {
        background-color: #26273b;
    }

    .menuLevel3 {
        background-color: #1e1f2f;
    }

    .menuLevel4 {
        background-color: #0f0f17;
    }
}

.disable-interactions {
    opacity: 0.5;
}

.disable-interactions .navbar .navbar-nav>li,
.disable-interactions ul.panel-collapse li a {
    cursor: not-allowed !important;
}

.disable-interactions .navbar .navbar-nav>li>a {
    pointer-events: none;
}

.delete-dashboard-modal {
    .modal-header {
        padding-top: 4px;
        padding-bottom: 0px;
        background-color: #FFFFFF;
        border-bottom: none;
    }

    .deleteDashboard {

        .deleteChart {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 30px 20px 10px;
        }

        .delete-img {
            margin-bottom: 16px;
            margin-left: auto;
            margin-right: auto;
        }

        .body-heading {
            font-size: 20px;
            color: #000000;
            font-weight: 900;
        }

        .body-content {
            color: #555555;
            font-size: 14px;
            font-weight: 400;
        }


    }

    .modal-footer {
        border-top: none;

        button {
            min-width: auto;
            margin-left: 45%;
        }

        padding-top: 5px;
        margin-bottom: 25px;
    }
}