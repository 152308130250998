@mixin displayFlexContainter(
    $alignItems,
    $justifyContent,
    $flexDirection,
    $width,
    $height,
    $horizontalMargin,
    $verticalMargin,
    $rowGap,
    $columnGap
) {
    display: flex;
    align-items: $alignItems;
    justify-content: $justifyContent;
    flex-direction: $flexDirection;
    width: $width;
    height: $height;
    row-gap: $rowGap;
    column-gap: $columnGap;
    margin: $verticalMargin $horizontalMargin;
}

.guideAutomation {

    .table tbody tr td:nth-child(1),
    .table th:nth-child(1) {
        width: 30%;
        .tableContent {
            // max-width:35%;
            // cursor: pointer !important;
        }
    }

    .table tbody tr td:nth-child(2),
    .table th:nth-child(2) {
        width: 25%;
    }

    .table tbody tr td:nth-child(3),
    .table th:nth-child(3) {
        width: 15%;
    }

    .table tbody tr td:nth-child(4),
    .table th:nth-child(4) {
        width: 15%;
    }
    .table tbody tr td:nth-child(5),
    .table th:nth-child(5) {
        width: 15%;
    }

}

.guideAutomationDetail {
    .guideAutomationDescription {
        display: block;
        .subTitles {
            @include displayFlexContainter(center, flex-start, row, 100%, 30px, 2px, 10px, 10px, 5px);
            font-size: 13px;
        }
        .playedBy {
            font-size: 13px;

            @include displayFlexContainter(baseline, flex-start, row, 90%, auto, 2px, 10px, 10px, 5px);
            .keyName {
                color: grey;
                min-width: 65px;
            }
            .userName {
                word-break: break-all;
            }
        }
        .otherDetails {
            font-size: 13px;

            @include displayFlexContainter(center, flex-start, row, 100%, 30px, 2px, 10px, 20px, 10px);
            .keyName {
                font-weight: 600;
                color: grey;
            }
            .valueName {
                text-transform: capitalize;
                font-weight: 600;
                color: grey;
            }
            .black {
                color: #4a4a4a;
            }
            .orange {
                color: rgb(253, 158, 41) !important;
            }
        }
    }
}
