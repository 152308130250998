@import "../../../style_variables.scss";

.filterDiv {
    // display: flex;
    height: 100px;
    align-items: center;
    width: 100%;
    // margin-top: 50px;

    .guideStatus {
        padding: 2px 10px;
        border-radius: 10px;
        margin-left: 0;
        font-size: 11px;
        color: #273441;
    }

    .colorGrey {
        background-color: $unpublised;
    }

    .colorRed {
        background-color: $deleted;
        color: white;
    }

    .colorGreen {
        background-color: $success;
    }

    .colorYellow {
        background-color: #fbeb6d;
    }
}

.userEngagementSection {

    .table tbody tr td:nth-child(1),
    .table th:nth-child(1) {
        width: 250px;
        position: sticky;
        max-width: 200px;
        left: 0px;
        background-color: white;
        z-index: 1;

        div {
            background-color: white;
            margin-left: -4px;
            padding-left: 5px;
        }
    }

    .table {
        display: inline-table !important;
    }



    .table th>span,
    .table th>span>p {
        width: 200px;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: break-spaces;
        justify-content: space-between;
    }

    .commonSummaryTable>div {
        overflow-x: auto;
    }

    .table tbody tr td:nth-child(2),
    .table th:nth-child(2) {
        width: 200px;
    }

    .table tbody tr td:nth-child(3),
    .table th:nth-child(3) {
        width: 200px;
    }

    .table tbody tr td:nth-child(4),
    .table th:nth-child(4) {
        width: 200px;
    }

    .table tbody tr td:nth-child(5),
    .table th:nth-child(5) {
        width: 200px;
    }

    .table tbody tr td:nth-child(6),
    .table th:nth-child(6) {
        width: 200px;
    }

    .table tbody tr td:nth-child(7),
    .table th:nth-child(7) {
        width: 200px;
    }
}