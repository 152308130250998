#goalSection {
    .table tbody tr td:nth-child(1) {
        width: 20%;
        .tableContent{
            // max-width:420px;
            // cursor: pointer !important;
        }
    }

    .table tbody tr td:nth-child(2) {
        width: 13%;
    }

    .table tbody tr td:nth-child(3) {
        width: 20%;
    }

    .table tbody tr td:nth-child(4) {
        width: 20%;
    }

    .table tbody tr td:nth-child(4) {
        width: 20%;
    }

    .table tbody tr td:nth-child(5) {
        width: 20%;
    }

    .table tbody tr td:nth-child(6) {
        width: 17%;
    }


    .table th:nth-child(1) {
        width: 20%;
    }

    .table th:nth-child(2) {
        width: 13%;
    }

    .table th:nth-child(3) {
        width: 20%;
    }

    .table th:nth-child(4) {
        width: 20%;
    }

    .table th:nth-child(5) {
        width: 20%;
    }

    .table th:nth-child(6) {
        width: 7%;
    }

    .editDeleteBtn {
        border: none;
        background-color: transparent;
    }

    .createBtn-div-goal {
        justify-content: flex-end;
        display: flex;

        .btn {
            width: 140px;
        }
    }
}