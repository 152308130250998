.errorImg{
    position: absolute;
    top: 200px;
    text-align: center;
    img{
        width: 20%;
    }
    p{
        font-size: 25px;
    }
}