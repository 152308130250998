@import "style_variables";

.featureFilterTitle {
	top: 0;
    // position: absolute;
    right: 300px;
    z-index: 1;
    font-size: 14px;
    font-weight: 700;
    color: #707070;
}

.source-but:hover {
	background-color: $filter-font;
	color: #fff;
}

.source-but:hover[disabled] {
	background-color: #ecedf3;
	color: $filter-font;
}

.source-but:active {
	background-color: $filter-font;
	color: #fff;
}

.source-but span {
	display: inline-block;
	margin-left: 8px;
	text-align: left;
}

.source-but {
	height: 40px;
	padding: 0.375rem 0.75rem;
	min-width: 140px;
	// margin-right: 20px;
	font-size: 16px;

	border-radius: 4px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	z-index: 9;
	width: 180px;
	right: 177px;
	align-items: center;
	white-space: break-spaces;
	line-height: 1;
	// position: absolute !important;
	top: 21px;



    color: #000 !important;
    border-radius: 4px;
    cursor: hand;
    border: 1px solid #BBBBBB !important;
    text-align: left;
    overflow: hidden;
    background: linear-gradient(180deg, #ffffff80, #f5f5f5) !important;

    &:focus{
        border: 2px solid #005FCC !important;
    }

    &:hover{
        border: 1px solid #BBBBBB !important;
    }
}

.featureList {
	padding: 10px 20px;
	height: 50vh;
	overflow-y: auto;
}

.treeSection {
    // height: 250px;
    // overflow-y: auto;
    // overflow-x: hidden;
    // border: 1px solid lightgray;
    // border-radius: 4px;
    
    .guideDiv {
        border-bottom: 1px solid $lightgray;
    }

    .guideDiv-bg:nth-of-type(odd) {
        background-color: #ffffff;
        padding: 10px 0px;
    }

    .guideDiv-bg:nth-of-type(even) {
        background-color: #eafaf5;
        padding: 10px 0px;
    }

    .check-container {
        position: relative;
        display: block;
        // overflow: hidden;
        $white-space: nowrap;
        text-overflow: ellipsis;
        width: 139px;
        padding-left: 20px;
        /* left: 20px; */
        position: relative;

        input {
            display: none;
        }
    }

    .checkmark {
        position: absolute;
        top: 2px;
        left: 5px;
        height: 18px;
        width: 18px;
        border: 1px solid #eee;
        border-radius: 2px;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .check-container {
        display: inline-block;
        width: auto;
        font-size: 14px;
    }

    /* Show the checkmark when checked */
    .check-container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .check-container .checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        visibility: visible;
    }

    .check-container input:disabled ~ .checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid transparent;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        visibility: visible;
    }

    .card-header {
        height: auto !important;
    }

    .level2 {
        padding-left: 50px;
        margin-bottom: 5px;
    }
}

