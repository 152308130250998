.sankeybox {
    height: 360px;
    width: 100%;
    padding: 10px 20px;
    align-items: center;
}

svg text {
    font-size: 13px;
    stroke-width: 0;
    fill: black;
}

.sankey .node text {
    pointer-events: none;
}

.sankey .node rect {
    cursor: move;
    fill-opacity: 0.9;
    shape-rendering: crispEdges;
}
.sankey .node text {
    pointer-events: none;
    text-shadow: 0px 0px 13px #fff;
}

.sankey .node:hover {
    stroke-opacity: 0.2;
}

.sankey .link {
    fill: none;
    stroke: #000;
    stroke-opacity: 0.4;
    transition-property: stroke-opacity;
    transition-duration: 0.5s;
}
.sankey .link:hover {
    stroke-opacity: 0.8;
}

.sankeybox {
    display: flex;
    flex-direction: column;
    position: relative;
}

.d3-zoom-controls {
    position: absolute;
    left: 0px;
    top: 10px;
}

.d3-zoom-controls .btn-circle {
    width: 30px;
    height: 30px;
    align-items: center;
    padding: 0px 0;
    font-size: 18px;
    line-height: 2;
    display: grid;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.7);
    color: gray;
    margin-top: 10px;
}
