.positionEventSourceDropdown {
    width: 100%;
    left: -50px !important;
    margin-left: 0px !important;
}

.positionGuideTutorialDropdown {
    left: 0px !important;
    margin-left: 15px;
    overflow: auto;
    width: 100% !important;
}

.zIndex-0 {
    z-index: 0!important;
}
