@import "style_variables";

.app-select-btn {
    height: 40px;
    width: 180px;
    position: absolute;

    padding: 5px 25px 5px 23px;
    top: 21px;
    cursor: pointer;
    display: inline-block;
    text-align: left;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1;


    color: #000 !important;
    border-radius: 4px;
    cursor: hand;
    border: 1px solid #BBBBBB !important;
    text-align: left;
    overflow: hidden;
    background: linear-gradient(180deg, #ffffff80, #f5f5f5) !important;

    &:focus {
        border: 2px solid #005FCC !important;
    }

    p {
        padding-top: 7px !important;
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

    }
}

.app-select-btn:hover {
    border: 1px solid black;
}

.app-row {
    display: flex;
    flex-direction: row;
    padding: 4px;
    margin: 5px;
    align-items: center;
    font-size: 14px;
    border-bottom: 1px solid $filter-btn-border;
    border-radius: 4px;


    &:hover {
        background-color: #ebf2fe;
    }
}

.app-panel {
    width: 500px;
}

.env-select {
    width: 245px;

    .dropdown {
        padding: 0 !important;
        width: 100%;
        height: 40px;
        font-size: 14px;
        color: $greySideNavColor !important;


    }

    .dropdown:hover {
        outline: none !important;
    }

    .dropdown-toggle {
        width: 100%;
        text-align: left;
        height: 40px;
        font-size: 14px;
        background-color: #ffffff !important;
        border-radius: 4px;
        color: $primaryBlue-color !important;
        background: #ffffff !important;
        border:1px solid $primaryBlue-color !important;
        height: 40px !important;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
        font-weight: 700;
        font-size: 15px;

        &:hover {
            background: rgba(0, 91, 240, 0.08);
            border: 1px solid rgba(0, 91, 240, 1);
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
        }

        &:focus {
            border: 2px solid rgba(0, 95, 204, 1);
            color: $primaryBlue-color !important;
            background: #ffffff !important;
            border-color: $primaryBlue-color !important;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);

        }

        &:disabled,
        &.disabled {
            background: rgba(245, 245, 245, 1);
            border: 1px solid rgba(230, 230, 230, 1);
            color: rgba(187, 187, 187, 1);
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
        }

        &:hover {
            background: $white !important;
            background-color: $white !important;
            color: #555555;
        }

        span {
            padding: 2px 5px !important;
            outline: none !important;
        }
    }

    .dropdown-menu {
        top: 40px !important;
        min-height: 80px;
    }
}

.modal-body-container {
    max-height: 500px;
    overflow: auto;
}

.appEnv-filter-title {
    position: absolute;
    right: 208px;
    font-size: 14px;
    color: #707070;
    font-weight: 700;
    text-align: left;
    width: 169px;
}