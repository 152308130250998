@import "style_variables";

#Heatmap > svg:not(:last-child) { 
  display: none; 
}

.pageDetailSection {
    .noDomainWarning{
        display: block;
    }
    .paginationPosition {
        position: absolute;
        bottom: -100px;
        right: 20px;
        left: 20px;
    }

    .exportPdf-btn {
        // margin-right: 20px;
        margin-top: 0px;
        position: absolute;
        // right: -260px;
        cursor: pointer;
    }

    .deletePage-btn-square {
        margin-right: 10px!important;
        cursor: pointer;
        position: absolute;
        right: 134px;
    }


    .createPage-btn-square {
        position: absolute;
        right: 274px;

        span {
            display: flex;
            align-items: center;
        }
    }

    .color_table thead th {
        /*background-color: white;*/
        /*border-bottom-color: white;*/
        // background-color: $tableHeader-purple;
        // color: white;
    }

    .demo {
        /* margin-left: 8px; */
        padding-top: 10px !important;
    }

    // .section-heading-div {
    //     margin-top: 0px;
    // }
    .sectionSub-heading {
        padding: 0px;
        .sectionTitle {
            height: 40px;
            .section-col-div {
                padding-left: 0px;
            }
        }
    }
    .statCards {
        .borderBottom-1 {
            border-bottom: 4px solid #0b8be6;
            border-radius: 9px;
        }
        .borderBottom-2 {
            border-bottom: 4px solid #d4737c;
            border-radius: 9px;
        }
        .borderBottom-3 {
            border-bottom: 4px solid #6ebd46;
            border-radius: 9px;
        }
        .borderBottom-4 {
            border-bottom: 4px solid #bd9e46;
            border-radius: 9px;
        }
        table {
            width: 100% !important;
        }
        table th:nth-child(1) {
            width: 44%;
        }
        .userEngagementStatsSummaryTable{

            .table tbody tr td:nth-child(1) {
            width: 30%;
                .tableContent {
                    max-width: 420px;
                }
            }
            .table tbody tr td:nth-child(2) {
                width: 40%;
            }
        
            .table tbody tr td:nth-child(3) {
                width: 30%;
            }
        
            .table th:nth-child(1) {
                width: 30%;
            }
        
            .table th:nth-child(2) {
                width: 40%;
            }
        
            .table th:nth-child(3) {
                width: 30%;
            }
        
        }

        // .usageTable table th:nth-child(1) {
        //     width: 14%;
        // }
        
        // @for $i from 2 through 7 {
        //     table th:nth-child(#{$i}) {
        //         width: 8%;
        //     }
        // }
        .stats-refreshIcon {
            margin-top: 4px !important;
            margin-right: 13px !important;
        }
    }
    .dashboardTiles {
        padding: 0;
        display: flex;
        .table-container {
            padding-right: 0;
            .chart-wrapper5 {
                margin-top: 0px;
                .cardPadding {
                    padding: 20px 20px 0px;
                    .info-icon-color {
                        margin-left: 10px;
                    }
                    .table-switch-btn {
                        height: inherit;
                        margin-top: -7px;
                        display: flex;
                        float: right;
                        .tabSwitch-btn1 {
                            font-size: 14px;
                            border: 0;
                            // color: #42427d;
                            // border: none;
                            // background-color: #ecedf3;
                            padding: 0.5rem 1.8rem;
                            // border-radius: 8px;
                            &:hover {
                                cursor: pointer;
                            }
                        }
                        .taggedBtn {
                            margin-right: 5px;
                        }
                        .btnActive,
                        .activeBtn-bg {
                            color: white;
                            // border-color: #42427d;
                            // background-color: #42427d;
                        }
                    }
                }
            }
            .card-body {
                min-height: 480px;
                overflow-y: hidden;
                .paginationPosition {
                    position: absolute !important;
                    top: 380px !important;
                }
            }
        }
        .table-section {
            padding-left: 0;
            .chart-wrapper-showme {
                margin-top: 0px;
                .page-data-div {
                    padding: 20px 20px 0px;
                    .info-icon-color {
                        margin-left: 10px;
                    }
                }
                .card-body {
                    min-height: 354px;
                    overflow-y: hidden;
                    .graphWidth {
                        width: 100%;
                        .graph-container {
                            width: 220px;
                            height: 360px;
                            padding: 20px;
                            margin-right: auto;
                            margin-left: auto;
                            .graph-height-div {
                                height: 320px;
                                width: 130px;
                                display: inline;
                                float: left;
                                border: 2px solid #ffffff;
                                .untaggedHeight1,
                                .taggedHeight {
                                    background-color: #ffffff;
                                    width: 100%;
                                    position: relative;
                                    .textMiddle {
                                        position: absolute;
                                        /*vertical-align: middle;*/
                                        transform: translateY(-50%);
                                        top: 50%;
                                        /* height: inherit; */
                                        p {
                                            font-size: 10px;
                                            margin-bottom: 0px;
                                        }
                                    }
                                }
                            }
                            .graph-data {
                                height: 320px;
                                width: 50px;
                                border: 2px solid #ffffff;
                                display: inline;
                                float: left;
                                background-color: $lightgreen;
                                .untaggedHeight {
                                    width: 100%;
                                    background-color: $lightgray;
                                }
                            }
                        }
                        .graph-legend-section {
                            width: 220px;
                            height: 360px;
                            padding: 20px;
                            margin-right: auto;
                            margin-left: auto;
                            // .textCenter{
                            //     display: flex;
                            //     padding: 0px;
                            .percent-one,
                            .percent-three {
                                width: 50px;
                                p {
                                    font-size: 8px;
                                }
                            }
                            .percent-two {
                                width: 80px;
                            }
                            // }
                            .div-sec-1 {
                                height: 280px;
                                width: 50px;
                                border: 2px solid #ffffff;
                                display: inline;
                                float: left;
                                background-color: $darkturquoise;
                                .leftBar {
                                    width: 100%;
                                    background-color: $lightgray;
                                }
                            }
                            .div-sec-2 {
                                height: 280px;
                                width: 80px;
                                display: inline;
                                float: left;
                                border: 2px solid #ffffff;
                                .sec-2-bg {
                                    background-color: #ffffff;
                                    width: 100%;
                                    height: 20%;
                                }
                                .leftBarPercntInc {
                                    background-color: #ffffff;
                                    width: 0px;
                                    height: 0px;
                                    border-right: solid 76px $skyblue;
                                }
                                .leftBarOpp {
                                    background-color: $skyblue;
                                    width: 100%;
                                    border-bottom: solid 2px #ffffff;
                                }
                            }
                            .div-sec-3 {
                                height: 280px;
                                width: 50px;
                                border: 2px solid #ffffff;
                                display: inline;
                                float: left;
                                background-color: $darkturquoise;
                                .textCenter {
                                    margin-top: 0px;
                                    color: grey;
                                }
                                .rightBar {
                                    width: 100%;
                                    height: 20%;
                                    background-color: $lightgray;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .fa-star {
        color: #f8d562;
    }

    .loader-img {
        height: 300px;
    }

    .menu {
        list-style-type: none;
        overflow: hidden;
        width: 100%;
        margin-bottom: -8px;
        background-color: $grey6;
        border-radius: 8px 8px 0px 0px;
        color: $table-header-font;
        font-size: 18px;
        border: 1px solid #e0e0e0;
        height: 47px;
    }

    .menu li {
        float: left;
    }

    .menu li a {
        display: block;
        color: $table-header-font;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        border-right: 1px solid #e0e0e0;
        font-size: 14px;
        width: 160px;
        display: flex;
        align-items: center;
        height: 45px;
    }

    .menu li a:hover {
        background-color: #a9aab9;
    }

    .card-body-2 {
        height: 324px;
    }

    .modal-header {
        border-bottom-color: #eeeeee;
        background-color: #f5f5f5;
        display: block;
    }

    .tableBodyScroll tbody {
        display: block!important;
        max-height: 230px;
        overflow-y: auto;
    }

    .tableBodyScroll thead,
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        
        td {
            line-height: 2;
        }
    }

    .mainSection .statCards table th:nth-child(1) {
        width: 24%;
    }

    .mainSection .statCards table th:nth-child(2) {
        width: 12%;
    }

    .mainSection .statCards table th:nth-child(3) {
        width: 12%;
    }

    .mainSection .statCards table th:nth-child(4) {
        width: 14%;
    }

    .usageTable table tbody tr td:nth-child(1) {
        width: 40%;
        .tableContent{
            max-width:280px;
            cursor: pointer !important;
        }
    }

    .usageTable table tbody tr td:nth-child(2) {
        width: 25%;
    }

    .usageTable table tbody tr td:nth-child(3) {
        width: 20%;
    }

    .usageTable table tbody tr td:nth-child(4) {
        width: 15%;
    }

    .usageTable table th:nth-child(1) {
        width: 40%;
    }

    .usageTabletable th:nth-child(2) {
        width: 25%;
    }

    .usageTabletable th:nth-child(3) {
        width: 20%;
    }

    .usageTable table th:nth-child(4) {
        width: 15%;
    }

    .width40 {
        width: 40%;
    }

    .pagesTitle-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    .border-style {
        border: 1px dashed lightgray;
        margin: 10px 0px;
        border-radius: 10px;
        max-width: 32.4%;
        margin-right: 8px;
        height: 200px;
        cursor: pointer;
    }


    .statBg-color {
        background-color: #e6f6fa;
    }

}

.chartDiv {
    .workflow-countBox {
        border: 1px solid $grey6;
        border-radius: 6px;
        padding: 25px 0px;
        margin-bottom: 20px;
    }
    .bestBox {
        border-bottom: 3px solid $color1;
        .bestTime-count {
            font-size: 20px;
            font-weight: 600;
            color: $color1;
        }
    }
    .avgBox {
        border-bottom: 3px solid $color2;
        .avgTime-count {
            font-size: 20px;
            font-weight: 600;
            color: $color2;
        }
    }
    .worstBox {
        border-bottom: 3px solid $color3;
        .worstTime-count {
            font-size: 20px;
            font-weight: 600;
            color: $color3;
        }
    }
}

.userEngagementStatsSummaryTable{

    .table tbody tr td:nth-child(1) {
    width: 30%;
        .tableContent {
            max-width: 420px;
        }
    }
    .table tbody tr td:nth-child(2) {
        width: 40%;
    }

    .table tbody tr td:nth-child(3) {
        width: 30%;
    }

    .table th:nth-child(1) {
        width: 30%;
    }

    .table th:nth-child(2) {
        width: 40%;
    }

    .table th:nth-child(3) {
        width: 30%;
    }

}