@import "style_variables";

a.disabled {
    pointer-events: none;
    cursor: pointer;
}

.cursorPointer {
    cursor: pointer;
}

.dummayAppImage {
    height: 26px;
    width: 26px;
    min-width: 26px;
    border: 1px solid grey;
    display: block;
    right: 12px;
    font-size: 21px;
    background-color: white;
    text-align: center;
    padding-top: 1px;
    line-height: 1;
    margin-top: 2;
    border-radius: 4px;
}

.marginTop3 {
    margin-top: 3px;
}

.marginTop12 {
    margin-top: 12px;
    margin-bottom: 5px;
}

.width18px {
    width: 18px;
}

.switchOrg-btn {
    padding: 4px 7px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: transparent;
    background-size: cover;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    color: #4a4c5e;
    -webkit-text-decoration: none solid #4a4c5e;
    text-decoration: none solid #4a4c5e;
    text-align: center;
    // position: absolute;
    top: -2px;
    /* right: -29px; */
    // margin-left: 10px;
    cursor: pointer;
    img {
        height: 30px;
    }
}

.orgName-div {
    left: 230px;
    width: 200px;
    font-size: 13px;
    font-weight: 600;
    position: absolute;

    span {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
        cursor: default;
    }
}

.organisationSwitcher .loading :after {
    position: absolute;
    width: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);
    //background-image: url("/src/assets/img/loading1.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 8em;
    content: "";
    z-index: 10000;
}

.organisationSwitcher {
    padding-right: 20px;
    padding-left: 20px;

    .switcherInput-div {
        padding: 15px 0px;
    }
}

@media screen and (max-width: 576px) {
    .navbar-menuBtn {
        display: block;
    }
    .collapse.show {
        display: block !important;
    }
    .collapse {
        display: none !important;
    }
    .appApplication-div {
        right: 150px;
    }
}

.pointer {
    cursor: pointer;
}

.borderOrg {
    border: 1px solid #d3e5ff;
    margin: 10px 0px;
    border-radius: 4px;
    padding:0px
}

.nav-side-menu ul :not(collapsed) .arrow:before,
.nav-side-menu li :not(collapsed) .arrow:before {
    font-family: FontAwesome;
    content: "\f078";
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    float: right;
}

header.header-new a .new-logo {
    width: 192px;
    height: auto;
    margin-top: -15px;
    margin-left: 1px;
}

.float-right {
    float: right;
}

.displayFlex {
    display: flex !important;
}

.displayNone {
    display: none !important;
}

.nopadding {
    padding: 0px;
}

.navbar .navbar-nav > li a {
    padding: 14px 10px;
    display: inline-block;
    color: $grey6;
}

.navbar .navbar-nav > li a:hover {
    text-decoration: none;
    // color: #ffffff;
    // transition: all 0.5s ease-in-out;
}

.navbar .navbar-nav li.menu-item-has-children .sub-menu a {
    padding: 2px 0 2px 47px;
}

.navbar .navbar-nav > li {
    // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    width: 100%;
}

.navbar .navbar-nav > li > a {
    width: 100%;
}

.navbar li > a:hover {
    background-color: #ebf2fe!important;
    color: #555555 !important;
    border-radius: 4px;
}

.nav.navbar-nav li:nth-child(2) span,
.nav.navbar-nav li:nth-child(3) span,
.nav.navbar-nav li:nth-child(4) span {
    top: 1px;
    position: relative;
    margin-right: 5px;
}

.subLink-1 a {
    color: #555555;
    font-size: 16px;
    font-weight: 400;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    // padding-left: 15px !important;
    height: 48px;
    width: 100%;
}

.subLink-2 a {
    font-size: 13px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 80px !important;
    height: 48px;
    width: 100%;
    color: #555555;

}

.subMenu-2-span {
    line-height: 1.1;
    display: table-caption;
}

.sideOption-down,
.sideOption-up {
    position: absolute;
    right: 15px;
}

.sideOption-up {
    color: #fff;
}

.detailFlag {
    height: 10px;
    width: 10px;
    background-color: #c7c9ca;
    border-radius: 50%;
    display: inline-block;
}

.position1 {
    position: absolute !important;
    right: 20px;
    top: 20px !important;
}

.position2 {
    position: relative !important;
    right: -67px;
    top: 2px !important;
}

.position3 {
    position: relative !important;
    right: -57px;
    top: 2px !important;
}

/* Input focus */

// label {
//   position: relative;
//   display: inline-block;
//   // margin: 10px;
// }

li a input.inputclass {
    height: 25px !important;
    width: 40px !important;
}

input:focus + .placeholderText,
input:not(:placeholder-shown) + .placeholderText,
textarea:focus + .placeholderText,
textarea:not(:placeholder-shown) + .placeholderText,
select:focus + .placeholderText,
select:not(:placeholder-shown) + .placeholderText {
    opacity: 1;
    transform: scale(0.75) translateY(-100%) translateX(-30px);
    background-color: white;
    left: 20px;
    position: absolute;
    padding: 0px;
    color: $primaryColor-enable;
    top: 0px;
}

/* For IE Browsers*/

input:focus + .placeholderText,
input:not(:-ms-input-placeholder) + .placeholderText,
textarea:focus + .placeholderText,
textarea:not(:placeholder-shown) + .placeholderText,
select:focus + .placeholderText,
select:not(:placeholder-shown) + .placeholderText {
    opacity: 1;
    transform: scale(0.75) translateY(-100%) translateX(-30px);
    background-color: white;
    left: 20px;
    position: absolute;
    padding: 0px;
    color: $primaryColor-enable;
    top: 0px;
}

input,
textarea,
select {
    box-shadow: none !important;
    // border: #eaeaea 2px solid;
}

input:focus,
textarea:focus,
select:focus {
    // border: #1aa2de 2px solid;
}

/* Input focus */

// .active-icon {
//   fill: red;
// }

.switch-btn {
    border: 1px;
    margin-left: 10px;
    width: 90px;
    height: 40px;
    padding: 0px 15px 1px;
    border-radius: 4px;
    background-color: #38b6a0;
    background-size: cover;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
}

.org-row {
    padding: 8px;
}

.switchOrganization {
    .checkmark {
        display: block;
        width: 16px;
        height: 18px;
        border-radius: 50%;
        margin-left: -14px;
        margin-top: 2px;
    }
    .checkmark:after {
        position: relative;
        left: 5px;
        top: 5px;
        width: 6px;
        height: 6px;
        background-color: white;
        border: solid white;
        border-radius: 50%;
    }
    .check-container input {
        // position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    :hover {
        // color: #444;
        // background-color: #ebf7f5 !important;
    }
}

.org-active-row {
    background-color: #ceece7;
    color: #333333;
    font-weight: 400;
}

.orgText-overflow {
    width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    font-size: 14px;
}

.displayInline-width100 {
    width: 100% !important;
    display: block;
}

.itemCount {
    padding: 0px !important;
    font-size: 13px;
    display: inline-block;
    margin: 0px;
    width: 100%;
}

.orgData {
    width: 100%;
    // height: 40vh;
    // overflow-y: auto;
    // overflow-x: hidden;
    // padding-right: 5px;
    margin-top: 20px;

    .org-loaderHeight {
        height: 40vh;
    }
    .orgList-div {
        height: 40vh;
        overflow-y: auto;
        padding: 0 15px;

        .displayInline-width100 {
            // padding: 0 15px;
        }
    }

    .switcherPagination {
        border-top: 1px solid #e8e8e8;
    }
}

.header-orgName {
    position: relative;
    top: 39px;
    left: 14px;
    font-size: 13px;
    color: #444457;
    display: inline-block;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.siteLayout-block {
    /*min-height: 100vh;*/
    display: block;
    header.header-new {
        background: #fff;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
        clear: both;
        display: block;
        padding: 9px 30px 15px 20px;
        width: 100%;
        height: 60px;
        .inline-block {
            display: inline-block;
            header.header-new a .new-logo {
                width: 192px;
                height: auto;
                margin-top: -15px;
                margin-left: 1px;
                top: 5px;
            }
        }
        .logoDivider {
            height: 58px;
            width: 1px;
            background-color: #dcdcdc;
            margin-right: 15px;
            position: absolute;
            left: 135px;
            display: block;
            top: -8px;
        }
        .analyticsLogo-div {
            display: -webkit-box;
            display: flex;
            text-align: center;
            border-radius: 4px;
            padding: 1px 10px 2px 10px;
            border: 1px solid transparent;
            position: relative;
            -webkit-box-align: center;
            align-items: center;
            line-height: 1;
            &:hover {
                // border: 1px solid #c5c5ce;
            }
            &:hover .spiltTriangle {
                visibility: visible;
            }
        }
        .userData-div {
            display: flex;
            text-align: center;
            .spiltTriangle {
                width: 10px;
                height: 10px;
                background-color: transparent;
                border-left: 10px solid #c5c5cd;
                border-right: 10px solid transparent;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                position: absolute;
                right: -10px;
                transform: rotate(45deg);
                top: 30px;
                visibility: hidden;
            }
            .appDetails {
                text-align: left;
                margin-right: 10px;
                display: inline-block;

                span {
                    display: flex;
                    // direction: rtl;
                }
                .analytics-text {
                    color: #27273b;
                    font-size: 14px;
                    // margin-top: -4px;
                    margin-left: 8px;
                }
            }
            .myIntel-logo {
                height: 26px;
                width: 26px;
                border-radius: 4px;
                // border: 1px solid #c5c5ce;
                // border-radius: 20px;
            }
            .myIntel-logo1 {
                height: 26px;
                width: 26px;
            }
        }
        .linkMain-div {
            position: absolute !important;
            top: 54px !important;
            width: 190px;
            background-color: white;
            box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
                0 3px 3px -2px rgba(0, 0, 0, 0.12);
            left: 196px !important;
            border: none;
            .linksDiv {
                border: 1px solid transparent;
                border-radius: 4px;
                padding: 0 9px;
                margin: 0 15px;
                display: flex;
                align-items: center;
                text-decoration: none;
                .analytics-links-text {
                    color: #5d5c7b;
                    font-size: 26px;
                }
                &:hover {
                    border: 1px solid #c5c5cd;
                }
            }
        }
        .appApplication-div {
            position: relative;
            right: 22px;
            top: -4px;
        }
        .user-area {
            .user-menu {
                background: #fff;
                border: none;
                font-family: 'Lato', sans-serif;
                left: inherit !important;
                right: -15;
                top: 65px !important;
                margin: 0;
                max-width: 200px;
                padding: 0px 0px;
                position: absolute;
                width: 100%;
                z-index: 1000;
                min-width: 300px;
                border-radius: 4px;
                overflow: hidden;
                .switch-org-btn {
                    border: none;
                    color: black;
                    font-size: 14px;
                    text-align: left;
                    /* // padding: 10px 15px; */
                    width: 100%;
                    &:hover {
                        /* // background-color: #a5e3fd; */
                        color: rgba(0, 0, 0, 0.5);
                        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
                    }
                    .user-info {
                        padding: 20px 20px;
                        background-color: $grey-active;
                        color: white;
                        .userName {
                            font-weight: 600;
                            color: white;
                        }
                        .userEmailId-text {
                            font-size: 12px;
                            margin-top: -8px;
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: white;
                        }
                        .userDetails-text {
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: white;
                            .orgImage {
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                                border: 2px solid #d0d0d0;
                            }
                        }

                        .org-logo-div {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            margin-right: 10px;
                            border: 1px solid lightgrey;
                            display: flex;
                            margin-top: -12px;
                            .orgImage {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                margin: auto;
                            }
                        }

                        .org-name-p {
                            background-color: #f0f0f5;
                            padding: 10px 18px;
                            /* border-radius: 4px; */
                            margin-left: -20px;
                            margin-right: -20px;
                            margin-bottom: -20px;
                            margin-top: 10px;
                            color: $table-header-font;

                            .orgText {
                                color: #9e9e9e;
                                margin-right: 5px;
                            }

                            .orgName {
                                font-weight: 700;
                            }
                        }
                    }
                    .user-settings {
                        padding: 10px 0px;
                        // box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);
                        .resetPassword-text {
                            padding-top: 5px;
                            /* // border-top: 1px solid #b4bcbf; */
                            padding-bottom: 5px;
                            /* // border-top: 1px solid #b4bcbf; */
                            /* font-size: 12px; */
                            color: $table-header-font;
                            font-weight: 600;
                            font-size: 12px;
                            padding-left: 20px;
                            padding-right: 20px;
                            height: 34px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                        }
                        .resetPassword-text i,
                        .logout-text i,
                        .adminLink img {
                            margin-right: 8px;
                        }
                        .logout-text {
                            color: $table-header-font;
                            font-weight: 600;
                            font-size: 12px;
                            padding-top: 0px;
                            padding-bottom: 5px;
                            margin-bottom: 0px;
                            padding-left: 20px;
                            padding-right: 20px;
                            height: 34px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                        }
                        .adminLink {
                            color: $table-header-font !important;
                            font-weight: 600;
                            font-size: 12px;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            margin-bottom: 0px;
                            text-decoration: none;
                            padding-left: 20px;
                            padding-right: 20px;
                            height: 34px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            img {
                                width: 13px;
                            }
                            a {
                                text-decoration: none;
                                color: $table-header-font !important;
                            }
                        }
                        .resetPassword-text:hover,
                        .logout-text:hover,
                        .adminLink:hover {
                            background-color: #f1f2f7;
                            cursor: pointer;
                        }
                    }
                }
                .user-avatar {
                    float: none;
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                }
            }
            a {
                display: flex;
                align-items: center;
            }
            .initialsDiv {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                text-align: center;
                font-size: 24px;
                font-weight: 600;
                color: black;
                text-transform: uppercase;
            }
            .user-icon-name {
                line-height: 38px;
                /* float: right; */
                /* padding: 10px; */
                margin-left: 10px;
                font-size: 13px;
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                margin-right: 10px;
            }
            .userArrow {
                font-size: 20px;
                position: relative;
                /* top: 9px; */
                left: 3px;
            }
        }
    }
}

.orgLogo-Div {
    max-width: 300px;
    height: 45px;
    left: 46px;
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    // border: 1px dashed #7a5faf;
    // border-radius: 6px;
    margin-top: -12px;
    padding-right: 15px;
    // background-color: #d7ccea;

    .orgLogo-bg {
        background-size: contain;
        /* width: 200px; */
        height: 40px;
        width: 40px;
        min-width: 40px;

        background-repeat: no-repeat;
        // min-width: 100px;
        // max-width: 200px;

        background-color: white;
        background-position: center;
        // border-radius: 6px 0px 0px 6px;
        border: 1px solid #e3e3e3;
    }

    .orgName-span {
        margin-left: 5px;
        font-size: 18px;
        color: $grey1;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 50px;
        cursor: default;
    }
}

.user-area {
    // position: absolute;
    /* right: 10px; */
    top: -6px;
    border-left: 1px solid #f0f0f5;
    height: 60px;
    /* width: 120px; */
    padding-left: 10px;
    font-size: 14px;
}

.selected-app-div {
    // position: absolute;
    // right: 140px;
    height: 35px;
    // border: 1px solid #39b6a0;
    border-radius: 0px 4px 4px 0px;
    width: 180px;
    position: relative;
    top: -2px;
    left: 9px;
    border-left: 1px solid transparent !important;
}
.changePass-body {
    // padding-top: 30px;
    padding-bottom: 20px;
    .errorContainer {
        height: 20px;
        .requiredErrorMsg {
            font-size: 12px;
            color: #c22828;
            margin-left: 10px;
        }
        button[disabled] {
            background-color: lightgray;
            border: 1px solid lightgray;
        }
    }
    .placeholderText {
        padding: 10px;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: -3px;
        transition: 0.2s;
        transition-timing-function: ease;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        opacity: 0.5;
    }
    .export-btn {
        background-color: #00a1e1;
        border: 1px solid #00a1e1;
        color: white;
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 4px;
        float: right;
    }
}

.exportModal {
    .or-div {
        top: calc(50% - 20px);
    }
    .text {
        top: -10px;
    }
    .vl {
        height: 85px;
        margin-top: 0px;
    }
}

// ::-webkit-scrollbar {
//     width: 8px;
// }

/* Track */

// ::-webkit-scrollbar-track {
//     background: #f1f1f1;
// }

/* Handle */

// ::-webkit-scrollbar-thumb {
//     background: #888;
// }

// /* Handle on hover */

// ::-webkit-scrollbar-thumb:hover {
//     background: #555;
// }

.left-panel {
    background-color: $greySideNavColor;
    ul.panel-collapse {
        list-style: none;
        -moz-padding-start: 0;
        -webkit-padding-start: 0;
        -khtml-padding-start: 0;
        -o-padding-start: 0;
        padding: 0;
        li {
            i {
                margin-right: 0px;
            }
            a {
                padding-left: 50px;
            }
        }
    }
    .main-menu {
        display: block !important;
        .sidenavHeight {
            // height:100vh;
            background-color: $greySideNavColor;
            .ul-padding0 {
                padding: 0px;
            }
            .collapseIcon {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
            }
            .liMenu-position a {
                position: relative;
            }
            .toggleIcon {
                padding-top: 14px !important;
                padding-bottom: 14px !important;
                min-height: 57px;
                box-sizing: border-box;
            }
            .toggleIcon span {
                line-height: 1;
            }
            // .toggleIcon[data-toggle].collapsed .togSpan:after {
            //   content: "\02C7 ";
            //   font-size: 25px;
            //   /* margin-top: 10px; */
            //   /* display: inherit; */
            //   margin: auto;
            //   position: relative;
            //   top: 14px;
            //   left: 13px;
            // }
            // .toggleIcon[data-toggle]:not(.collapsed) .togSpan:after {
            //   content: "\02C6";
            //   font-size: 25px;
            //   /* margin-top: 10px; */
            //   /* display: inherit; */
            //   margin: auto;
            //   position: relative;
            //   top: 14px;
            //   left: 13px;
            // }
            .toggleIcon[data-toggle].collapsed .toggleSpan:after {
                content: "\02C7 ";
                font-size: 25px;
                /* margin-top: 10px; */
                /* display: inherit; */
                margin: auto;
                position: relative;
                top: 11px;
                left: 13px;
            }
            .toggleIcon[data-toggle]:not(.collapsed) .toggleSpan:after {
                content: "\02C6";
                font-size: 25px;
                /* margin-top: 10px; */
                /* display: inherit; */
                margin: auto;
                position: relative;
                top: 13px;
                left: 10px;
            }
            .toggleIcon i:before {
                color: white;
                font-weight: bold;
                margin-left: 10px;
            }
        }
    }
}

.modal-body {
    .domain-input {
        width: 100%;
        height: 35px;
        border: 1px solid gainsboro;
        border-radius: 4px;
        padding: 0 10px;
    }
    .domain-example {
        color: grey;
    }
    .domainSave {
        font-size: 12px;
        float: right;
        width: 100px;
    }
}

.domainModal {
    .errorContainer {
        height: 20px;
        margin-top: -10;
        margin-bottom: 20px;
        margin-left: -10px;
        .requiredErrorMsg {
            font-size: 12px;
            color: #c22828;
            margin-left: 10px;
        }
    }
}

.left-panel {
    overflow-y: auto;
}

.header-logo-div {
    width: 192px;
    top: -2px;
    position: relative;
}

@media (min-width: 576px) {
    .navbar_expand_sm .navbar_collapse {
        /* display: -webkit-box!important; */
        display: -ms-flexbox !important;
        display: block !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

@media (min-width: 800px) {
    .navbar {
        /*position: fixed;
       width: 13%;*/
    }
    header.header-new {
        position: fixed;
        z-index: 1000;
    }
    #left-panel {
        // margin-top: 147px;
        width: 280px;
    }
}

@media (min-width: 500px) and (max-width: 799px) {
    header.header-new {
        position: fixed;
        z-index: 1000;
    }
    #left-panel {
        // margin-top: 147px;
    }
}

@media (width: 576px) {
    .mac-os #main-menu > ul.nav.navbar-nav {
        width: 100% !important;
    }
    .mac-os img.new-logo {
        width: 30% !important;
    }
    .header-new {
        position: fixed;
    }
}

@media (max-width: 620px) {
    header.header-new a .new-logo {
        width: 50%;
        display: none;
    }
}

@media (max-width: 576px) {
    .sidenavHeight {
        height: auto;
    }
}

@media (max-width: 575.99px) {
    .right-panel .breadcrumbs {
        padding-left: 15px;
    }
    aside.left-panel .navbar .navbar-nav li > a {
        padding-left: 18px;
    }
    .navbar .navbar-header {
        /*width: 90%;*/
        width: 100%;
        height: 50px;
    }
    .navbar-menuBtn {
        float: right;
        margin-top: 10px;
        color: rgba(255, 255, 255, 0.6);
        outline: none;
    }
    .user-icon-name {
        display: none;
    }
}

@media (max-width: 454px) {
    header.header-new a .new-logo {
        width: 100%;
    }
}

@media (max-width: 446px) {
    .user-area .user-menu {
        z-index: 9999 !important;
    }
}

@media (max-width: 620px) {
    .analyticsLogo-div {
        margin-top: 0px;
        left: -50;
    }
}

@media (max-width: 320px) {
    .analyticsLogo-div {
        left: -70px;
    }
    .appApplication-div {
        right: 150px;
    }
}

.session_modal {
    p {
        text-align: center;
        font-size: 14px;
    }
    .exp-okBtn {
        width: 100px;
        margin: 0 auto;
        background-color: #4d5275;
        color: white;
        border-radius: 4px;
        font-size: 14px;
        cursor: pointer;
    }
}

.exportLi {
    padding: 15px 20px;
    font-size: 13px;
    cursor: pointer;
    i {
        font-size: 22px;
        color: white;
    }
    span {
        color: rgba(255, 255, 255, 0.8) !important;
        margin-left: 6px;
    }
    &:hover span {
        color: white !important;
    }
}

.source-menu {
    text-align: left;
    top: 40px !important;
    min-width: 200px;
    padding: 0 !important;
    position: absolute;
    right: 222px;
    left: auto !important;
}

.highlight {
    background-color: #e5e6f0;
}

.source-list1,
.source-list2,
.source-list3 {
    color: #0a93cb;
    padding: 0 0.5rem;
    font-weight: 600;
    font-size: 12px;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-bottom: 3px;
    margin-top: 3px;
    cursor: pointer;
}

.source-list1:hover,
.source-list2:hover,
.source-list3:hover {
    background-color: #e5e6f0;
}

.round-input {
    width: 100%;
}

.expDiv {
    width: 100%;
    padding: 20px 25px;

    .export-btn {
        padding: 0.375rem 0.75rem;
        border-radius: 4px;
        float: right;
        width: 135px !important;
        text-align: center;
        display: block;
    }
    button[disabled] {
        background-color: lightgray;
        border: 1px solid lightgray;
    }
}

.stpesLabel {
    font-size: 13px;
    cursor: pointer;
    div {
        margin: 3px;
        width: 85px;
        cursor: pointer;
        display: flex;
        align-items: center;
        .lableIcons {
            margin-left: 17px;
            margin-right: -19px;
        }
    }
    span {
        font-size: 14px;
        margin-left: 8px;
    }
}

.dateRange-span {
    // position: relative;
    // top: 15px;
    // left: 10px;
    font-size: 14px;
}

.chk {
    margin-left: 10px;
}

custom-control-label {
    cursor: pointer !important;
}

.exportLi {
    padding: 15px 20px;
    font-size: 13px;
    cursor: pointer;
    i {
        font-size: 22px;
        color: white;
    }
    span {
        color: rgba(255, 255, 255, 0.8) !important;
        margin-left: 6px;
    }
    &:hover span {
        color: white !important;
    }
}

.custom-control.custom-checkbox {
    padding-left: 0;
}

label.custom-control-label {
    position: relative;
    padding-right: 1.5rem;
}

label.custom-control-label::before,
label.custom-control-label::after {
    right: 3px;
    left: auto;
    margin-top: -3px;
}

.stepsLabel {
    font-size: 13px;
    cursor: pointer;
    div {
        margin: 3px;
        width: 85px;
        cursor: pointer;
        display: flex;
        align-items: center;
        .lableIcons {
            margin-left: 10px;
            margin-right: -17px;
        }
    }
    span {
        font-size: 14px;
        margin-left: 3px;
    }
    .exportText-span {
        display: inline-block;
        width: 70px;
    }
}

.stepsLabel > div {
    border: 1px solid $grey-enable;
    width: 135px;
    border-radius: 4px;
    background-color: #ffffff;
    color: $grey1;
}

.sentimentIcons {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.checkmark {
    width: 24px;
    height: 24px;
    border-radius: 2px;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.exportCheck input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.exportCheck .checkmark:after {
    position: relative;
    position: relative;
    left: 7px;
    top: 3px;
    width: 8px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkmark:after {
    position: relative;
    left: 7px;
    top: 2px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.tablePagination {
    float: left;
}

.user-app-data {
    display: flex;
    position: absolute;
    right: 15px;
}

.sNDashboardTitle-input {
    display: flex;
    align-items: center;

    .fa-pencil {
        display: none;
        margin-left: 5px;
    }

    &:hover {
        .fa-pencil {
            display: inline-block;
            cursor: pointer;
        }
    }
}

.dashboardDiv .sNDashbordLabelClass {
    background-color: transparent !important;
    border: 1px solid transparent !important;
    padding: 5px 10px 8px !important;
    height: 25px !important;
    outline: none !important;
    margin: 0px;
    cursor: pointer;
}

.dashboardDiv .sNDshbordInputClass {
    background-color: white !important;
    border: 1px solid $lightgray !important;
    padding: 5px 10px 8px !important;
    height: 25px !important;
    outline: none !important;
    line-height: 0;
}

.realtimeInfo {
    z-index: 9991;
    position: fixed;
    top: 132px;
    width: calc(100% - 280px);
    text-align: center;
    margin-left: 280px;

    .infoDiv{
        width: 50%;
        margin: 0 auto;
        background-color: #ffeaa5;
        z-index: 4;
        padding: 0px 7px;
        border: 1px solid #f2c534;
        font-size: 14px;
        color: #9a7600;
        border-radius: 4px;
        box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);

        .iconP{
            margin-bottom: 15px;
            text-align: end;
        }

        .infoP{
            margin-top: -10px;
            margin-bottom: 10px;
        }
    }
}


#left-panel {
    box-shadow: 0px 4px 4px 0px #2122263D!important;
    box-shadow: 0px 0px 4px 0px #2122261F!important;
    padding: 10px 0px;
}