@import "style_variables";

.limit-select {
    width: 72px;
    height: 40px;
    padding: 8px 12px 8px 10px;
    border-radius: 4px;
    border: 1px;

    font-size: 16px;
    border: 1px solid rgba(187, 187, 187, 1) !important;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #F5F5F5 100%) !important;
    color: #000000 !important;
}
.pagination-imgs {
    border: none;
    background-color: inherit;
    fill: #BBBBBB !important;
    path {
        fill: #BBBBBB !important;
    }
    svg{
        path{
            fill: #BBBBBB !important;
        }
    }
    &:disabled {
        svg {
            path{
                fill: #BBBBBB !important;
            }
        }
    }
}

.fill-red {
    fill: red !important;
}