@import 'style_variables';

#language-filter-dropdown {
    .source-but-state-filter {
        // position: absolute;
        // right: 0px;
        // top: 12px;
        // left: 600px;
        width: 100%;
        // position: absolute;
        height: 40px!important;
        font-size: 14px;
        color: #454560 !important;
        background-color: #ffffff !important;
        padding: 0px 16px;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        // border: 1px solid #ced4da!important;
        text-transform: capitalize;
    
        .switchApp-btn-icon {
            position: absolute;
            right: 10px;
            top: 13px;
        }
    
        span.caret {
            display: none;
        }

        .filterCase {
            text-transform: capitalize;
        }
    }
    
    .source-but-state-filter:hover {
        background-color: $loader-bg !important;
        cursor: pointer;
    }
    
    .source-but-state-filter:active {
        background-color: #ffffff !important;
        color: $filter-font !important;
    
    }
    
    .source-but-state-filter span {
        display: inline-block;
        // margin-left: 8px;
        text-align: left;
    }
    
    .dropdown-menu-state-filter {
        width: 152px !important;
        margin-top: 40px;
        left: 225px;
        // margin-left: 420px !important;
    }
    
    .dropdown-menu-item {
        border-radius: 4px;
        width: 100%;
        text-align: center;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 5px;
        margin-top: 5px;
        font-size: 14px;
        padding: 4px;
    }
    
    .source-icon {
        float: left;
        font-size: 20px!important;
    }
    
    .buttonPositionStateFilter {
        // position: fixed!important;
        // z-index: 999!important;
      }
    
    .source-menu-state-filter {
        text-align: left;
        // top: 53px!important;
        min-width: 150px;
        padding: 10px 20px;
        // right: 0px !important;
        // left: 600px !important;
        transform: none !important;
        position: fixed !important;
        width: 225px;
    }

    .guideAnalytics-source-menu-state-filter {
        top: 162px !important;
    }
    
    .source-but-state-filter-dashboard {
        // position: absolute;
        right: 294px;
        width: 168px;
        position: absolute;
        height: 40px;
        font-size: 14px;
        color: $filter-font !important;
        background-color: #ffffff !important;
        padding: 0.5rem 20px;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: 1px solid $filter-btn-border;
        position : static !important;
        z-index: 999!important;
    
        .switchApp-btn-icon {
            position: absolute;
            right: 10px;
            top: 13px;
        }
    
        span.caret {
            display: none;
        }
    }
    
    .source-but-state-filter-dashboard:hover {
        background-color: #ffffff !important;
        color: $filter-font !important;
        
    }
    
    .source-but-state-filter-dashboard:active {
        background-color: #ffffff !important;
        color: $filter-font !important;
    
    }
    
    .source-but-state-filter-dashboard span {
        display: inline-block;
        // margin-left: 8px;
        text-align: left;
    }
    
    .source-menu-state-filter-dashboard {
        text-align: left;
        // top: 120px !important;
        min-width: 150px;
        // padding: 0 !important;
        // position: absolute;
        // right: 294px;
        // left: auto !important;
        transform: none!important;
        position: static!important;
        width: 168px;
        z-index: 999!important;
        padding: 10px;
        // width: 148px;
    }

    .langFilterFont {
        color: $grey6 !important;
        font-size: 14px!important;
        font-weight: 400;
        margin-left: 4px;
    }

    .languageIcon{
        width: 16px;
        height: auto;
    }
}
