@import "style_variables";

.tooltips{

    .table tbody tr td:nth-child(1),
    .table th:nth-child(1) {
        width: 24%;
        .tableContent {
            // max-width:30%;
            // cursor: pointer !important;
        }
    }

    .table tbody tr td:nth-child(2),
    .table th:nth-child(2) {
        width: 12.5%;
    }

    .table tbody tr td:nth-child(3),
    .table th:nth-child(3) {
        width: 12.5%;
    }

    .table tbody tr td:nth-child(4),
    .table th:nth-child(4) {
        width: 20%;
    }
    .table tbody tr td:nth-child(5),
    .table th:nth-child(5) {
        width: 16%;
    }

    .table tbody tr td:nth-child(6),
    .table th:nth-child(6) {
        width: 15%;
    }

    .warningDiv {
        position: absolute;
        top: 50%;
        /* right: 50%; */
        transform: translateX(-50%);
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 10px 20px 0px 20px;
        background-color: #feeaa5;
        color: #9a7600;
        border-radius: 4px;
        font-size: 14px;

        .closeWarning {
            border: none;
            background-color: transparent;
            width: 20px;
            height: 20px
        }

        p {
            margin-bottom: 10px;
        }
    }
    
}