@import "style_variables";

.guideErrorsDetail {
    table {
        display: block;
    }

    table tbody tr td:nth-child(1),
    table th:nth-child(1) {
        width: 28%;
        .tableContent {
        }
    }



    table tbody tr td:nth-child(2),
    table th:nth-child(2) {
        width: 18%;
    }

    table tbody tr td:nth-child(3),
    table th:nth-child(3) {
        width: 18%;

        div {
            width: 100% !important;
            height: 95%;
            display: flex !important;
            /* display: contents; */
            align-items: center;
        }
    }
}

.guideErrorsDetailTable {
    .tableTooltip   > div {
        // height: 35px;
        // display: flex!important;
        // align-items: center;
    }
}