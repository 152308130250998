@import "style_env";


$black: #000000;
$white: #ffffff;
$error-red: #fa283d;

$lightgreen: #76be65;
$lightseagreen: #38b6a0;
$turquoise: #56e7ce;

$darkturquoise: #00a6d9;

$slateblue: #5b64dd;
$mediumpurple: #9476c9;
$mediumorchid: #b060e2;

$brightred: #f93850;
$salmon: #f1736a;
$lightpink: #ffaea9;

$yellowsandybrown: #facc41;
$orangesandybrown: #f79b42;

$skyblue: #7ebce6;
$lightgray: #e6e6e6;
$chart-wrapper-blue: #008dff;

$section-header: #ffffff;
// $table-header: #dfe0e7;
$table-header: #ffffff;

$red-active: #993b36;
$red-focus: #b3463f;
$red-hover: #d45c55;
$red-enable: #f1736a;


//sidenav colors
$activeGreenColor: #38b6a0;
$activeTextGreenColor: #a6ded4;
$greySideNavColor: #ffffff;
$activeMenu: #ffffff;
$activeSubMenu: #1e1f2f;
$activeSubMenu2: #1e1f2f;
$activeSubMenu2Grey: #0f0f17;
$activeSubMenuFont: #38b6a0;


$toggle-btn-active: #7a62ab;
$toggle-btn-inactive: #cfc1e7;


$tooltip-grey: #000;

$section-tooltip-bg:#cfd0dd;
$section-tooltip-border: #6f708b;

$fontgrey: $black;
$loader-bg: #f1f2f7;


$white-shade1:#fafafa;
$white-shade2: #eeeeee;


$ppt: #00a6d9;
$video: #f93850;
$gif: #9476c9;
$pdf: #76be65;
$docx: #facc41;
$text:#38b6a0;
$live: #5b64dd;
$html: #f79b42;
$grey: #454560;
$orangesandybrown: #f79b42;
$lightseagreen: #38b6a0;
$lightpink: #ffaea9;
$slateblue: #5b64dd;

$box: #ffaea9;
$box1: #5b64dd;

//neutral grey shades dark to light
//sidenav colors
$grey1: #383838; //242d32 212121 222
$grey-active: #454560; // 606060 4b4b4b #505050 495057
$grey-focus: #6f708b; //878787 9f9f9f 575869
$grey-hover: #8e8fa6; // 9a9aa7  8c8b8b 78909c 666
$grey-enable: #acadc1; //#c6c7cc e5e5e5 858a9e ced4da eaeaea bfc0c1
$grey6: #555555; // #ccd0d3 #ededed #e9ecef #e6e4e4 d3d3d3    

//purple
$purple1: #4a426b;
$purple6: #b49fda;
$purple-active: #594985;
$purple-focus: #695696;
$purple-hover: #7a62ab;
$purple-enable: #9476c9; //7a5faf


//primary green
$primaryColor1: #005FCC;
$primaryColor6: #005FCC;
$primaryColor-active: #005FCC;
$primaryColor-focus: #005FCC;
$primaryColor-hover: #005FCC;
$primaryColor-enable: #005FCC; //3fb5a0
$primaryTransparent: #ebf7f5;

//primary blue
$primaryBlue-color: #005BF0;


$auth-section-bg: #ebf7f5;
$secondaryColor: #9476c9;


$table-header: #ffffff;

// $table-header-font: #444444;
$table-header-font: #383838;
$table-lower-border: #f0f0f5;
$table-body-data: #555555;
$table-font-link: #005FCC;


$success: #76be65;
$unpublised: #d4d2d2;
$deleted: #ff8684;

$status-chip-font-color: #565656;
$table-row-even: rgba(235, 247, 245, 0.15);
$guide-status-chip: #273441;


$filter-btn-border: #ced4da;
$filter-font: #454560;
$priority-info: #9476c9; //7a5faf
$active-icons: $lightseagreen;

$export-btn: $primaryColor-active;
$export-btn-hover: $primaryColor-hover;


$customDashboard-saveBtn-border: #9c9db5;

$warning-text-font-color: #f79b42;


$color1: #118DA3;
$color2: #AA63D1; 
$color3: #318c00; 
$color4: #636ED1 ;


// @if $env == "assima" {

//     $black: #6f6d6d;
//     $white: #ffffff;
//     $error-red: #fa283d;
    
//     $lightgreen: #76be65;
//     $lightseagreen: #38b6a0;
//     $turquoise: #56e7ce;
//     $darkturquoise: #00a6d9;
//     $slateblue: #5b64dd;
//     $mediumpurple: #9476c9;
//     $mediumorchid: #b060e2;
//     $brightred: #f93850;
//     $salmon: #f1736a;
//     $lightpink: #ffaea9;
//     $yellowsandybrown: #facc41;
//     $orangesandybrown: #f79b42;
//     $skyblue: #7ebce6;
//     $lightgray: #e3ebf0;
//     $chart-wrapper-blue: #008dff;

//     $section-header: #e3ebf0;
//     // $table-header: #dfe0e7;
//     $table-header: #ffffff;

//     $red-active: #993b36;
//     $red-focus: #b3463f;
//     $red-hover: #d45c55;
//     $red-enable: #f1736a;

    
//     //sidenav colors
//     $activeGreenColor: #38b6a0;
//     $activeTextGreenColor: #a6ded4;
//     $greySideNavColor: #ffffff;
//     $activeMenu: #585858;
//     $activeSubMenu: #626262;
//     $activeSubMenu2Grey: #0267c1;
//     $activeSubMenuFont: white;
        
//     $toggle-btn-active: #31a579;
//     $toggle-btn-inactive: #70c8a6;
        
//     $tooltip-grey: #000;
    
//     $section-tooltip-bg:#cfd0dd;
//     $section-tooltip-border: #484848;
    
//     $fontgrey: $black;
//     $loader-bg: #f1f2f7;

        
//     $white-shade1:#fafafa;
//     $white-shade2: #eeeeee;

    
//     $ppt: #00a6d9;
//     $video: #f93850;
//     $gif: #9476c9;
//     $pdf: #76be65;
//     $docx: #facc41;
//     $html: #f79b42;
//     $text:#38b6a0;
//     $live: #5b64dd;
//     $grey: #454560;
//     $orangesandybrown: #f79b42;
//     $lightseagreen: #38b6a0;
//     $lightpink: #ffaea9;
//     $slateblue: #5b64dd;

//     $box: #ffaea9;
//     $box1: #5b64dd;

//     //neutral grey shades dark to light
//     //sidenav colors
//     $grey1: #26273b; //242d32 212121 222
//     $grey-active: #454560; // 606060 4b4b4b #505050 495057
//     $grey-focus: #626262; //878787 9f9f9f 575869
//     $grey-hover: #8e8fa6; // 9a9aa7  8c8b8b 78909c 666
//     $grey-enable: #acadc1; //#c6c7cc e5e5e5 858a9e ced4da eaeaea bfc0c1
//     $grey6: #cfd0dd; // #ccd0d3 #ededed #e9ecef #e6e4e4 d3d3d3    

//     //purple
//     $purple1: #4a426b;
//     $purple6: #b49fda;
//     $purple-active: #594985;
//     $purple-focus: #695696;
//     $purple-hover: #7a62ab;
//     $purple-enable: #9476c9; //7a5faf


//     //primary green
//     $primaryColor1: #1d5349;
//     $primaryColor6: #6bc9b8;
//     $primaryColor-active: #0267c1;
//     $primaryColor-focus: #1890e2;
//     $primaryColor-hover: #1890e2;
//     $primaryColor-enable: #0267c1;
//     $primaryTransparent: #e3ebf0;

//     $table-header: #ffffff;
    
//     // $table-header-font: #444444;
//     $table-header-font: #484848;
//     $table-lower-border: #f0f0f5;
//     $table-body-data: #454560;
//     $table-font-link: #0267c1;

//     $auth-section-bg: #eaf1f699;

//     $secondaryColor: #31a579;

//     $success: #31a579;
//     $unpublised: #626262;
//     $deleted: #e75736;

//     $status-chip-font-color: white;
//     $table-row-even: #d8e8f826;
//     $guide-status-chip: white;

//     $filter-btn-border: $table-header-font;
//     $filter-font: $table-header-font;
//     $priority-info: #b7bede; //7a5faf
//     $active-icons: white;
    
//     $export-btn: $primaryColor-active;
//     $export-btn-hover: $primaryColor-hover;
    
// }