@import "style_variables";

.exportToPdf{
    // display: flex;
    // align-items: center;
    // padding: 5px 10px;
    // background-color: transparent;
    border: none!important;
    // font-size: 13px;
    // min-height: 35px;
    // max-height: 35px;
    // color: $grey-active;
    // border: 1px solid $grey-active;
    // border-radius: 4px;
    background-color: $white!important;
    padding: 0px!important;
    // float: right;
    // position: relative;
    // right: 0;
    // width: 135px !important;
    // margin-top: 0px;
    // line-height: 1;
    // cursor: pointer;
    // top: 10px;
    color: #383838!important;
    text-align: left;
    width: 100%;
    img {
        // margin-right: 8px;
    }

    &:hover {
        border: none;
        background-color: $white;
    }
}