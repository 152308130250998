@import "style_variables";

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    font-size: 16px;
}
.pagination > .pagination__link--active > a {
    background-color: #ffffff;
    border-color: $primaryColor-active;
    color: $primaryColor-active;
}
.pagination > li > a {
    border: 1px solid #ddd;
    margin: 0px 2px;
    color: $table-header-font;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
}

.pagination > .pagination__link--active > a,
.pagination > .pagination__link--active > span,
.pagination > .pagination__link--active > a:hover,
.pagination > .pagination__link--active > span:hover,
.pagination > .pagination__link--active > a:focus,
.pagination > .pagination__link--active > span:focus,
.pagination > .li > a,
.pagination > .li > span,
.pagination > .li > a:hover,
.pagination > .li > span:hover,
.pagination > .li > a:focus,
.pagination > .li > span:focus {
    background-color: #ffffff;
    border: 2px solid $primaryColor-active;
    color: $primaryColor-active;
    outline: none;
}
.pagination > li > a,
.pagination > li > span {
    color: $table-header-font;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-radius: 4px;
}

.paginationContainer {
    float: right;
}

.chartTables {
    .table tbody tr td:nth-child(1),
    .table th:nth-child(1) {
        width: 40%;
        .tableContent {
            // max-width:35%;
            // cursor: pointer !important;
        }
    }
}



.chartTables {
    .table thead tr, table thead, table tr{
        width: 100%;
        display: inline-table;
    }
    .table tr td:nth-child(1),
    .table tr th:nth-child(1) {
        width: 40%;
    }
    
    .table tr td:nth-child(2),
    .table th th:nth-child(2) {
        width: 30%;
    }
    
    .table tr td:nth-child(3),
    .table tr th:nth-child(3) {
        width: 30%;
    }
}

