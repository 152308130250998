@import "style_variables";

.controlCenterComponent {
    padding: 98px 24px !important;

    .controlCenterDiv {
        display: flex;
        flex-direction: column;
        gap: 16px !important;
        box-sizing: border-box;
        background-color: #ffffff !important;
        padding: 16px !important;
        box-shadow: 0px 2px 2px 0px #2122263D;
        box-shadow: 0px 0px 2px 0px #0000001F;
        border-radius: 2px;

        .header {
            font-family: "Lato";
            font-size: 20px;
            font-weight: 900;
            line-height: 27px;
            text-align: left;
            color: #383838;
        }

        .content-container {
            .tab-data-container {
                margin-top: 16px;
                .settingRow {
                    padding: 8px 16px;
                    gap: 8px;
    
                    .title {
                        color: #555555;
                        font-size: 14px;
                        font-weight: 400;
                    }
    
                    .detail {
                        font-size: 12px;
                        font-weight: 400;
                        color: #707070;
                    }
                }
            }

            .tab-content {
                min-height: fit-content;
            }
        }
    }
}