// .dropdown {
//     height: 50px;
//     width: 100px;
// }
@import "style_variables";


.searchForm{
    border: 1px dotted $grey;
    // padding-right:10px;
    border-radius: 4px;

}
.searchForm:hover{
    border: 1px dotted $grey;
    cursor: pointer;

    // padding-right:10px;
}

.searchDiv{
    margin-right: 10px;
}