@import "style_variables";

.textMiddle {
    position: sticky;
    vertical-align: middle;
    top: 50%;
}

.loaderDiv-height {
    min-height: 320px;
}

.noDataText-fontCommonTable {
    line-height: 3;
    text-align: center;
    font-size: 13px !important;
    font-weight: 600 !important;
    color: $fontgrey !important;
    background-color: $white !important;

    td {
        padding-top: 20px !important;
    }
}

// .paginationPosition {
//   position: absolute;
//   bottom: -180px;
//   right: 20px;
//   left: 20px;
// }

.btn-export {
    margin-top: 2px;
    border: 1px solid #ced4da !important;
}

.disabledNextBtn {
    color: #777777;
    background-color: #fff;
    border-color: #ddd;
}

.color_table thead th {
    /*background-color: white;*/
    /*border-bottom-color: white;*/
    // background-color:$tableHeader-purple;
    // color: white;
}

.tabSwitch-btn1 {
    font-size: 14px;
    // color: #42427d;
    border: none;
    // background-color: #ecedf3;
    padding: 0.5rem 1.8rem;
    border-radius: 4px;
}

.btnActive {
    color: white;
    border-color: #42427d;
    background-color: #42427d;
}

.color_table .tooltip-inner {
    background-color: lightgrey !important;
    text-align: left !important;
    opacity: 1 !important;
    min-width: 0px;
    padding: 15px;
}

.table-responsive {
    display: contents;
}

.table thead th {
    vertical-align: middle !important;
}

.createEdit-form {
    // padding: 20px;
    border-radius: 4px;
    // border: 1px solid #d7d8dc;
    padding-bottom: 50px;
    background-color: white;
    min-height: 273px;
    box-sizing: content-box;
}

.ratioBar {
    width: 80%;
    justify-content: space-between;
    display: flex;
    align-items: center;

    .ratioBarDivisions {
        height: 10px;
        margin-top: 8px;
        display: inline-block;
        background-color: $lightgray;
    }
}

.rowHeight {
    // height: 48px;
}

.table td,
.table th,
tr {
    // padding: 8px;
    padding: 12px 16px;
    // max-height: 48px !important;
}

.tableContent {
    position: relative;
    top: 4px;
}

.ellipsistext {
    // width: 160px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: inline-block;

    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    display: block !important;
    width: fit-content !important;

    // cursor: pointer;
}

.ellipsistext250Width {

    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    display: block !important;
    width: 200px !important;
}

.ellipsistext-expandable {
    // width: 160px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: inline-block;

    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    display: block !important;
    width: fit-content !important;
    // cursor: pointer;
}

.stepDeleted {
    margin-top: -15px;
}

.catMargin {
    margin-left: 21px;
    margin-top: 8px;
}

.ratio-color {
    text-transform: capitalize;
    font-size: 13px;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    border: 1px solid $grey;
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    font-size: 16px;
}

.pagination>.active>a {
    background-color: #ffffff;
    border-color: $primaryColor-active;
    color: $primaryColor-active;
}

.pagination>li>a {
    border: 1px solid #ddd;
    margin: 0px 2px;
    color: $table-header-font;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus,
.pagination>.li>a,
.pagination>.li>span,
.pagination>.li>a:hover,
.pagination>.li>span:hover,
.pagination>.li>a:focus,
.pagination>.li>span:focus {
    background-color: #ffffff;
    border: 2px solid $primaryColor-active;
    color: $primaryColor-active;
    outline: none;
}

.pagination>li>a,
.pagination>li>span {
    color: $table-header-font;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: 4px;
}

.paginationContainer {
    float: right;
}

.countContainer {
    font-size: 13px;
    margin: 25px 0px;
    display: inline-block;
}

.loaderContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-grow: 1;
    width: 285%;
}

.commonTable {
    display: inline-grid;

    .noDataText-font td:nth-child(1) {
        width: 100vw;

        .tableContent {
            max-width: 100vw;
            // cursor: default !important;
        }
    }

    tbody {
        display: block;
    }

    .checkmark-tooltip {
        position: absolute;
        top: 0px;
        left: 5px;
        height: 18px;
        width: 18px;
        border: 1px solid #cfd0dd;
        background-color: #ffffff !important;
        border-radius: 2px;
        cursor: pointer;
    }

    .padding-chk-0 {
        padding: 0 !important;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark-tooltip:after {
        content: "";
        position: absolute;
        display: none;
    }


    .check-container input:checked~.checkmark {
        background-color: $primaryColor-active !important;
        border: 1px solid $primaryColor-active;
    }
    
    /* Show the checkmark when checked */
    .check-container input:checked~.checkmark-tooltip:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .check-container .checkmark-tooltip:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        visibility: visible;
    }

    .check-container input:disabled~.checkmark-tooltip:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid transparent;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        visibility: visible;
    }
}

.showCursor {
    cursor: pointer !important;
    color: $table-font-link !important;
}

.hideCursor {
    cursor: default !important;
}

.linkedTable {
    border: none;
    box-shadow: none;
}

.btns-div {
    height: 200px;
    margin-top: 20px;
}

.excelText {
    width: 78%;
    text-align: center;
}

.commonSummaryTable {
    table> :not(:first-child) {
        border-top: none !important;
    }
}

// tr td{
//     max-width: 10px;
//     white-space: nowrap;
//     overflow: hidden;
//     transition: 1s;
//   }

//   tr:hover *td.expandCell_1{
//     // max-width: 100px;
//     // min-width: 100px;
//     background-color: red;
//   }

// .commonTable:hover .expandCell_1 {
//     background-color: green!important;
// }

.expandCell_1,
.expandCell_2,
.expandCell_3,
.expandCell_4,
.expandCell_5,
.expandCell_6 {
    padding: 8px !important;
}

.cellExpand {
    // transition: 1s;
    // width: 300px!important;
}

.excel-icon-hide {
    transition: 1s;
    display: none;
}

.column-title {
    transition: 1s;
    display: none
}

.column-title-show {
    transition: 1s;
    display: block;
}

.resultStatusBtn {
    border-radius: 4px;
    color: #555555;
    font-size: 13px;
    width: auto;
    text-align: center;
    align-items: center;
    margin-left: 10px;
    border: 0px;
    z-index: 0;
    background-color: #ffffff;
    height: 30px;
    border-radius: 4px;
    width: 30px;
    margin-top: 0px;
    border: 1px solid #555555;
    height: 25px;


    &:hover {
        background-color: $primaryColor-hover;
        color: #ffffff;
    }
}

.result-status-container {
    position: absolute;
    right: 0px !important;
    left: 20px;
    top: 0px;
    display: flex;
    flex-direction: row;
}

.positionSticky {
    position: sticky;
    left: 0px
}

.thTitle-span {
    display: flex;
    width: 100%;
    justify-content: space-between;
}


.progress-container {
    width: 100%;
    background-color: #00000014;
    // border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    height: 10px;
    // margin-right: 10px;
  }
  

  .progress-bar {
    height: 100%;
    background-color: $primaryColor-active;
    transition: width 0.4s ease;
  }

  .extraGoalPercentage {
    background-color: $color2;
    height: 20px;
    display: flex;
    width: 60px;
    border-radius: 4px;
    color: white;
    justify-content: center;
    font-size: 12px;
    /* vertical-align: middle; */
    margin-bottom: 5px;
    /* padding-top: 2px; */
    line-height: 1.5;
    font-weight: 700;
  }