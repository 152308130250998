.featureDetailSection {

    .featureDetailStatsSection {
        .statsTiles .firstSection {
            height: 100% !important;
            padding: 0;
            display: flex !important;
            background-color: #ffffff;
            flex-direction: column;
        }
            
    }

    .statCards {
        .userEngagementSummaryTable{
            .table tbody tr td:nth-child(1) {
                width: 30%;
                    .tableContent {
                        max-width: 420px;
                    }
                }
                .table tbody tr td:nth-child(2) {
                    width: 40%;
                }
            
                .table tbody tr td:nth-child(3) {
                    width: 30%;
                }
            
                .table th:nth-child(1) {
                    width: 30%;
                }
            
                .table th:nth-child(2) {
                    width: 40%;
                }
            
                .table th:nth-child(3) {
                    width: 30%;
                }
        }
    
        table {
            width: 100% !important;
        }
        .stats-refreshIcon {
            margin-top: 4px !important;
            margin-right: 13px !important;
        }
    }
}

.userEngagement-modal{
    modal-body{
        padding: 16px !important;
    }
}