.chatBotStatsTable {
    .table tbody tr td:nth-child(1) {
        width: 40%;
        .tableContent {
            // max-width: 420px;
            // cursor: pointer !important;
        }
    }

    .table tbody tr td:nth-child(2) {
        width: 20%;
    }

    .table tbody tr td:nth-child(3) {
        width: 20%;
    }

    .table tbody tr td:nth-child(4) {
        width: 20%;
    }


    .table th:nth-child(1) {
        width: 40%;
    }

    .table th:nth-child(2) {
        width: 20%;
    }

    .table th:nth-child(3) {
        width: 20%;
    }

    .table th:nth-child(4) {
        width: 20%;
    }

}