@import "style_variables";

.maintenanceMain-div {
    position: absolute;
    top: -11px;
    width: calc(100% + 240px);
    left: -240px;
    z-index: 9999;
    background-color: $loader-bg;
    height: 100vh;
}
