@import "style_variables";



.css-yk16xz-control:focus{
    border-color : #38b6a0!important
}

.css-1pahdxg-control:hover {
    border-color : #38b6a0!important
}

#userEngagementDetailSection2 {
    .borderBottom {
        border-bottom: 1px solid $lightgray;
    }
    
    .borderLR{
        border-left: 1px solid $lightgray;
        border-right: 1px solid $lightgray;
    }
}


.userEngegment-icon path {
    fill: $table-header-font!important;
}

.userEngagementDetailSection {
    .table tbody tr td:nth-child(1),
    .table th:nth-child(1) {
        width: 25%;
    }

    .table tbody tr td:nth-child(2),
    .table th:nth-child(2) {
        width: 15%;
    }

    .table tbody tr td:nth-child(3),
    .table th:nth-child(3) {
        width: 15%;
    }

    .table tbody tr td:nth-child(4),
    .table th:nth-child(4) {
        width: 15%;
    }
    .table tbody tr td:nth-child(5),
    .table th:nth-child(5) {
        width: 15%;
    }

    .table tbody tr td:nth-child(6),
    .table th:nth-child(6) {
        width: 15%;
    }
}