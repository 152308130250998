@import 'style_variables';


.envfilterTitle {
    width: 168px;
    // position: absolute;
    font-size: 14px;
    color:#707070;
    font-weight: 700;

    top: 0px;
    z-index: 1;
    /* left: 46px; */
}

.guideAnalytics-envfilterTitle {
    @extend .envfilterTitle;
    right: 750px
}

.guideAnalyticsDetail-envfilterTitle {
    @extend .envfilterTitle;
    right:  196px;
}

.searches-envfilterTitle {
    @extend .envfilterTitle;
    right:  382px;
}


.userEnagagement-envfilterTitle {
    @extend .envfilterTitle;
    right:  772px;
}


#environment-filter-dropdown {
    .envBtn {
        display: flex;
        justify-content: space-between;
    }

    
    .source-but-state-filter:hover {
        background-color: #ffffff !important;
        color: $filter-font !important;
        
    }
    
    .source-but-state-filter:active {
        background-color: #ffffff !important;
        color: $filter-font !important;
    
    }
    
    .source-but-state-filter span {
        display: inline-block;
        // margin-left: 8px;
        text-align: left;
    }
    
    .dropdown-menu-state-filter {
        width: 152px !important;
        margin-top: 40px;
        left: 225px;
        // margin-left: 420px !important;
    }
    
    .dropdown-menu-item {
        border-radius: 4px;
        width: 100%;
        text-align: center;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 5px;
        margin-top: 5px;
        font-size: 14px;
        padding: 4px;
    }
    
    .source-icon {
        float: left;
        font-size: 20px!important;
    }
    
    .buttonPositionStateFilter {
        // position: fixed!important;
        z-index: 999!important;
      }
    
    .source-menu-state-filter {
        text-align: left;
        top: 63px !important;
        min-width: 180px;
        padding: 10px 20px;
        left: auto !important;
        transform: none !important;
        /* width: 168px; */
        z-index: 9999999;
        position: static!important;
      }


    .guideAnalytics-envfilterDropdown {
        @extend .source-menu-state-filter;
        right: 739px !important;
    }
    
    .guideAnalyticsDetail-envfilterDropdown {
        @extend .source-menu-state-filter;
        right: 757px !important;
    }
    
    .searches-envfilterDropdown {
        @extend .source-menu-state-filter;
        right:  444px !important;;
    }

      .source-but-state-filter {
        width: 180px;
        // position: absolute;
        height: 40px;
        font-size: 16px;

        padding: 0.5rem 15px;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
  
        text-transform: capitalize;
        text-align: left;
        top: 21px;


        color: #000 !important;
        border-radius: 4px;
        cursor: hand;
        border: 1px solid #BBBBBB !important;
        text-align: left;
        overflow: hidden;
        background: linear-gradient(180deg, #ffffff80, #f5f5f5) !important;
    
        &:focus{
            border: 2px solid #005FCC !important;
        }
    
        &:hover{
            border: 1px solid #BBBBBB !important;
        }

        &:disabled {
            background: #f5f5f5 !important;
            color: #bbbbbb !important;
            border: #e6e6e6 !important;
            opacity: 1 !important;
        }
    
        .switchApp-btn-icon {
            position: absolute;
            right: 10px;
            top: 13px;
        }
    
        span.caret {
            display: none;
        }
    }

    .guideAnalytics {
        @extend .source-but-state-filter;
        right: 740px;
    }

    .guideAnalyticsDetail {
        @extend .source-but-state-filter;
        right: 185px;
    }

    .searches {
        @extend .source-but-state-filter;
        right: 370px;
    }
    
    
    //   .source-but-state-filter-detail {
        // // position: absolute;
        // right: 276px;
        // top: 21px;
        // width: 168px;
        // position: absolute !important;
        // height: 40px;
        // font-size: 14px;
        // color: #454560 !important;
        // background-color: #ffffff !important;
        // padding: 0.5rem 20px;
        // border-radius: 4px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        // border: 1px solid #ced4da;
        // z-index: 999 !important;
        // text-align: left;
    
        // .switchApp-btn-icon {
        //     position: fixed;
        //     right: 10px;
        //     top: 13px;
        // }
    
        // span.caret {
        //     display: none;
        // }
    
    
    .source-but-state-filter-dashboard:hover {
        background-color: #ffffff !important;
        color: $filter-font !important;
        
    }
    
    .source-but-state-filter-dashboard:active {
        background-color: #ffffff !important;
        color: $filter-font !important;
    
    }
    
    .source-but-state-filter-dashboard span {
        display: inline-block;
        // margin-left: 8px;
        text-align: left;
    }
    
    .source-menu-state-filter-dashboard {
        text-align: left;
        // top: 120px !important;
        min-width: 150px;
        // padding: 0 !important;
        // position: absolute;
        // right: 294px;
        // left: auto !important;
        transform: none!important;
        position: static!important;
        width: 168px;
        z-index: 999!important
        // width: 148px;
      }
}
