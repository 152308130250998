@import "style_variables.scss";
.searchSection {

    .table tbody tr td:nth-child(1) {
        width: 40%;
        .tableContent{
            max-width:420px;
            // cursor: pointer !important;
        }
    }

    .table tbody tr td:nth-child(2) {
        width: 20%;
    }

    .table tbody tr td:nth-child(3) {
        width: 20%;
    }

    .table tbody tr td:nth-child(4) {
        width: 20%;
    }


    .table th:nth-child(1) {
        width: 40%;
    }

    .table th:nth-child(2) {
        width: 20%;
    }

    .table th:nth-child(3) {
        width: 20%;
    }

    .table th:nth-child(4) {
        width: 20%;
    }


    .pagesTitle-icon path {
        fill: $grey;
    }

    .heigth100 {
        height: 100px;
    }
    .width250 {
        width: 250px;
    }
    .marginT20 {
        margin-top: 20px;
    }

    .marginB50 {
        margin-bottom: 50px;
    }
    .font_28 {
        font-size: 28px;
    }

    .datePickerTop {
        top: 78px !important;
    }

    .borderBottom_0 {
        border-bottom: 5px solid #60a550;
    }

    .borderBottom_1 {
        border-bottom: 5px solid #5b64dd;
    }

    .borderBottom_2 {
        border-bottom: 5px solid #b060e2;
    }
    .stat-digit {
        text-align: center;
        display: block;
    }

    .color_table tr {
        height: 40px;
        box-sizing: content-box;
    }

    // .divOverflow{
    //     // min-width:1000px;
    //     // overflow-x: auto;
    // }

    .color_table thead th {
        // background-color:$tableHeader-purple;
        //border-bottom-color: white;
        // color: white;
    }

    // .paginationPosition-search{
    //     bottom: 10px;
    //     right: 20px;
    //     left: 20px;
    // }

    .tooltip .show {
        background-color: transparent;
    }

    #bubbleChart {
        display: flex;
    }

    .filters-div {
        padding-left: 19px !important;
        margin-bottom: -34px;
        margin-top: -20px;
    }

    .statsTiles-loaderDiv {
        min-height: 111px;
        border-radius: 8px;
        height: 100px;
        background-color: white;
        border: 1px solid #e0e0e0;
        margin-bottom: 1.5rem;

        .imgDiv {
            position: absolute;
            top: 43%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .export-div {
        float: right;
        margin-top: -33px;

        .exportPdf-btn {
            // margin-top: -179px;
            // margin-right: 276px;
        }
    }

    .headingText {
        height: 40px;
    }

    //   .rangeBox {
    //         position: absolute;
    //         bottom: 10px;
    //     }

    .rangeBox {
                // margin-top: 30px;

                .keyword-range {
                    display: inline-block;
                    width: 55px;
                    height: 16px;
                }

                .fontRange {
                    font-size: 9pt;
                    font-family: Consolas, courier;
                    fill: #aaa;
                    //text-align: right;
                }
            }
    .section-wrapper {
        .panelHeading {
            padding: 20px 20px 0px;

            .info-icon-color {
                margin-left: 10px;
                font-size: 15px;
            }
        }

        // .cloudChart-div {
        //     height: 450px;

            
        // }

      
    }

    .tileBoxes {
        margin-bottom: 1.5rem;
        padding: 0 10px;
    }

    .searchTile-tooltip {
        position: absolute;
        right: 10px;
        top: 5px;
    }

    @media screen and (min-width: 1250px) {
        table {
            width: 100% !important;
        }
        table th:nth-child(1) {
            width: 60%;
        }
        table th:nth-child(2) {
            width: 20%;
        }
        table th:nth-child(3) {
            width: 20%;
        }
    }
    @media screen and (max-width: 1249px) {
        table th:nth-child(1) {
            width: 60%;
        }
        table th:nth-child(2) {
            width: 20%;
        }
        table th:nth-child(3) {
            width: 20%;
        }
    }

    .card-header {
        height: unset !important;
    }

    .exportPdf-btn {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 5px 10px;
        // background-color: transparent;
        // border: none;
        font-size: 13px;
        min-height: 35px;
        max-height: 35px;
        // color: white;
        // border: 1px solid #48455d;
        // border-radius: 6px;
        // background-color: #585869;
        float: right;
        margin-right: 0px;
        top: 92px;
        position: relative;
        cursor: pointer;
    }

    // .eventSource-div {
    //     position: absolute;
    //     right: 478px;
    //     top: -12px;
    // }

    .searchTiles-margin {
        margin-left: -30px;
        margin-right: -30px;
    }

    #cloudChart {
        width: 800px;
    }

    .tiles-border {
        border: 1px solid $lightgray;
        /* background-color: #a2a0a0; */
        background-color: $white;
        border-radius: 10px;
        /* box-shadow: 0 0 10px #bfbfbf;*/
    }

    .borderBottom_0 {
        border-bottom: 5px solid #60a550;
    }

    .borderBottom_1 {
        border-bottom: 5px solid #5b64dd;
    }

    .borderBottom_2 {
        border-bottom: 5px solid #b060e2;
    }

    .statColor_0 {
        color: #60a550;
    }

    .statColor_1 {
        color: #5b64dd;
    }

    .statColor_2 {
        color: #b060e2;
    }
}
