@import "style_variables";

// ngb-tabset ul.nav-tabs li a.nav-link.active {
//     color:$primaryColor-enable;
//     background-color: $white;
//   }

//   ngb-tabset ul.nav-tabs li a.nav-link {
//     color:$table-header-font;
//     padding: 0px;
//   }

.exportModal {
    .or-div {
        top: calc(50% - 20px);
    }
    .text {
        /* right: 108px; */
        top: -30px;
    }
    .vl {
        height: 85px;
        margin-top: 0px;
    }
    #exportExcel_modal {
        .excelText {
            // width: 70%;
        }
    }
}

.datepiecker-div-modal {
    z-index: 40;
    display: flex;
    justify-content: center;
    position: static !important;
}

.dateRange-span-EE {
}

.datePickerContainer {
    min-width: 280px;
    justify-content: center;
}

.exportText-span {
    display: inline-block;
    width: 70px;
    padding-left: 5px;
}

.StepsLabel {
    border: 1px solid #acadc1;
    width: 160px;
    border-radius: 4px;
    background-color: #fff;
    color: #26273b;
    margin: auto;
    // cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 10px;

    .exportText-span {
        display: inline-block;
        width: 70px;
        padding-left: 5px;
    }
}

.div-spans {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
    max-width: none;
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

.btn-export-modal {
    margin-top: 25px !important;
}

.lableIcons {
    margin-left: 0px;
    margin-right: -17px;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: $primaryColor-enable;
    cursor: default;
    background-color: #fff;
    border-bottom-color: #fff;
    width: 100%;
    display: block;
    font-size: 14px !important;
    font-weight: 600;
}
.nav-tabs > li.active {
    border-bottom: 0px;
}

.nav-tabs > li > a {
    color: #26273b;
    padding: 8px 16px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    width: 100%;
    display: block;
    font-size: 14px !important;
    font-weight: 600;
    margin-right: 2px;
    line-height: 1.42857143;
    // border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}

.checkmark-export {
    position: relative;
    top: 0px;
    right: -6px;
}

.labelPM {
    margin: 4.5px !important;
    padding-left: 20px !important;
}
.tab-content {
    min-height: 300px;
}

#controlled-tab-example-pane-2 {
    min-height:450px
}


.excelExport {
    .color_table tr:nth-child(odd) {
        // background-color: $white;
    }

    .color_table tr:nth-child(even) {
        background-color: $table-row-even;
    }

    .color_table tr:nth-child(1) {
        // background-color: $white;
    }

    .color_table tr:nth-child(0) {
        // background-color: $white;
    }

    .color_table tr {
        height: 45px !important;
    }

    /* To display the block as level element */
    table.scrolldown tbody,
    table.scrolldown thead {
        display: block;
    }

    table.scrolldown {
        width: 100% !important;
    }

    table.scrolldown tr {
        width: 100% !important;
        display: block;
    }

    table.scrolldown th:nth-child(1),
    table.scrolldown td:nth-child(1) {
        width: 40%;
        display: inline-block;
    }
    table.scrolldown th:nth-child(2),
    table.scrolldown td:nth-child(2) {
        width: 30%;
        display: inline-block;
    }
    table.scrolldown th:nth-child(3),
    table.scrolldown td:nth-child(3) {
        width: 30%;
        display: inline-block;
    }

    table.scrolldown tbody {
        /* Set the height of table body */
        height: 300px;

        /* Set vertical scroll */
        overflow-y: auto;

        /* Hide the horizontal scroll */
        overflow-x: hidden;
    }

    table {
        height: 347px !important;
    }

    .entries-dropdown {
        background-color: #ffffff;
        color: #26273b;
        border: 1px solid #cfd0dd;
        height: 30px;
        width: 120px;
    }

    .errorMsgDiv{
        position: absolute;
        width: 100%;
        top: -30px;
        display: flex;
        .errorMsg {
                margin-bottom: 8px;
                height: auto;
                padding: 4px;
                border: 1px solid $html;
                border-radius: 4px;
                background-color: $white-shade2;
                background-size: cover;
                font-family: 'Lato', sans-serif;
                font-size: 12px;
                color: $html;
                text-decoration: none solid rgb(247, 155, 66);
                text-align: center;
                width: 90%;
                margin: 0 auto;
            }
    }

 
   
}

.exportDatepicker {
    .selectCustomColor {
        position: absolute;
        left: 290px;
        top: 14px;
    }

    #modalDropdownButton {
        position: absolute;
        left: 622px;
        top: 44px;
    }

    .buttonPositionEventSource {
        position: absolute !important;
        right: 0px !important;
        left: 108px !important;
        top: 4px !important;
    }

    .source-menu-event-source {
        text-align: left;
        top: 46px !important;
        min-width: 150px;
        right: 0px !important;
        left: 108px !important;
        transform: none !important;
        position: absolute !important;
        width: 200px;
    }
}

.refreshIconDiv {
    .refreshIcon {
        position: absolute;
        right: 0px;
        z-index: 9;
    }
}
.exportPending {
    font-weight: 600;
    color: #ffffff;
    background-color: $darkturquoise;
    padding: 2px 15px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 600;
}
.exportProcess {
    font-weight: 600;
    color: #ffffff;
    background-color: $orangesandybrown;
    padding: 2px 15px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 600;
}
.exportComplete {
    font-weight: 600;
    color: #ffffff;
    background-color: $lightgreen;
    padding: 2px 15px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 600;
}
.exportFail {
    font-weight: 600;
    color: #ffffff;
    background-color: $brightred;
    padding: 2px 15px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 600;
}

.exportNoData {
    font-weight: 600;
    color: #ffffff;
    background-color: $yellowsandybrown;
    padding: 2px 15px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 600;
}
