.openModal-div {
    overflow: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
    width: 200px;
    float: right;
    .openModal-btn {
        float: right;
        border-radius: 8px;
        font-size: 13px;
        padding: 8px 10px;
        color: white;
        text-align: left;
    }
}

.funnelBorder-div {
    width: 92%;
    overflow: hidden;
    height: 100%;
    display: flex;
}

.funnelBorder {
    border: 1px dashed lightgray;
    border-radius: 9px;
    padding: 30px;
    margin-bottom: 30px;
}
.create-funnelDiv {
    text-align: center;
    width: 50%;
    position: relative;
    padding: 10px;
    border: 1px dashed lightgrey;
    border-radius: 4px;
    top: 30%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    height: 50px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    z-index: 1;
    line-height: 2;
}

.stepsForm-div {
    padding: 20px 20px 0px 20px;
    margin-bottom: 0px;

    // button[disabled] {
    // background-color: lightgrey;
    // border: 1px solid lightgrey;
    // }

    label {
        margin: 0px;
        width: 100%;

        .checkmark {
            top: 2px;
            margin-bottom: 0px;
            right:0px!important;
        }
    }

    .select-stepBox {
        width: 100%;
        // display: inline-table;

        .check-container .step-span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 0px;
        }

        .stepsBox {
            height: 265px;
            width: 100%;
            // overflow-y: auto;
            margin-right: -15px;
            padding-bottom: 20px;
            min-height: 200px;
        }

        .steps-innerBox {
            width: 100%;
            background-color: #f7f7f7;
            border: 1px solid #f3f0f0;
            padding: 20px;
            border-radius: 4px;
            height: calc(100% - 40px);
            overflow-y: auto;
            max-height: 324px;

            .stpesLabel {
                display: flex;
                align-items: center;
                font-size: 13px;
                input {
                    margin-right: -18px;
                }
                &:hover {
                    cursor: pointer;
                }
            }

            .stpesLabel {
                padding: 5px 10px;
                border: 1px solid #cecdcd;
                border-radius: 4px;
                margin-bottom: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                // display: block;
                white-space: nowrap;
                &:nth-child(odd) {
                    background: #e6e6e6;
                }
                &:nth-child(even) {
                    background: white;
                }
            }
        }

        .stepsBox,
        .selected-steps {
            height: 360px;
            // overflow-y: auto;
            margin-right: 0px;
            margin-left: 0px;
            min-height: 200px;
            background-color: #ffffff;
            padding: 0px;
            /* border: 1px solid #f3f0f0; */
            display: block;
            width: 250px;
            min-width: 250px;
            max-width: 250px;


            .guideSteps-text {
                font-size: 14px;
                height: 20px;
                margin-bottom: 10px;
                font-weight: 600;
            }
        }

        .steps-selectedOptns {
            font-size: 13px;
            list-style-type: none;
        }

        .partition {
            height: 360px;
            display: flex;

            i {
                position: relative;
                font-size: 30px;
                font-weight: 600;
                height: 32px;
                margin: auto;
            }
        }
    }

    .checkBox-errorMsg {
        font-size: 12px;
        color: #808080;
        height: 20px;
        margin-top: 10px;
    }

    .createFunnel-btn {
        position: absolute;
        right: 155px;
    }

    .checkBox-errorMsg {
        font-size: 12px;
        color: #808080;
        height: 20px;
    }

    .stepsBox {
        height: 300px;
        overflow-y: auto;
        margin-right: -15px;
        // padding-bottom: 20px;
        min-height: 200px;
    }
}

.GuideFunnel-modal {
    max-width: 650px;
    width: 650px;
}