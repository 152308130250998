@import "style_variables";

.user-info-main-div {
    min-height: 110px;
    background-color: #ffffff;
    box-shadow: 0px 2px 2px 0px #2122263D;
    box-shadow: 0px 0px 2px 0px #0000001F;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 20px;
    margin-top: 35px;
    position: relative;
    
    .userName {
        font-family: Lato;
        font-size: 32px;
        font-weight: 900;
        line-height: 40px;
        color: $grey1;
        text-transform: capitalize;

    }

    .dateChip {
        background-color: #f5f5f5;
        height: 24px;
        display: inline-block;
        border-radius: 12px;
        padding: 0 8px;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        margin-top: 3px;
    }

    .infoChip {
   
            background-color: #f5f5f5;
            height: 24px;
            display: inline-block;
            border-radius: 12px;
            padding: 2px 8px;
            font-size: 13px;
            font-weight: 700;
            color: #555555;
            margin-top: 3px;
    }
}