@import "style_variables";

.applicationFilterTitle {
    font-size: 13px;
    position: absolute;
    top: -21px;
    right: 180px;
    z-index: 1;
}

.applicationModalFilterTitle {
    font-size: 13px;
    position: absolute;
    top: 1px;
    right: 618px;
    z-index: 1;
}

.appListDropdown {
    display: flex;
    height: 40px;
    width: 252px;
}
.appListDropdownDashboard {
    display: flex;
    height: 40px;
    width: 252px;
    z-index: 999 !important;

    position: absolute!important;
    top: 21px;
    right: 603px;
}

.appListDropdownExportModal{
    display: flex;
    height: 40px;
    width: 252px;
}

.dropdown-menu-Applist {
    height: 200px;
    overflow-y: auto;
}

.source-but-app-list {
    height: 40px;
    width: 252px;
    // margin-right: 20px;
    font-size: 14px;
    color: $filter-font !important;
    border: 1px solid $filter-btn-border !important;
    background-color: white !important;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 9;
    position: fixed;
    padding: 4.5px 11.5px;
    // right: 274px;
    align-items: center;
    display: flex;
}

.source-but-app-list-dashboard {
    height: 40px;
    width: 252px;
    // margin-right: 20px;
    font-size: 14px;
    color: $filter-font !important;
    border: 1px solid $filter-btn-border !important;
    background-color: white !important;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 9;
    position: fixed;
    padding: 4.5px 11.5px;
    // right: 678px;
    align-items: center;
    display: flex;
}

.source-but-app-list-exportModal {
    height: 40px;
    width: 252px;
    // margin-right: 20px;
    font-size: 14px;
    color: $filter-font !important;
    border: 1px solid $filter-btn-border !important;
    background-color: white !important;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 9;
    position: absolute;
    padding: 4.5px 11.5px;
    // right: 274px;
    align-items: center;
    display: flex;
}

.source-app-list {
    width: 252px !important;
    top: 40px !important;
    padding-top: 0px !important;
    min-height: 310px;
}

.source-app-list-dashboard {
    width: 252px !important;
    top: 42px !important;
    padding-top: 0px !important;
    right: 502px !important;
    position: absolute !important;
    /* inset: 97px 610px auto auto !important; */
}

.source-app-list-exportModal{
    width: 252px !important;
    top: 40px !important;
    padding-top: 0px !important;
    left: 0px !important;
}

.dropDownText {
    position: absolute;
    /* right: 70px; */
    /* top: 6px; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    white-space: break-spaces;
    width: 150px;
    left: 53px;
    line-height: 1;
}

.dropDownIcon {
    position: absolute;
    right: 180px;
    top: 13px;
}
.deletedText {
    color: $brightred;
}
.paddingDeleted {
    padding-left: 5px;
}

.dropdown-toggle:after {
    content: none;
}

#modalDropdownButton {
    display: flex;
    align-items: center;

    .caret {
        display: none;
    }
}

.applyButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
}

.app-count {
    position: absolute;
    top: 0px;
    /* overflow: hidden; */
    left: 0px;
    background: $primaryBlue-color;
    font-size: 12px;
    color: white;
    font-weight: 600;
    width: 25px;
    height: 25px;
    text-align: center !important;
    border-radius: 50%;
    /* top: 4px; */
    padding-top: 4px;
}