@import "style_variables";
.lightgreyTxt{
 color:$grey-focus
}
.marginSectionHeader{
    margin: 0px 0px 10px 0px;
}
 .exportSpacing{
    align-items: center;
    justify-content: flex-end;
 }

 .breadcrumbText {
    margin: 0px 0px 10px 0px;
    padding-left: 1px !important;
    padding-right: 0 !important;
    list-style: none;
    display: flex;
    float: left;
    font-size: 14px;
    font-weight: 400;
    flex-wrap: wrap;
 }

 .no-padding-left {
   padding-left: 0px !important;
 }