@import "style_variables.scss";
@import "../../../components/Table/CommonTable.scss";

.guideAnalytics {
    .font16 {
        font-size: 16px;
    }
    .font13 {
        font-size: 13px;
    }
    .guide-count {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    .countHeight {
        height: 40px;
    }

    .completeCount-text {
        color: #38b6a0;
    }
    .payCount-text {
        color: #f79b42;
    }

    .table tbody tr td:nth-child(1),
    .table th:nth-child(1) {
        width: 200px;
        box-sizing: border-box;
        position: sticky;
        max-width: 200px;
        left: 0px;
        z-index: 1;
        background-color: white;
        div {
            background-color: white;
            margin-left: -4px;
            padding-left: 5px;
        }
    }

    .table {
        display: inline-table!important;
    }



    .table th > span,
    .table th > span > p {
        width: 200px;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: break-spaces;
        justify-content: space-between;
    }

    .commonSummaryTable > div {
        overflow-x: auto;
    }

    .table tbody tr td:nth-child(2),
    .table th:nth-child(2) {
        width: 200px;
        text-align: left !important;
    }

    .table tbody tr td:nth-child(3),
    .table th:nth-child(3) {
        width: 200px;
    }

    .table tbody tr td:nth-child(4),
    .table th:nth-child(4) {
        width: 200px;
    }

    .table tbody tr td:nth-child(5),
    .table th:nth-child(5) {
        width: 200px;
    }
    .table tbody tr td:nth-child(5),
    .table th:nth-child(6) {
        width: 200px;
    }

    .table tbody tr td:nth-child(6),
    .table th:nth-child(6) {
        width: 200px;
    }

    .table tbody tr td:nth-child(7),
    .table th:nth-child(7) {
        width: 200px;
    }

    .weekendsIncluded-p {
        margin-top: 10px;
        display: flow-root;

        span {
            float: right;
            font-size: 14px;
            background-color: #ffeec9;
            border-radius: 2px;
            padding: 2px 20px;
            color: #c7a55f;
            border: 1px solid #ded9cf;
        }
    }
}

.guide-play-info {
    padding: 0 30px;
    margin-bottom: 20px;

    .guide-play-div {
        flex-wrap: nowrap;
        /* display: contents; */
        justify-content: center;

        .guidePlay-left {
            border: 1px solid #bbbbbb;
            margin-right: 10px;
            border-radius: 4px;
        }

        .guidePlay-right {
            border: 1px solid #bbbbbb;
            margin-left: 10px;
            border-radius: 4px;

        }
    }
}

.charts-container {
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    justify-content: center;
    padding-bottom: 32px;

    .charts-div-left {
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        padding: 16px;
        margin-right: 10px;
    }
    .charts-div-right {
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        padding: 16px;
        margin-left: 10px;
    }
}

.showMe-charts-container {
    display: flex;
    justify-content: center;
    padding: 20px 30px;
}

.showMe-section {
    margin-bottom: 0px!important;
    margin-top: 85px!important;
}

.sectionBorder {
    border: 1px solid #e6e6e6;
    margin: 0 5px;
    border-radius: 4px;
}

.lineHeight3 {
    line-height: 3;
}

// .custom-tabs {
//     display: flex;
//     overflow-x: auto;
//     overflow-y: hidden;
//     white-space: nowrap;
//   }
  
//   .custom-tabs .nav-link {
//     flex: 0 0 auto;
//   }

//   .custom-tabs-container {
//     overflow-x: auto;
//     white-space: nowrap;
//   }
  
//   .custom-tabs-container .nav-tabs {
//     display: inline-flex;
//     flex-wrap: nowrap;
//   }
  
//   .custom-tabs-container .nav-link {
//     flex: 0 0 auto;
//   }
  
//   .custom-tabs-container::-webkit-scrollbar {
//     height: 6px;
//   }
  
//   .custom-tabs-container::-webkit-scrollbar-thumb {
//     background-color: #ccc;
//     border-radius: 3px;
//   }
  
//   .custom-tabs-container::-webkit-scrollbar-thumb:hover {
//     background-color: #aaa;
//   }
  
/* Container for the tabs */
.custom-tabs-container {
    display: flex;
    flex-direction: column;
  }
  
  .custom-tabs-container .nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap; 
    -webkit-overflow-scrolling: touch;
    padding-bottom: 3px;
  }
  
  .custom-tabs-container .nav-tabs > .nav-item {
    flex-shrink: 0;
  }
  
  .custom-tabs-container .nav-tabs::-webkit-scrollbar {
    height: 6px;
  }
  
  .custom-tabs-container .nav-tabs::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 3px;
  }
  
  .custom-tabs-container .nav-tabs::-webkit-scrollbar-thumb:hover {
    background-color: #888;
  }

  .tab-content {
    margin-top: 1rem;
  }
  